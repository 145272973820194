import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import { BlogVid } from './BlogVid'
import { ScrollTop } from '../../utils/ScrollTop'

export class B6 extends Component {

  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }


  render() {
    return (
        <div className="content"> 
        <Helmet>
          <title>Cosas que Evitan Tu Crecimiento como Músico</title>
        </Helmet>

          <div className="cl-descrip contenido-blog">
            <h3 className='texto-titulo'>Cosas que Evitan Tu Crecimiento como Músico</h3>

            <div className="media my-4">
            <img src="/assets/images/perfil_nuevo_160px.jpg" className="mr-3" alt="" width="40" height="40"
                 style={{ borderRadius: '100%',  border: '2px solid var(--color-cinco)' }}
            />
            <div className="media-body">
              <h6 className="mt-0 texto-cursiva" style={{ margin: 0, fontSize: '1.6rem', color: 'var(--color-primario)' }}>Tincho</h6>
                <small className="text-muted">27 Marzo 2020 - lectura de 6 min</small>
              </div>
            </div>

            <div>
                A lo largo de todos los años que llevo como músico y docente he notado que, como en la vida, el proceso necesario para lograr superarnos consiste en tener claro una serie de conceptos y comportamientos importantes.
                <br/><br/>
                En mi experiencia, sin entender y accionar sobre estos conceptos, será muy difícil obtener resultados satisfactorios. Y si logramos hacerlos parte de nosotros mismos, de nuestro carácter, seremos imparables.
                <br/><br/>
                Veamos entonces cuáles son las cosas que anulan nuestro avance y crecimiento:
                <br/><br/>

                <b>1) No tener objetivos claros</b>
                <br/><br/>
                No tener claro cuales son nuestros objetivos a corto y largo plazo, es como estar en un barco a la deriva y sin control: vamos a ir a donde nos lleve la corriente, y lo más probable es que sea a ninguna parte, nos quedaremos estancados. La forma de romper con el estancamiento es visualizar un objetivo, e ir tras él.
                <br/><br/>
                Es importante, además de visualizar y definir el objetivo, entender cuál es el recorrido (o el proceso) para llegar a él. Este recorrido puede a su vez estar compuesto por pequeñas metas que tendremos que ir alcanzando.
                <br/><br/>
                Por ejemplo: Si nuestro objetivo es ser un buen músico, deberemos desarrollar habilidades en varios campos: tendremos que entender armonía, saber aplicarla al instrumento, tener buena técnica, buen sentido del ritmo, buen oído, un vocabulario musical amplio y una comprensión profunda del lenguaje en el estilo que nos interese.
                Todas estas habilidades tienen que ser desarrolladas para ser un buen músico, por lo que tenemos que tener claro cómo adquirirlas.
                <br/><br/>
                Ser un gran músico de un día para el otro es imposible, vamos a tener que ir planteando pequeños desafíos que seamos capaces de alcanzar y nos brinden pequeños triunfos que a su vez nos empujen a seguir desafiándonos y así sucesivamente hasta alcanzar nuestro objetivo.
                <br/><br/>

                <b>2) Falta de concentración</b>
                <br/><br/>
                La falta de concentración es un mal cada vez más común en el mundo actual. Nos estamos acostumbrando a tener nuestra mente constantemente ocupada con distracciones, sobreinformación, mensajes de texto, notificaciones, memes. Contenido muchas veces trivial y que no aporta a nuestra productividad.
                <br/><br/>
                Ser capaz de concentrarse en una tarea, sin distracciones, durante un periodo de tiempo prolongado es indispensable para lograr buenos resultados.
                <br/><br/>
                En nuestras sesiones de práctica es necesario evitar distracciones: redes sociales, celular, televisión, radio. En caso de convivir con familiares o amigos avisar previamente para no ser molestado.
                <br/><br/>
                Tenemos además que estar cómodos en el silencio, nosotros y la actividad, nada más.
                <br/><br/>
                También es importante entrenarse para mantener el foco en el ejercicio o la actividad que estamos realizando. Muchas veces estamos haciendo un ejercicio x de bajo y terminamos tocando ese tema de los Red Hot Chili Peppers que tanto nos gusta, pero que no era parte del ejercicio. Por lo tanto nos estamos ‘autodistrayendo’. Si identificamos este comportamiento tenemos que obligarnos a volver, y poner atención al ejercicio original y dejar que nos absorba por completo.
                <br/><br/>
                Practicar enfocado en la tarea es la diferencia entre una práctica efectiva y una obsoleta.
                <br/><br/>

                <b>3) Falta de Disciplina</b>
                <br/><br/>
                Si esperamos tener ganas para hacer algo, vamos a ver cómo fácilmente lo podemos postergar durante meses, incluso años.
                <br/><br/>
                Para crecer como músico hace falta constancia. Ser disciplinados nos va a ayudar adquirir el hábito de tocar y practicar a diario. Nos vamos a asombrar del progreso y la productividad que podemos alcanzar de esta forma.
                <br/><br/>
                Hay un frase de Aristóteles que me gusta mucho y dice:
                <br/><br/>
                <blockquote className="blockquote"
                            style={{
                              margin: '0',
                              padding: "0 1rem",
                              fontSize: '1.1rem',
                              borderLeft: '2px solid var(--color-cuatro)'
                            }}
                >
                  <div className="mb-0 mt-0">"Somos lo que hacemos repetidamente. La excelencia, por lo tanto, no es un acto sino un hábito."</div>
                  <div className="blockquote-footer">Aristóteles, <cite>filósofo Griego (384 - 322 ac)</cite></div>
                </blockquote>

                <br/>
                Otra idea importante que deriva de esta es que hay que deshacerse de la idea de "inspiración" y reemplazarla por trabajo.
                En el imaginario común se habla de la inspiración del artista, una especie de energía del universo que invade a la persona y le hace escribir una obra maestra. Esta idea es falsa. La única forma que pase esto, y hasta cierto punto, es ser un maestro. Y para ser un maestro hace falta mucho aprendizaje, trabajo y experiencia.
                <br/><br/>
                La inspiración y el talento no son habilidades innatas, así como las buenas ideas, son producto del trabajo de años.
                <br/><br/>

                <b>4) El Ego</b>
                <br/><br/>
                El ego muchas veces nubla nuestra visión, y más que jugar a favor, juega en contra.
                <br/><br/>
                Si hay algo que no nos sale, no nos frustremos, no maldigamos, no busquemos excusas. Tratemos de ver qué es lo que estamos pasando por alto, ver de qué forma podemos encontrar una solución. La vida sin problemas para resolver carece de sentido, asumámoslo como desafíos que harán de nosotros un mejor músico y persona.
                <br/><br/>
                No nos juzguemos tan duramente, tanto a los demás como a nosotros mismos. Practiquemos la tolerancia y pongamos nuestra energía en las cosas que importan.
                <br/><br/>
                No dejemos que la envidia nos invada, aplaudamos los logros de nuestros compañeros y colegas. Comparémonos sólo con nosotros mismos y pensemos siempre en positivo.
                <br/><br/>
                La humildad es una virtud, nunca creamos que lo sabemos todo. Que no nos guste determinado tipo de música, o algún músico en particular, no significa que sea mala y seguramente hay algo que podamos aprender de ella.
                <br/><br/>
                No creamos que un músico por “tocar más” es mejor que otro. La música no es meritocracia, se trata de contar historias y conectar con el público y para esto no hay una fórmula establecida.
                <br/><br/>
                No nos preocupemos por lo que los demás opinan de nosotros, no vamos a poder conformar a todos, siempre vamos a tener que lidiar con críticas, tomemos las que sean constructivas, ignoremos las otras. No tenemos que convencer a nadie, no busquemos aprobación de nadie.
                <br/><br/>
                Hagamos nuestro camino de forma honesta y humildemente, brindando siempre lo mejor de nosotros.
                <br/><br/>

                <b>5) Falta de Hambre</b>
                <br/><br/>
                Si queremos romperla (ser un crack) como músicos, tenemos que tener “hambre”. Con esto me refiero a las ganas, el empuje, la energía y la curiosidad necesarias para constantemente desafiarnos, exigirnos más y, claro está, disfrutar de eso.
                <br/><br/>
                Sin hambre real nos va a resultar difícil llevar nuestras ideas a la acción, ser productivos. Si nos da lo mismo no vamos a crecer. Tenemos que realmente desearlo y recordarnos todos los días por qué estamos haciendo lo que estamos haciendo.
                <br/><br/>
                La comodidad es enemigo del hambre, tenemos que estar incómodos para estar dispuestos a arriesgar, a buscar, a movernos. Busquemos hacer cosas que nos desafíen y de las que podamos aprender.
                <br/><br/>
                Tenemos que abrazar al error, buscar la falla, fallar cada vez mejor. Aprendemos a través de equivocarnos y adaptarnos, una y otra vez. Después de todo, la práctica no es otra cosa que la falla controlada.
                <br/><br/>

                <b>Conclusión</b>
                <br/><br/>
                Todos estos conceptos, son aprendizajes que me llevaron años darme cuenta, los fuí aprendiendo a los tropezones, luego de chocar muchas veces y de frustarme otras tantas. Los comparto aquí con ánimo de ayudar a todos aquellos que se encuentran necesitando guía y un empujoncito de ánimo para seguir.
                <br/><br/>
                Si estamos dispuestos a trabajar con constancia, mantenernos curiosos, aprendiendo constantemente, manteniendo la humildad, aceptando que sabemos muy poco, siendo buenos compañeros, positivos y solidarios, y sabiendo con claridad a dónde queremos ir: además de sentirnos felices y plenos, nos asombraremos de los resultados que podremos alcanzar.
                <br/><br/>
                A seguir grooveando!
                <br/><br/>
                <span className='texto-cursiva' style={{ fontSize: '1.4rem'}}>Tincho </span>
                <span role="img" aria-label="emoji">🤗🎸🎶</span>
                <br/><br/>


                <BlogVid embed="https://www.youtube.com/embed/_NzOSZvbJ78"
                         texto="Este texto es una transcripción del siguiente video:"
                />

              <br/>
                <ScrollTop />

                <div className='btn-anterior-proximo centro'>
                  <a href="/blog/que-significa-estudiar-musica" className='btn mt-4 centro'>
                    <span className="material-icons">keyboard_arrow_left</span>
                    <span>Anterior: "¿Qué Significa Estudiar Música?"</span>
                  </a>

                  <a href="/blog/que-significa-ser-bajista" className='btn mt-4 centro'>
                    <span>Próximo: "¿Qué Significa Ser Bajista?"</span>
                    <span className="material-icons">keyboard_arrow_right</span>
                  </a>
                </div>

              <br/>
            </div>

          </div>
        </div>
    )
  }
}
