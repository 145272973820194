import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

//import './B4.css'
import { ScrollTop } from '../../utils/ScrollTop'

export class B4 extends Component {

  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }


  render() {
    return (
        <div className="content"> 
        <Helmet>
          <title>¿Qué significa estudiar música?</title>
        </Helmet>

          <div className="cl-descrip contenido-blog">
            <h3 className='texto-titulo'>¿Qué significa estudiar música?</h3>

            <div className="media my-4">
            <img src="/assets/images/perfil_nuevo_160px.jpg" className="mr-3" alt="" width="40" height="40"
                 style={{ borderRadius: '100%',  border: '2px solid var(--color-cinco)' }}
            />
            <div className="media-body">
              <h6 className="mt-0 texto-cursiva" style={{ margin: 0, fontSize: '1.6rem', color: 'var(--color-primario)' }}>Tincho</h6>
                <small className="text-muted">14 Sep 2019 - lectura de 4 min</small>
              </div>
            </div>

            <div>
              Estudiar es una palabra que suele espantar a algunos músicos, sobre todo a los principiantes. Esta forma de pensar es algo que siempre me hizo ruido, ya que para hacer algo bien, tener habilidad en algo, hay que estudiarlo.
              <br/><br/>
              Quizás el problema sea la palabra, que cuando pensamos en “estudiar” sufrimos una regresión que nos remonta al sistema educativo tradicional, que está roto y tiene enormes falencias.
              <br/><br/>
              También sucede que hay cierta creencia generalizada de que la música es algo que no se estudia, que es algo así como mover los dedos en el instrumento y ya está. Sería como pensar que mover la lengua es suficiente para hablar, para trasmitir una idea. Si intento hablar en chino, aunque mueva la lengua e intente emitir sonidos no lo voy a lograr, porque no sé hablar chino.
              <br/><br/>
              <b>Estudiar</b> para mí es ir descubriendo un mundo nuevo con <b>actitud de curiosidad y ánimo de aprender</b>. De aprender todo lo que se pueda. Estudiando desarrollamos habilidades nuevas, haciendo frente a desafíos que si bien nos pueden incomodar, nos hacen crecer y ser mejores. Sin este proceso no existe mejora y solo nos depara la mediocridad.
              <br/><br/>

              Muchas veces escuchamos historias de artistas que "no estudiaron” música, que aprendieron “tocando de oído”.
              <br/><br/>
              Solemos idealizar estas historias, y creer que ese es el proceso natural para cualquiera que quiera tocar música, pensamos que si ellos fueron capaces de crear tanta música increíble, porqué deberíamos nosotros invertir tiempo y esfuerzo en estudiar música? La música se siente y listo, no?
              <br/><br/>
              Pero cuidado, estos relatos suelen ser arbitrarios y mostrar solo una porción parcial de la realidad. El hecho de no haber seguido una carrera académica en música (en un conservatorio por ejemplo) no implica que estos músicos no la hayan estudiado. Hay muchas formas de aprender música, y todas requieren de lo mismo: tiempo, dedicación y esfuerzo.
              <br/><br/>
              Veamos el ejemplo de Paul McCartney. El padre de Paul era músico, tocaba la trompeta y el piano e incentivaba a su hijo a tocar música constantemente.
              <br/>
              Paul no aprendió en un conservatiorio de música, pero sí estudió música. Aprendió, quizás por medio de prueba y error, los fundamentos, las escalas, los acordes, ritmos, melodías, armonías, el lenguaje musical, y entrenó bien su oído. Quizás tenía una facilidad natural hacia la música, cultivada por su padre, por su entorno y/o por él mismo, pero trabajó todos los días durante años y años para formarse como músico, y no paro de aprender.
              <br/><br/>
              El productor de los Beatles, George Martín que sí había estudiado música formalmente, tenía las herramientas que le permitieron escribir, arreglar y orquestar para grandes ensambles de cuerdas y vientos que ayudó a la banda a abrir su sonido y crear grandes obras maestras que de otra forma no hubieran sido posibles.
              <br/><br/>
              Este es un ejemplo en el que músicos con distinta formación unen sus conocimientos y habilidades para crear algo mejor de lo que hubieran hecho cada uno por separado. Saber siempre suma.
              <br/>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/beatles.JPG" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >McCartney (segundo hacia la derecha) con Harrison, George Martin y Lennon en una sesión de grabación de 1966.</div>
              </div>
            </div>

            <div>
              <br/>
              Pero entonces, <b>¿que significa estudiar música?</b>
              <br/><br/>
              Pará mí, estudiar música es el <b>proceso</b> por el cual desarrollamos nuestra <b>habilidad</b> como músicos, para tener oficio como tal.
              <br/><br/>
              Estudiar música se parece mucho a practicar, ya que no sólo tenemos que aprender las estructuras que forman parte del lenguaje musical en el instrumento, sino también en el oído a nivel perceptivo.
              <br/><br/>
              <b>La práctica</b> es el paso más importante para que el aprendizaje musical sea efectivo.
              <br/><br/>
              Estos son algunos síntomas básicos que indican que deberías estar estudiando música, o de que no lo estuviste haciendo bien hasta ahora:
              <ul style={{ listStyle: 'initial' }}>
                <li>
                  Si te olvidas de una canción al tiempito de haberla aprendido.
                </li>
                <li>
                  Si cuando te dicen: “toquemos algo”, no sabes que tocar.
                </li>
                <li>
                  Ninguna de tus ideas te gustan, no lográs que cobren sentido.
                </li>
                <li>
                  Si tenés que ‘pensar’ para tocar una escala, un arpegio o un intervalo.
                </li>
                <li>
                  Si te cuesta memorizar una idea musical.
                </li>
                <li>
                  Si te cuesta sacar un tema de oído.
                </li>
                <li>
                  Si no podés mantener un pulso y un ritmo firme mientras tocás.
                </li>
                <li>
                  Si no podés distinguir entre una nota que cae a “tierra” (con el pulso) de una al “aire” (contratiempo).
                </li>
                <li>
                  Si no podés distinguir algo “mayor” de algo “menor”.
                </li>
                <li>
                  Si no podés transponer una canción o idea a otra tonalidad.
                </li>
                <li>
                  Si no tenés idea de qué es una tonalidad.
                </li>
              </ul>
              Si podés hacer todo en la lista anterior, ya tenés una buena base, por lo que podés continuar construyendo y desarrollando tu lenguaje musical. Siempre va a haber algo nuevo que aprender, ya sea desde lo técnico como desde lo creativo. Cosas tales como:
              <ul style={{ listStyle: 'initial', marginBottom: '0' }}>
                <li>
                  Ritmos complejos: subdivisiones varias, compases irregulares.
                </li>
                <li>
                  Trasponer (cambiar de tonalidad) un tema (o fragmento/idea) en tiempo real.
                </li>
                <li>
                  Improvisar.
                </li>
                <li>
                  Componer.
                </li>
                <li>
                  Aprender a crear líneas de bajo interesantes.
                </li>
                <li>
                  Fills y solos.
                </li>
                <li>
                  Leer a primera vista.
                </li>
                <li>
                  Entender en profundidad la Armonía Funcional.
                </li>
                <li>
                  Sacar temas de oído y retenerlos en la memoria.
                </li>
                <li>
                  Saber que notas o acordes están sonando sin tener el instrumento.
                </li>
                <li>
                  … y mucho más!
                </li>
              </ul>
              Tener habilidades es lo que nos distingue y nos hace únicos. Nadie nos puede quitar los conocimientos adquiridos y siempre van a servir para sumar valor para hacer buenas canciones, tocar bien para que nuestra música tenga impacto e inspire a otras personas, o sea para hacer buen arte, por lo que es la mejor inversión que podemos hacer. Por más que no podamos ver su aplicación inmediata (no sabemos lo que no sabemos), todo influye en los resultados.
              <br/><br/>
              No esperes más, a estudiar!
              <br/><br/>
              <span className='texto-cursiva' style={{ fontSize: '1.4rem'}}>Tincho </span>
              <span role="img" aria-label="emoji">🤗🎸🎶</span>

              <ScrollTop />

              <div className='btn-anterior-proximo centro'>
                <a href="/blog/tips-para-ser-un-mejor-bajista" className='btn mt-4 centro'>
                  <span className="material-icons">keyboard_arrow_left</span>
                  <span>Anterior: "Tips para Ser un Mejor Bajista"</span>
                </a>

                <a href="/blog/cosas-que-evitan-tu-crecimiento-como-musico" className='btn mt-4 centro'>
                  <span>Próximo: "Cosas que Evitan tu Crecimiento como Músico"</span>
                  <span className="material-icons">keyboard_arrow_right</span>
                </a>
              </div>

            </div>

          </div>
        </div>
    )
  }
}
