import React from 'react'
import './Item.css'

export default class Item extends React.Component {
  constructor(props) {
    super(props)
    this.handleSelection = this.handleSelection.bind(this)
  }

  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  categ(el) {
    if (el === 'transcripcion') {
      return <i className="fas fa-music"></i>
    }
    if (el === 'grooves') {
      return <i className="fas fa-user-astronaut"></i>
    }
    if (el === 'tecnica') {
      return <i className="fas fa-guitar"></i>
    }
  }

  handleSelection() {
    //console.log(this.props.parte)
    this.props.itemState(this.props.parte)
  }

  render() {
    let { parte } = this.props
    return (

        <div className="lista">

          <div onClick={this.handleSelection} className='prevItem ' >
            <div className="detalles"
                    style={{
                            backgroundImage: 'url(/assets/vids/'+parte.img+')',
                            backgroundPosition: parte.img_pos,
                            height: '14rem'
                          }}
              >
            </div>
            <div className={parte.categ+" icon center"}>
              {this.categ(parte.categ)}
              <p>{parte.fecha}</p>
            </div>
            <div className="detalles">
              <div className="filtro"></div>
              <h2 className={"gs-b texto-titulo titulo-"+parte.categ}>{parte.titulo}
                <br/>
                <small>{parte.artista}</small>
              </h2>
              <p className="gs-it m my-1">{parte.descrip.length > 120 ? parte.descrip.slice(0, 160)+'...' : parte.descrip}</p>
            </div>

            {/* <div className="mini center"
              style={{
                      backgroundImage: 'url(/assets/vids/'+parte.img+')',
                      backgroundPosition: parte.img_pos,
                      backgroundSize: 'cover'
                    }}
            > 
            </div> */}
            
          </div>
        </div>
    )
  }
}
