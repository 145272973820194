import React, { useState } from 'react'
/* import { Collapse, Alert } from 'react-bootstrap'
import { Spotify } from 'react-spotify-embed' */
import { ScrollTop } from '../../utils/ScrollTop'

import './Clases.css'
/* import { items } from './cl-handle-data' */
import { youtube, spotify, apple, deezer, amazon, tidal } from '../../utils/svgs'


export function Clases() {

    const [open, setOpen] = useState(false);

    return (
      <div className="content contenido-texto sencillo">
        <div className="ini-descrip" id="inicio" style={{width: '100%'}}>

          <h1 className='texto-titulo text-center mb-0'>Música Original</h1>
          <h4 className='texto-cursiva' style={{rotate: '-2deg', position: 'relative', top: '-1.6rem'}}>por Tincho en el Bajo</h4>

          <div className="container" style={{}}>
            <div className='pb-4'>

              <div className='mb-3'>
                Mis composiciones tienden a ser muy rítmicas, enfatizando y poniendo al <b>Bajo</b> y el <b>Groove</b> como elementos protagónicos, dentro de un mix de Rock, Funk, Hip Hop, Jazz y Electrónica. <br/><br/>
                A continuación encontrarás mis sencillos, haciendo clic en el arte de tapa podrás escucharlos y accederás a info detallada de cada uno. ¡Que los disfrutes!
              </div>

             
              <div className='text-lanz pt-4' style={{maxWidth: '560px', margin: '0 auto'}}>

                {/* SENCILLO */}
                <a className="sencillo" href="/corriendoalmar" title="+ info">
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img src="/assets/img/corriendoalmar_500px.jpg" />
                  </div>
                </a>

                <br/><br/>


                {/* SENCILLO */}
                <a className="sencillo" href="/lovdisyit" title="+ info">
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img src="/assets/img/lovdisyit_500px.jpg" />
                  </div>
                </a>

                <br/><br/>

                {/* PLATAFORMAS */}
                <div className='texto-cursiva text-center mb-1' style={{fontSize:'2rem'}}>¡Escucha a <b>Tincho en el Bajo</b> <br/> en tu plataforma favorita!</div>
                <div className='plataformas-musica'>
                  <a className='btn boton2 btn-miembros' href="https://open.spotify.com/intl-es/artist/5r1neyXPnXHHlbAt2rhelM" target='_blank' rel='noopener noreferrer'>
                    {spotify} Spotify
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://music.youtube.com/channel/UCXoxePbEh6UXyGTavFcK83w" target='_blank' rel='noopener noreferrer'>
                    {youtube} YouTube
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://music.apple.com/ar/artist/tincho-en-el-bajo/1710658787" target='_blank' rel='noopener noreferrer'>
                    {apple} Apple
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://music.amazon.com/artists/B0CKKJ3W24/tincho-en-el-bajo" target='_blank' rel='noopener noreferrer'>
                    {amazon} Amazon
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://www.deezer.com/es/artist/234778661" target='_blank' rel='noopener noreferrer'>
                    {deezer} Deezer
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://tidal.com/browse/artist/42635974" target='_blank' rel='noopener noreferrer'>
                    {tidal} Tidal
                  </a>

                </div>

                <br/><br/>
                  <div className='text-center'>
                    ¡A Groovearla! 👽🎸🎶
                  </div>

                <br/>
                <hr/>

                <br/><br/>

                <ScrollTop />

              </div>
              
            </div>
          </div>
          
        </div>
      </div>
    )

}
