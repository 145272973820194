import React, { useState } from 'react'
import { Collapse, Alert } from 'react-bootstrap'
import { Spotify } from 'react-spotify-embed'
import { ScrollTop } from '../../utils/ScrollTop'

import './Clases.css'
import { items } from './cl-handle-data'
import { youtube, spotify, apple, deezer, amazon, tidal } from '../../utils/svgs'


export function Clases() {

    const [open, setOpen] = useState(false);

    return (
      <div className="content contenido-texto sencillo">
        <div className="ini-descrip" id="inicio" style={{width: '100%'}}>

          <h1 className='texto-titulo text-center mb-0'>LOVDISYIT</h1>
          <h4 className='texto-cursiva' style={{rotate: '-2deg', position: 'relative', top: '-1.6rem'}}>por Tincho en el Bajo</h4>

          <div className="container" style={{}}>
            <div className='pb-4'>

             
              <div className='text-lanz pt-1' style={{maxWidth: '560px', margin: '0 auto'}}>

                <div className='spoti desktop mb-2'>
                  {<Spotify link='https://open.spotify.com/track/3gfXUqAfQcSD7N2MDDCmlE?si=a5ccf1224f394135' />}
                </div>

                <div className='spoti mobile mb-2'>
                  {<Spotify wide link='https://open.spotify.com/track/3gfXUqAfQcSD7N2MDDCmlE?si=a5ccf1224f394135' />}
                </div>

                <div className='nov-vid mb-2 d-none'>
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/D0EvC3hFLn8?si=oEgYMv1NPoUy7CR6" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>

                <div>Lovdisyit es mi primer sencillo, una composición instrumental original que tiene a nuestro querido Bajo como protagonista. 💚🎸🎶 </div>
                <br/>
                <div className='texto-cursiva text-center mb-1' style={{fontSize:'2rem'}}>¡Escuchalo en las plataformas digitales!</div>

                <div className='plataformas-musica'>
                  <a className='btn boton2 btn-miembros' href="https://open.spotify.com/intl-es/track/3gfXUqAfQcSD7N2MDDCmlE?si=a5ccf1224f394135" target='_blank' rel='noopener noreferrer'>
                    {spotify} Spotify
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://music.youtube.com/watch?v=D8uv-W9qTCU&si=TBbVTGpPXVcPf9Si" target='_blank' rel='noopener noreferrer'>
                    {youtube} YouTube
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://music.apple.com/ar/album/lovdisyit/1710658952?i=1710658953" target='_blank' rel='noopener noreferrer'>
                    {apple} Apple
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://music.amazon.com/albums/B0CKKK3LF7?marketplaceId=ART4WZ8MWBX2Y&musicTerritory=AR&ref=dm_sh_pn7uQZ0OBPvpDMp0k4O8P1tfe&trackAsin=B0CKKK1RYX" target='_blank' rel='noopener noreferrer'>
                    {amazon} Amazon
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://www.deezer.com/es/track/2487529121" target='_blank' rel='noopener noreferrer'>
                    {deezer} Deezer
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://tidal.com/browse/track/320156754" target='_blank' rel='noopener noreferrer'>
                    {tidal} Tidal
                  </a>

                </div>
    
                
                <br/>
                También está disponible en 
                  <a href="https://www.pandora.com/TR:106420313" target='_blank' rel='noopener noreferrer'> Pandora</a>, 
                  <a href="https://www.claromusica.com/embed/track/182772989" target='_blank' rel='noopener noreferrer'> Claro Música</a>,
                  <a href="https://web.napster.com/track/tra.790201946" target='_blank' rel='noopener noreferrer'> Napster</a>,
                   
                  y en muchas otras plataformas de streaming y descarga de música digital.

                  <div className='texto-cursiva text-center mt-4' style={{fontSize:'2rem', lineHeight: '0.9'}}>                  
                    Video Oficial
                  </div>

                  {<div className='nov-vid mt-2 mb-4'>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/D0EvC3hFLn8?si=oEgYMv1NPoUy7CR6" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>}

                🎵 Compuesto, interpretado y producido por Tincho en el Bajo (o sea yo 😝). Todos los derechos reservados.
                <br/>
                🎙️ Grabado, mezclado y masterizado durante el mes de septiembre 2023.
                <br/>
                🚀 Fecha de lanzamiento: 3 de noviembre 2023.
                <br/><br/>
                ⚙️ Para grabar el Bajo utilicé mi Fender Jazz Bass México 2007, cuerdas Daddario Roundwounds 105 - 45 (de 8 años de antigüedad) a través del cabezal Aguilar Tone Hammer 350 (sin pasar por el Pre), por línea directa (DI) a la interfaz de audio Focusrite Scarlett 2i2. 
                <br/><br/>
                También agregué algunos efectos para bajo: usé mi pedal Boss Octave OC-2 analógico (adiviná en qué sección) y para algunos fragmentos un poco de Overdrive, Reverb y Auto Filter (digitales, agregados en post-producción). 
                <br/><br/>
                Y para lo demás, mi teclado MIDI a través de Ableton Live, que considero la mejor DAW (Digital Audio Workstation) para crear y producir música, en mi humilde opinión.
                <br/><br/>

                <h4>Concepto</h4>

                La canción cuenta una historia de búsqueda y aventura, como está representado en la portada: Un niño que empujado por un impulso más fuerte que él, por una necesidad que crece como el fuego, sale a explorar lo desconocido dejando la seguridad de su hogar. 
                <br/><br/>
                Contra viento y marea emprende su viaje, encontrando a su paso distintas situaciones de las cuales va aprendiendo y le permiten seguir avanzando. La duda y el miedo aparecen, y el recuerdo, la añoranza, de lo que quedó atrás. Pero volver no es una opción, solo seguir adelante, un paso a la vez.
                <br/><br/>
                Cada avance abre lugar a algo nuevo, al descubrimiento de algo hermoso, y el miedo se convierte en entusiasmo y pasión.
                <br/><br/>

                <h4>Breve análisis</h4>

                Compuse Lovdisyit pensando en el Groove como el elemento principal, y fusionando Funk, Rock, Jazz y Electrónica. Creo que la Computadora es el instrumento musical de esta época, y me entusiasma combinar los timbres del Bajo Eléctrico con instrumentos virtuales y las posibilidades que brinda la tecnología a nivel sónico y compositivo.
                <br/><br/>
                Armónicamente, utilicé exclusivamente acordes dominantes, de 7ma, como en el Blues.
                <br/><br/>
                La canción consta de 2 grandes secciones, que a su vez están divididas en subsecciones. Comenzamos con una breve <b>introducción</b> con una secuencia de acordes dominantes que se mueven por semitonos descendentes.
                <br/><br/>
                Luego entramos a la <b>1er gran sección</b>, que tiene <b>2 subsecciones: A y B</b>. La parte A es protagonizada por la línea de Bajo (el niño) y la batería va complementando el groove, mientras el sintetizador armoniza el acorde A7 con notas sostenidas en crescendo. También tenemos otras capas como un sinte secundario que va por debajo de los instrumentos principales y sonidos que aparecen y desaparecen.
                <br/><br/>
                Después entramos en la B, cambiando al IV grado, un D7. Esta sección incrementa la intensidad, el bajo gruñe y dispara frases a modo de pregunta y respuesta. Se suman al contrapunto voces femeninas, como un coro de sirenas.
                <br/><br/>
                Volvemos a la A y aparece una melodía fuerte, con carácter de victoria, tocada por una guitarra eléctrica. En mi imaginación la guitarra representa un pájaro, una guía para el niño en su viaje.
                <br/><br/>
                Luego entramos a la <b>2da gran sección</b>, que contrasta con la anterior, generando un clima algo más oscuro. Se incorpora una batería electrónica y la batería acústica entra a tocar melódicamente. El sonido de campanas a lo lejos, sonidos del hogar que fue. Aparece otra vez la guitarra (el pájaro) con un par de frases breves, como de aliento.
                <br/><br/>
                En esta sección la armonía cambia, y tenemos una secuencia de acordes que se mueven por terceras: D7, B7, G7 y Bb7, tocados por el piano eléctrico Fender Rhodes utilizando densos voicings.
                <br/><br/>
                Luego entra el Bajo (acá usé el OC-2), con una melodía grave "through-composed" (doblada también por una flauta baja) que se mueve continuamente sin repetición atravesando los acordes. Representa la búsqueda, el aprendizaje.
                <br/><br/>
                Volvemos a la intro y se repite el mismo ciclo, pero se suman más instrumentos al contrapunto: una sección de brasses (trompetas), sintetizadores y un piano eléctrico tipo Wurlitzer.
                <br/><br/>
                En la sección B el Bajo se pone heavy, se va prendiendo fuego, con el overdrive, auto filter y frases con frases funkys pero filosas.
                <br/><br/>
                Volvemos a la A y suena la melodía, con el llamado a la victoria en su máximo esplendor, combinando el timbre de las trompetas, la flauta, sintes y guitarra eléctrica.
                <br/><br/>
                Y así llegamos a la 2da sección, otra vez en zona desconocida (conceptualmente hablando). En ausencia del Bajo la guitarra nos canta una melodía. Luego entramos con el Bajo tocando la melodía grave, nos dobla la flauta en el registro agudo, acompañándonos. Repetimos la melo, se acopla la guitarra, y las trompetas/sintes tocan una contra melodía, armonizada a 6 voces, llegando al clímax de la canción, que finaliza luego de la Coda (sección final y cierre).
                

                <div className='texto-cursiva text-center mt-4' style={{fontSize:'2rem', lineHeight: '0.9'}}>                  
                  Transcripción completa <br/>y Backing Tracks <br/>
                  <span style={{fontSize:'1.6rem'}}>
                    <a href="https://youtube.com/tinchoenelbajo/join" target='_blank' rel="noopener noreferrer">
                      (para miembros del canal de YouTube)
                    </a>
                  </span>
                </div>

                {<div className="foto parte mb-5">
                  <a href="https://youtu.be/wPLKMifFpWw" target='_blank' rel="noopener noreferrer" title="ir al video">
                    <img src='/assets/img/lovdisyit_parte_backin_web.jpg' alt='' />
                  </a>
                </div>}
                

                <h4>La historia detrás de Lovdisyit</h4>

                Comencé a componer esta canción el año pasado (octubre/noviembre 2022), bosquejando las primeras ideas, que fui desarrollando y ampliando a lo largo del 2023. Venía de uno de esos "lapsus" donde comencé a dudar de mí mismo, de no saber bien qué estoy haciendo, qué dirección tomar, y las responsabilidades de la vida abrumándome. Ser músico no es fácil, al menos no lo es para mí, y comencé a dudar de mi "talento", mi capacidad, mi criterio y a replantear todo.
                <br/><br/>
                Una noche, luego de bastante tiempo, agarré mi bajo y empecé a tocar. Se sentía bien, muy bien. Y pasaron las horas: 1, 2, perdí la cuenta. De ahí salieron muchas ideas, parte de ellas convirtieron en "Lovdisyit". <br/><br/>
                Esa noche me "cayó la ficha", hice "clic". <b>La música me hace bien, es mi lugar feliz</b>. Lo demás no importa. No importa si no soy tan bueno, si no logré destacarme, si ya soy grande, y todos los condicionales que puedan existir. Son trampas de la mente que restringen nuestro potencial. Lo que importa es hacer lo que nos llena el alma, hacerlo de la mejor manera, con honestidad. Un paso a la vez, firme, todos los días.
                <br/><br/>
                Y ser agradecido por lo que tenemos y hemos logrado. Todos somos únicos, tenemos algo diferente para dar. Sé auténtico, buscá una voz propia, distinta y alimentala.
                <br/><br/>
                "Lovdisyit" (onomatopeya de "Love This Shit") es un tema instrumental que quiere representar este concepto, el viaje de búsqueda como artista, y de la vida misma, ese impulso que nos lleva a la exploración de lo desconocido, con los riesgos y sacrificios que conlleva. El amor por lo que hacemos, la entrega y la curiosidad por descubrir el mundo que vive en nuestra imaginación.
                <br/><br/>
                ¡Aguante la vida y la música! <br/>
                ¡A Groovearla! 💚👽🎸🎶
                <br/><br/>
                <span className='texto-cursiva' style={{fontSize: '3rem'}}>Tincho</span>

                <hr/>

                Contenido relacionado: 
                  <a href="https://youtu.be/UvPaaT4eSu8" target='_blank' rel="noopenner norefferer"> presentación</a>,
                  <a href="https://youtu.be/D0EvC3hFLn8" target='_blank' rel="noopenner norefferer"> video oficial</a>,
                  <a href="https://youtu.be/wPLKMifFpWw" target='_blank' rel="noopener noreferrer"> transcripción y backing tracks</a>.
                <br/><br/>
                <br/><br/>

                <ScrollTop />

              </div>
              
            </div>
          </div>
          
        </div>
      </div>
    )

}
