import React from 'react'
import { Alert } from 'react-bootstrap'
import { Spotify } from 'react-spotify-embed'

import './Clases.css'
import { youtube, spotify, apple, deezer, amazon, tidal } from '../../utils/svgs'

export function Clases() {


    return (
      <div className="content contenido-texto">

        <div className="ini-descrip mb-3" id="inicio">

          <p>
          Mi nombre es Tincho, <b>soy Bajista, Profe de Bajo y Youtuber.</b> <br/><br/>

          Mi <b>pasión es la música</b>, creo que es un arte que tiene un gran poder movilizador y la capacidad de encender nuestro espíritu como pocas cosas en el mundo.<br/>
          Mi <b>vocación es compartir</b> lo que aprendí y lo que todavía aprendo en mi camino como músico.

          </p>

          <Alert variant="success" style={{marginBottom: "1rem"}}>
            <a href="/clases" title="Más info sobre las clases">
              <h3 className="texto-titulo mb-1">Clases de Bajo Online <span>🎶</span></h3>
              <div></div>
              <div className='clases-click'>
                Por videocall, individuales y personalizadas.<br/>
                Hacé click acá para más info.
              </div>
            </a>
          </Alert>

          <br/>

          <h3 className="texto-titulo novedades">Novedades</h3>

          {/*<div className='mb-2' style={{textAlign: 'center'}}>Estreno público Miércoles 6/9 18:00 hs (GMT-3)</div>*/}

          {/* <div className="mt-4" style={{margin: '0 auto'}}>
            <div className="lanzamiento container py-4">
              <div className='row'>

                <div className='presave col-md'>
                  <iframe src="https://show.co/social-unlock/5QMZAfSEh7DHGBVrrfqqxC/widget" width="340" height="340" frameborder="0"></iframe>
                </div>

                <div className='text-lanz col-md'>
                  Se viene mi nuevo sencillo titulado "<b>Corriendo al Mar</b>" 🌊. <br/>

                  <div style={{margin: '1rem 0'}} className=''>
                    <a className="lanz" href="https://show.co/TOtqTfE" rel="noopenner noreferer" target="_blank">¡Guardalo ahora en Spotify</a> y escuchalo el día del lanzamiento!
                  </div>

                  <small className='d-block mt-3'>A partir del 24 de mayo podrás escucharlo desde cualquier servicio de streaming de música (Spotify, Apple Music, Tidal, Youtube Music, etc).</small>
                </div>
                
              </div>
            </div>
          </div> */}
          

          {/* <div className='lanzamiento membresia azulclaro'>
            <div className='py-4 text-lanz'>
              <div className='indiana'>
                <img src="/assets/images/b8/indiana_90px.png" />
              </div>

              <div className='texto-cursiva miembros text-center' style={{color:'white',fontSize:'2.4rem'}}>
                ¡Nuevo Blog!

                <div className='mt-3'>
                  <span className='texto-titulo' style={{fontSize:'1.8rem'}}>Breve Historia del Bajo Eléctrico</span>
                </div>
              </div>


              <div>
                <a className='btn boton2 btn-miembros mt-3' href="/blog/breve-historia-del-bajo-electrico">
                  Leer artículo
                </a>
              </div>
            </div>
          </div> */}
       


          <div className='lanzamiento membresia video-youtube-nov py-5 mt-4'>
            <h4 className='texto-cursiva' style={{fontSize:'2.6rem',color:'white'}}>¡Último video de YouTube!</h4>
            {<div className='mb-2' style={{textAlign: 'center'}}></div>}
            
            
            <div style={{margin: '0 auto'}}>
              <div className="nov-vid">
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/tPgZ9gUTa2E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
            </div>
          </div>

          {/* <h4 className='texto-cursiva mt-4'>Unite a mi canal de YouTube</h4> */}

          <div className='lanzamiento membresia azulclaro'>
            <div className='py-4 text-lanz'>
              <div className='supergroovero'>
                <img src="/assets/img/supergroovero_80px.jpg" />
              </div>

              <div className='texto-cursiva miembros' style={{color:'white',fontSize:'2.4rem'}}>
                ¡Convertite en un <span>Super Groovero!</span>
              </div>

              <div className='mt-3'>Hacete <b>miembro del canal de YouTube</b> y disfrutá de <b>contenido extra y beneficios</b>: videos educativos exclusivos, backing tracks para practicar con acompañamiento y acceso anticipado a videos nuevos. <br/>
              Además me ayudás a poder seguir creando más y mejor contenido. <br/>
              ¡Infinitas gracias a todos los que me apoyan! 
              💚🤗🎸🎶
              </div>

              <div>
                <a className='btn boton2 btn-miembros mt-3' href="https://youtube.com/tinchoenelbajo/join" target='_blank' rel="noopener noreferrer">
                  + info acerca de la membresía
                </a>
              </div>
            </div>
          </div>



          <div className="lanzamiento membresia" style={{/* display: 'none' */}}>
            <div className='text-lanz pt-3 pb-4'>
              
              <h4 className='texto-cursiva' style={{fontSize:'2.6rem', color:'white'}}>🚀 ¡Último lanzamiento musical!</h4>

              {/* <div style={{margin: '0 auto'}}>
                <div className='nov-vid'>
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/D0EvC3hFLn8?si=oEgYMv1NPoUy7CR6" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
              </div> */}

              <div className='img-portada my-4'>
                <a href="/corriendoalmar">
                  <img src="/assets/img/corriendoalmar_500px.jpg" />
                </a>
              </div>


              <div className='pt-0'>
                {/* Escuchá mi nuevo sencillo que incluye 2 canciones, un lado A y un lado B, al estilo de la vieja escuela. <br/><br/>
                En el lado A tenemos "<b>Corriendo al Mar</b>" un tema muy power donde toco mi bajo Fretless. <br/><br/>
                Y el lado B se llama "<b>Trap del Bajo</b>", una canción donde rapeo y cuento con un poco de humor de mi experiencia como bajista y en la que le rindo homenaje a este hermoso instrumento.
                <br/> */}


                <div style={{margin: '1rem 0'}} className='pt-2'>
                  {/* <Spotify wide link='https://open.spotify.com/album/2WgsA9WcCKLjz26QWsoI8q' /> */}
                </div>

                <div className='plataformas-musica-ini mt-3'>
                  <a className='btn boton2 btn-miembros' href="https://open.spotify.com/intl-es/album/2WgsA9WcCKLjz26QWsoI8q" target='_blank' rel='noopener noreferrer' title='Spotify'>
                    {spotify}
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://music.youtube.com/playlist?list=OLAK5uy_m2Cksb-888l-AajZUqprv7AN_ads6ZNec" target='_blank' rel='noopener noreferrer' title='YouTube Music'>
                    {youtube}
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://music.apple.com/ar/album/corriendo-al-mar-single/1745782785" target='_blank' rel='noopener noreferrer' title='Apple Music'>
                    {apple}
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://music.amazon.com/albums/B0D3WGLVXS" target='_blank' rel='noopener noreferrer' title='Amazon Music'>
                    {amazon}
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://www.deezer.com/es/album/585292262" target='_blank' rel='noopener noreferrer' title='Deezer'>
                    {deezer}
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://listen.tidal.com/album/362548222" target='_blank' rel='noopener noreferrer' title='Tidal'>
                    {tidal}
                  </a>
                </div>

                <div className='mt-4'>
                  <a className='btn boton2 btn-miembros' href="/corriendoalmar">
                    + info sobre este sencillo
                  </a>
                </div>

              </div>
              
            </div>
          </div>


          



        </div>
      </div>
    )

}
