import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from "react-router-dom";

import './Partituras.css'

// Utils
import { getCookie, _SERVER, _SID, _AUTH } from '../utils/req'

//COMPONENTES
import Login from './login'
import Privado from './privado'

let isAuth, session = {}


export class Partituras extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sesion: {}
    }
  }

  // pasar a Login para pasar datos de la res form al componente
  isLoggedFunc = (log, id, name) => {
    new Promise((res, rej) => {
      //console.log('isLogged is called')

      // variables para pasar props a componente Privado
      isAuth = log
      session = {
        id: id,
        name: name,
        logged: log
      }

      // crear cookie
      if (log) {
        document.cookie = _SID+id
        //console.log('cookie ' + document.cookie.ur)
      }
      res({
        id: id,
        name: name,
        logged: log
      })

    }).then(res => {
      this.setState({ session: res })
      //console.log('state: '+this.state.session.logged, 'log-param: '+log)
    })
  }

  
  // chequear cookies y extraer uid
  componentDidMount() {
    window.scroll({
      top: 0,
    })

    let cookie = getCookie(_SID),
        uid, sid,
        cookieData = new FormData();

    //let cookie = 'GJX35H01Sicl!!sdY-u1';
    // separa sid de id (-u[id])

    if (cookie) {
      sid = cookie.slice(0, 19);
      uid = cookie.slice(19);

      //console.log('Comp Will Mount, send cookie')
      //console.log(sid, uid)

      cookieData.append('isAuth', JSON.stringify({
          sid: sid,
          uid: uid
      }));

      // autenticar con el servidor
      try {
        fetch(_SERVER + _AUTH, {
          method: 'POST',
          headers: {
            //'Content-Type': 'text/json'
          },
          body: cookieData
        })
          .then(res => res.json())
          .then(res => {
            //console.log('response from cookie')
            //console.log(res)

            //isAuth = res.logged
            //session = res
            //console.log('comp will mount ' + isAuth)
            return res

          }).then(res => {
            this.setState({
              sesion: {
                id: res.id,
                name: res.name,
                logged: res.logged
              }
            })
          })
      }
      catch (err) {
        //console.log(err)
        this.setState({ sesion: { logged: false }})
      }

    } else {
      //console.log('no cookie')
      this.setState({ sesion: { logged: false }})

      // Crear cookie al ingresar los datos en el form Login (isLoggedFunc)
    }
    
    //const data = await fetch('/sesion_usuario/sesion/isLogged')
    //const res = await data.json()

    //console.log(res)

    /*
    if (res.log) {
      this.setState({ sesion: res })
    }
    else {
      this.setState({ sesion: { log: false }})
    }
    */
  }

  updateSesion = nuevaSesion => {
    this.setState({ sesion: nuevaSesion })
    //console.log('Nueva Sesion: ', nuevaSesion)
  }


  render() {

    //console.log(this.state.sesion.log)

    return (
      <div className="App part">
        <Router>

            {
              this.state.sesion.logged ?
                <Redirect to='/partituras/acceso' />
                :
                <Redirect to='/partituras/ingresar' />
            }

          <Route path="/partituras/ingresar" render={props => <Login isLogged={this.state.sesion.logged} isLoggedFunc={this.isLoggedFunc} sesion={this.state.sesion} updateSesion={this.updateSesion}/>} />

          {this.state.sesion.logged &&
            <Route path="/partituras/acceso" render={props => <Privado sesion={this.state.sesion}/> }/>
          }

        </Router>
      </div>
    );
  }
}