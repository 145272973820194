import React, { Component } from 'react'

import './Acerca.css'
import {testimonios} from '../../_json_data/testimonios.js'

export class Acerca extends Component {

  constructor(props) {
    super(props)
    this.state = {
      testimonios: [],
      indexList: false
    }
  }

  componentDidMount() {
    let index_list = []

    for (let i = 0; i < 3; i++) {
      let random_index = Math.floor(Math.random() * testimonios.length)
      let index_repetido = false

      if (i === 0) {
        index_list.push(random_index)
          //console.log(random_index, index_list)
      }

      if (i > 0) {
        index_repetido = index_list.includes(random_index)
          //console.log(index_repetido)
    
        if (!index_repetido) {
          index_list.push(random_index)
            //console.log(random_index, index_list)
            //console.log(index_repetido)
        }
        else {
          while (index_repetido) {
            random_index = Math.floor(Math.random() * testimonios.length)
            index_repetido = index_list.includes(random_index)
            
            if (!index_repetido) {
              index_list.push(random_index)
                //console.log(random_index, index_list)
                //console.log(index_repetido)
              break;
            }
  
          }
        }
      }
      
      if (index_list.length === 3) {
        //console.log(index_list)
        this.setState({
          indexList: index_list
        })
      }
    }
  }



  render() {

    const fecha = new Date().getFullYear()
    const tiempo_musico = fecha - 1996
    const tiempo_docente = fecha - 2006

    return (
      <div>
        <div className="space" id="acerca"></div>

        <div className="content mb-4">
          <div className="contenido-texto">
          <div className="cl-descrip" id="bio">
            <h3 className="texto-titulo">ACERCA DE MI</h3>

            <p>Mi nombre es Martín "Tincho" Satorre. Tuve la suerte de aprender y compartir experiencias de primera mano con grandes músicos que me han ayudado a entender y amar la música en sus más variadas expresiones.</p>

            <p>A lo largo de { tiempo_musico } años que llevo como músico, formé parte de diversas agrupaciones tocando el bajo eléctrico y el contrabajo en bandas de Rock, Funk, y en grupos de Jazz, Fusión, Folclore y música Clásica.</p>

            <p>Mi experiencia en la docencia, dictando clases en escuelas, instituciones y en forma privada desde hace de más de { tiempo_docente } años, me permitieron entender qué mecanismos son eficientes para optimizar el proceso de aprendizaje, desarrollando un método propio con excelentes resultados.</p>

            <div className="cl-descrip-r">
              <h3 className='texto-titulo'>FORMACION</h3>
              <div className="form-items">
                <p id="top">Tecnicatura Superior en Jazz, Contrabajo. <br/>
                  <span className="italic">Conservatorio Superior Manuel de Falla.</span></p>
                <p>Músico Profesional, Bajo Eléctrico. <br/>
                  <span className="italic">Escuela de Música Contemporánea (Berklee International Network).</span></p>
                <p>Contrabajo. <span className="italic">Conservatorio de San Martín.</span></p>
                <p id="bot">Clases de Bajo y Contrabajo con Hernán Merlo, Carlos Álvarez, Willy González, Omar Moreno y Rafael Surijón.</p>
              </div>
            </div>
            <h6 className="bio" title="más sobre mí">
              <a href="/blog/mi-historia">
                <span className="material-icons" style={{fontSize: '5rem'}}>add</span>
              </a>
            </h6>
          </div>
          </div>


          {/* TESTIMONIOS */}
          <div className='contenido-texto mb-4'>
            <div className='cl-descrip pt-3'>
              <h3 className='texto-titulo mb-2' style={{ textAlign: 'center', width: '100%'}}>Opiniones de mis alumnos</h3>
              
              <div className='testimonios'>

                {this.state.indexList && 
                  this.state.indexList.map((testIndex, i) => {
                    return (
                      <div className="card" key={i}>
                        <div className="card-body">
                          <div className="media">
                            <div className="media-body">
                              <p>"{testimonios[testIndex].opinion}"</p>
                              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <span role="img" aria-label="emoji" style={{ fontSize: '1.6rem' }} className='pr-1'>{testimonios[testIndex].img} </span>
                                <p className='texto-cursiva' style={{fontSize: '1.3rem'}}>{testimonios[testIndex].nombre}</p>
                              </div>
                            </div>
                          </div>                      
                        </div>
                      </div>
                    )
                  })}

              </div>
            </div>
          </div>


        </div>
      </div>
    )
  }
}


//const testimonios = () => ()