import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import './App.css'

// Components
import { NavPage } from './Components/NavPage'
import { Footer } from './Components/Footer'

import { Inicio } from './Inicio/Inicio'
import { Partituras } from './Partituras/Partituras'
import { Blog } from './Blog/Blog'
import { Video } from './Videos/Video'
import { ClasesPage } from './clases/Clases'
import { Musica } from './Musica/Musica'
import { Lovdisyit } from './Lovdisyit/Lovdisyit'
import { CorriendoAlMar } from './CorriendoAlMar/CorriendoAlMar'

import Error from './Components/Error'


class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navHeight: 52,
      contactY: 0
    }
  }

  componentDidMount() {
    this.handleNavHeight()
  }  

  handleNavHeight() {
    const navHeight = document.querySelector('.navegador').offsetHeight-1
    this.setState({ navHeight: navHeight })
  }


  render() {
    // iframe doc view height
    window.onresize = () => {
      this.handleNavHeight()
    }

    return (
      <Router>
        <div>
          <NavPage />

          <div className="section-content" style={{paddingTop: this.state.navHeight, minHeight: '90vh' }}>
            <Switch>
              <Route exact path="/" component={Inicio}/>
              <Route path="/partituras" component={Partituras} />
              <Route path="/blog" component={Blog} />
              <Route path="/videos" component={Video} />
              <Route path="/clases" component={ClasesPage} />
              <Route path="/lovdisyit" component={Lovdisyit} />
              <Route path="/corriendoalmar" component={CorriendoAlMar} />
              <Route path="/musica" component={Musica} />
              <Route path="*" component={Error} />
            </Switch>            
          </div>

          <Footer/>
        </div>
      </Router>
    )
  }
}


export default App;
