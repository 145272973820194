import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
// Components
import { Intro } from './Components/Intro'
import { Clases } from './Components/Clases'
import { Acerca } from './Components/Acerca'
import { Contacto } from './Components/Contacto'

import './pag-clases.css'


export function ClasesPage() {
  // scroll arriba
  useEffect(() => {
    window.scroll({
      top: 0
    })

  }, [])


  return (
    <div className='clases-de-bajo'>
      <Helmet>
        <title>Tincho en el Bajo</title>
      </Helmet>
      <main>

        <div className="back" id="bg1">
          <div className="filter"></div>
        </div>

          <Intro />
          <Clases />

        <div className="back back2" id="bg2">
          <Acerca />
        </div>

        {/*<div className="back back3" id="bg3">
        </div>*/}

        <div className="back back4" id="bg4">
          <div className="space"></div>
        </div>
        <Contacto />

      </main>
    </div>
  )
}
