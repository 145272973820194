import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

//import './B4.css'
import { BlogVid } from './BlogVid'
import { ScrollTop } from '../../utils/ScrollTop'


export class B5 extends Component {

  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }


  render() {
    return (
        <div className="content"> 
        <Helmet>
          <title>Tips para Ser un Mejor Bajista</title>
        </Helmet>

          <div className="cl-descrip contenido-blog">
            <h3 className='texto-titulo'>Tips para Ser un Mejor Bajista</h3>

            <div className="media my-4">
            <img src="/assets/images/perfil_nuevo_160px.jpg" className="mr-3" alt="" width="40" height="40"
                 style={{ borderRadius: '100%',  border: '2px solid var(--color-cinco)' }}
            />
            <div className="media-body">
              <h6 className="mt-0 texto-cursiva" style={{ margin: 0, fontSize: '1.6rem', color: 'var(--color-primario)' }}>Tincho</h6>
                <small className="text-muted">21 Ago 2018 - lectura de 4 min</small>
              </div>
            </div>

            <div>
              En nuestro camino de aprendizaje como Bajistas, muchas veces nos dejamos maravillar por las “luces de colores” y perdemos perspectiva que las cosas que realmente importan.
              <br/><br/>
              En este texto me gustaría compartir algunos de los conceptos que yo considero fundamentales para ser un buen bajista, y con buen bajista me refiero a estar preparado para ser un buen “performer” y responder a las demandas asociadas a un contexto de banda/grupo/orquesta.
              <br/><br/>

              <b>1) El Rol del Bajista</b>
              <br/><br/>
              Entender el rol que adoptamos como bajistas es fundamental para el buen funcionamiento de un grupo.
              <br/><br/>
              Podemos tocar cosas "flasheras" como tapping, armónicos, acordes y solos pero esto funciona solo en situaciones especiales y limitadas ya que no es lo que en general la gente de un grupo desea y/o espera de un bajista.
              <br/><br/>
              Lo que se espera de un bajista es que pueda tocar una buena <b>línea de Bajo</b>. Que sepa hacer una síntesis armónico-rítmica que sume a la canción pero dejando espacio para que sucedan otras cosas, un buen equilibrio en la orquestación.
              <br/><br/>
              Una buena línea tiene que estar en el punto justo, tiene que ser simple pero a la vez ser lo suficientemente interesante como para capturar la atención del oyente, sin opacar el foco central que está en la línea melódica del solista.
              <br/><br/>
              Rara vez un bajista es convocado para tocar solos en una banda, los bajistas que se dedican a esto suelen formar y liderar sus propias bandas y seguir un camino como solista.
              <br/><br/>
              Pero si queremos ser bajistas profesionales, es necesario trabajar en nuestras habilidades como “sideman”, o sea, tener oficio en el arte de acompañar a otros. Entender esto va a ser que la gente quiera tocar con nosotros y nos convoquen para tocar en sus grupos.
              <br/><br/>

              <b>2) Sensibilidad Rítmica</b>
              <br/><br/>
              Tener un buen sentido del tempo y del ritmo es imprescindible, no sólo para un bajista, sino para cualquier músico.
              <br/><br/>
              Podemos saber un montón de escalas, acordes, etc, pero si no podemos ejecutar una secuencia de notas sosteniendo el beat, con subdivisión precisa y estable, es lo mismo que nada.
              <br/><br/>
              Esta habilidad no la debemos dar por sentado y hay que practicar ritmo ser consciente de beat y la subdivisión. El sentido del ritmo es una de las cosas que más impacto tiene en la ejecución de una pieza musical, cuando hay groove se siente bien.
              <br/><br/>
              El ritmo es la diferencia entre tocar bien o mal y paradójicamente es una de las áreas que menos atención se le presta a la hora de practicar y enseñar.
              <br/><br/>
              Los grandes bajistas de la historia de la música pueden tener distintos estilos, formas de tocar, variar en su elección de notas, pero si hay algo que es común a todos es que son rítmicamente precisos.
              <br/><br/>

              <b>3) Lenguaje Musical</b>
              <br/><br/>
              Es muy importante entender los fundamentos del lenguaje musical y como delinear la armonía para crear o tocar una línea de bajo. Esto no da resto y oficio para aprender canciones más rápido, retenerlas en la memoria y poder ser creativos a la hora de componer.
              <br/><br/>
              Las canciones están formadas por acordes, y usualmente asociamos esto a lo que tocan los guitarristas o los tecladistas. Pero los bajistas también tocamos acordes, solo que en vez de tocar todas las notas del acorde en simultáneo, las tocamos en forma melódica, una nota por vez.
              <br/><br/>
              Saber y entender de armonía nos ayudará a tocar más allá de la tónica y entender cómo crear tensión armónica, melódica y rítmica con nuestro instrumento.
              <br/><br/>

              <b>4) Saber Escuchar</b>
              <br/><br/>
              Escuchar es una habilidad que como músicos debemos tener bien afilada. Con escuchar me refiero a ser sensible a lo que tocan los demás, y no encerrarse en uno mismo.
              <br/><br/>
              Si sabemos escuchar vamos a poder pensar la línea de bajo en contexto, conectando, reaccionando e interactuando con el resto de los instrumentos.
              <br/><br/>
              Saber escuchar demuestra madurez como músico y cuando en un grupo/banda pasa esto aparece la magia.
              <br/><br/>

              <b>5) Practicar</b>
              <br/><br/>
              Si queremos ser buenos musicos tenemos que practicar, no queda otra. Es como todo en la vida, para hacer algo bien tenemos que entenderlo en profundidad.
              <br/><br/>
              No podemos depender exclusivamente de la inspiración, porque sin trabajo la inspiración no llega. Las buenas ideas deben ser alimentadas, hay que invertir tiempo y atención en mejorar nuestras habilidades día a día.
              <br/><br/>
              Invertir tiempo concreto con el instrumento y sin el instrumento también: escuchar música con espíritu analítico y entrenar el oído, practicar escalas, arpegios, improvisar y componer, etc. Vamos a tener que hacer todo esto muchas veces, durante mucho tiempo, hasta que aparezca algo bueno. Y hay que disfrutarlo, tenemos que aprender a disfrutar más del proceso no tanto del resultado.
              <br/><br/>

              A seguir grooveando!
              <br/><br/>
              <span className='texto-cursiva' style={{ fontSize: '1.4rem'}}>Tincho </span>
              <span role="img" aria-label="emoji">🤗🎸🎶</span>
              <br/><br/>

              <BlogVid embed="https://www.youtube.com/embed/uCumoYPDxJM"
                       texto="Artículo basado en el siguiente video:"
              />

            </div>

              <ScrollTop />

            <div className='btn-anterior-proximo centro'>
              <a href="/blog/la-musica-y-la-practica" className='btn mt-4 centro'>
                <span className="material-icons">keyboard_arrow_left</span>
                <span>Anterior: "La Música y la Práctica"</span>
              </a>

              <a href="/blog/que-significa-estudiar-musica" className='btn mt-4 centro'>
                <span>Próximo: "¿Qué Significa Estudiar Música?"</span>
                <span className="material-icons">keyboard_arrow_right</span>
              </a>
            </div>

          </div>
        </div>
    )
  }
}
