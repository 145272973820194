import React from 'react'
import { Helmet } from 'react-helmet'
import Item from './Item'

import './privado.css'
import './Item.css'

import { _SERVER } from '../utils/req'

export default class Privado extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      partituras: [],
      parte: false,
      height: window.outerHeight
    }

    this.itemState = this.itemState.bind(this)
    this.backButton = this.backButton.bind(this)
  }

  async componentDidMount() {
    window.scroll({
      top: 0,
    })


    const data = await fetch(_SERVER+'/partituras.php')
    const res = await data.json()    
    //console.log('traer lista partituras: ', res)
    
    this.setState({ partituras: res })
  }

  async itemState(parte) {

    this.setState({
      ...this.state,
      parte: parte,
    })
    //console.log(parte)
    
    window.scroll({
      top: 0,
    })
  }

  backButton() {
    this.setState({ parte: false })
  }

  /* filtrarPartituras = (categoria) => {
    console.log(categoria)
    this.setState({
      categoria: categoria
    })
  } */


  render() {
    // iframe doc view height
    window.onresize = () => {
      this.setState({ height: window.outerHeight })
    }

    //console.log(this.props)
    //console.log(this.state)

    return (<>
      <Helmet>
        <title>Partituras | Tincho en el Bajo</title>
      </Helmet>

      <div className="content mb-5">
        {!this.state.parte &&
          <div className="cl-descrip" id="box-list">
            <span role="img" aria-label="emoji" style={{ fontSize: '1.6rem' }}>👋😄</span>
            <h3 className="title texto-titulo lista-partes-priv">¡Hola {this.props.sesion.name}!</h3>
            <p className="subtitle">Hacé clic en la partitura que desees visualizar.<br/>
                <span className='texto-cursiva' style={{ fontSize: '2rem', color: 'var(--color-cuatro)' }}>¡A seguir Grooveando!</span></p>

            {/* <div className='lista-etiquetas text-center my-4 text-white'>
              <span className='mx-3 my-1 grooves p-2' style={{ border: '16px' }}>
                <i className="fas fa-user-astronaut"></i> Grooves
              </span>
              <span className='mx-3 my-1 tecnica p-2'>
                <i className="fas fa-guitar"></i> Técnica
              </span>
              <span className='mx-3 my-1 transcripcion p-2'>
                <i className="fas fa-music"></i> transcripciones
              </span>
            </div> */}

            <div className="item-container">
              {this.state.partituras && this.state.partituras.map((parte, ind) => {
                return <Item parte={parte} itemState={this.itemState} key={'item_'+ind} />
              })}
            </div>      

          </div>
        }

        


        {this.state.parte &&
          <div>
            {<iframe className="view-pdf"
              src={_SERVER+this.state.parte.url}
              width="100%"
              height={this.state.height.toString()}
              title="view">
            </iframe>}

            <button
              onClick={() => {
                window.open(this.state.parte.vid_url,'_blank');
              }}
              className="video-button link gs-b centro"
              title="Ver Video">
                <span className="material-icons" style={{fontSize: '3rem'}}>play_arrow</span>
              <span></span>
            </button>

            <button onClick={this.backButton} className="back-button link gs-b centro"
              title="Volver">
                <span className="material-icons" style={{fontSize: '2.8rem'}}>arrow_back</span>
              <span></span>
            </button>
          </div>
        }
      </div>
    </>)
  }
}
