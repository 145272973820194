import React from "react";
//import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { Navbar, /* Nav */ } from 'react-bootstrap';

import './NavPage.css'
import { youtube, instagram, facebook, tiktok, spotify } from '../utils/svgs'

export class NavPage extends React.Component {
  render() {


    return (
      <Navbar className="navegador" variant="dark" expand="lg">
        <div className="container">
          <Navbar.Brand href="/" style={{ display: 'flex', alignItems: 'center'}}>
            <img
              alt=""
              src="/assets/icons/logo.png"
              className="d-inline-block align-top"
              style={{ width: '2rem', height: '2rem'}}
            />
            <div className="titulo">
              <span className="font-marca">TINCHO EN EL BAJO</span>
              <small className="nombre"></small>
            </div>
          </Navbar.Brand>

          <div className="nav-menu-lg d-flex justify-content-end w-100 ml-4">
            <Link to="/" className="nav-link btn sin-borde">Inicio</Link>
            <Link to="/musica" className="nav-link btn sin-borde">Música</Link>
            <Link to="/clases" className="nav-link btn sin-borde">Clases</Link>
            <Link to="/videos" className="nav-link btn sin-borde">Videos</Link>
            <Link to="/blog" className="nav-link btn sin-borde">Blog</Link>
            <Link to="/partituras" className="nav-link btn sin-borde">Partituras</Link>
            <div className="social">
              <a href="https://www.youtube.com/c/tinchoenelbajo" title="YouTube" target="_blank" rel="noopener noreferrer">{youtube}</a>
              <a href="https://www.instagram.com/tinchoenelbajo" title="Instagram" target="_blank" rel="noopener noreferrer">{instagram}</a>
              <a href="https://www.facebook.com/tinchoenelbajo" title="Facebook" target="_blank" rel="noopener noreferrer">{facebook}</a>
              <a href="https://www.tiktok.com/@tinchoenelbajo" title="TikTok" target="_blank" rel="noopener noreferrer">{tiktok}</a>
              <a href="https://open.spotify.com/intl-es/artist/5r1neyXPnXHHlbAt2rhelM" title="Spotify" target="_blank" rel="noopener noreferrer">{spotify}</a>
            </div>
          </div>

          <Navbar.Toggle aria-controls="basic-navbar-nav" className="burger menu-button"/>

          <Navbar.Collapse id="basic-navbar-nav">
            <Navbar.Toggle className="menu">
              <Link to="/" className="nav-link btn sin-borde">Inicio</Link>
              <Link to="/musica" className="nav-link btn sin-borde">Música</Link>
              <Link to="/clases" className="nav-link btn sin-borde">Clases</Link>
              <Link to="/videos" className="nav-link btn sin-borde">Videos</Link>
              <Link to="/blog" className="nav-link btn sin-borde">Blog</Link>
              <Link to="/partituras" className="nav-link btn sin-borde">Partituras</Link>
              <div className="social">
                <a href="https://www.youtube.com/c/tinchoenelbajo" title="YouTube" target="_blank" rel="noopener noreferrer">{youtube}</a>
                <a href="https://www.instagram.com/tinchoenelbajo" title="Instagram" target="_blank" rel="noopener noreferrer">{instagram}</a>
                <a href="https://www.facebook.com/tinchoenelbajo" title="Facebook" target="_blank" rel="noopener noreferrer">{facebook}</a>
                <a href="https://www.tiktok.com/@tinchoenelbajo" title="TikTok" target="_blank" rel="noopener noreferrer">{tiktok}</a>
                <a href="https://open.spotify.com/intl-es/artist/5r1neyXPnXHHlbAt2rhelM" title="Spotify" target="_blank" rel="noopener noreferrer">{spotify}</a>
              </div>
            </Navbar.Toggle>
          </Navbar.Collapse>
        </div>
      </Navbar>
    )
  }
}
