import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import './B3.css'
import { ScrollTop } from '../../utils/ScrollTop'

export class B3 extends Component {

  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }
  

  render() {
    return (
        <div className="content"> 
        <Helmet>
          <title>¿Cómo Elegir un Bajo?</title>
        </Helmet>

          <div className="cl-descrip contenido-blog">
            <h3 className='texto-titulo'>¿Cómo Elegir un Bajo?</h3>

            <div className="media my-4">
              <img src="/assets/images/perfil_nuevo_160px.jpg" className="mr-3" alt="" width="40" height="40"
                  style={{ borderRadius: '100%',  border: '2px solid var(--color-cinco)' }}
              />
              <div className="media-body">
                <h6 className="mt-0 texto-cursiva" style={{ margin: 0, fontSize: '1.6rem', color: 'var(--color-primario)' }}>Tincho</h6>
                <small className="text-muted">21 Oct 2017 - lectura de 12 min</small>
              </div>
            </div>

            <div>
              A la hora de ir a comprar nuestro primer bajo o de cambiar el que tenemos, disponemos de muchas alternativas. Y esto puede ser abrumador. Como elegimos entonces? generalmente solemos optar por el que más nos gusta estéticamente y la marca con más renombre dentro de nuestro presupuesto. Esto está bien. Pero a veces nos puede llevar a la decepción. Entender cuáles son las características que buscamos en un bajo nos va a ayudar a tomar una mejor decisión.
              <br/><br/>
              Antes de meternos en los detalles veamos algunas consideraciones generales:
              <br/><br/>
              Un bajo de mayor valor no necesariamente es “mejor” que uno de menor valor. Más caro no siempre implica mejor calidad, el precio puede estar influenciado por la marca, la oferta y la demanda, la especulación del vendedor, o ciertas características que tienen que ver con el coleccionismo y la historia del instrumento.
              <br/><br/>
              Hay cuestiones objetivas que pueden hacer que un bajo sea mejor que otro: la calidad de las maderas, los accesorios, los micrófonos, la construcción. Y hay cuestiones subjetivas: “me gusta cómo suena”, “es muy cómodo para tocar”, “me siento inspirado cuando toco tal instrumento”, “me gusta el look”, etc. Lo ideal es conocer cuáles son las características que nos gustan a nivel personal en concordancia con la calidad del instrumento.
              <br/><br/>
              Una creencia que muchas veces nos juega en contra es la idealización de la marca. El creer que tal bajo es mejor que tal otro por la marca o por el país en el que se fabricó es, en mi opinión, una equivocación. Hay casos y casos, hay marcas que son más consistentes que otras, pero como regla general no hay que idealizar ninguna sino ver (y tocar) los bajos en vivo y directo y evaluar a partir de la propia experiencia.
              <br/><br/>
              Por todo esto, a la hora de salir a comprar, lo recomendable es pasear por varias casas de música y pedir probar diferentes marcas y modelos. Ver cómo se siente el bajo, comparar el sonido, mirar detenidamente los detalles de terminación y construcción. Sacarnos las dudas y pedir información al vendedor (no tomar muy en serio lo que éste diga) o mejor aún chequear en Internet cuales son las características particulares tal bajo que nos gustó.
              <br/><br/>
              También hay que considerar el mercado de instrumentos usados, donde se puede no sólo negociar el precio sino conseguir instrumentos que ya no se fabrican más.
              <br/><br/>
              Es importante conocer los componentes del instrumento y las variantes que podemos encontrar en el mercado. Veamos entonces qué elementos conforman el bajo:
              <br/>
            </div>
              <img className="image" src="/assets/images/b3_partes.jpg" alt="" />

            <div>

              <b>Cuerpo (Body):</b>
              <br/>
              Es la parte de madera que le da forma al bajo. En la figura de arriba sería la porción grande de madera oscura donde están alojados los micrófonos y el puente. El cuerpo puede variar en tamaño, formas, colores y tipos de maderas. Las maderas usadas en bajos de calidad son macizas, aunque el cuerpo puede estar formado por varios pedazos de la misma o distintas maderas. Las más usadas son: Fresno (Ash), Aliso (Alder), Arce (Maple), Caoba (Mahogany), Nogal (Walnut), Picea (Spruce), Tilo (Basswood).
              <br/><br/>
              El cutaway inferior y superior es el corte del cuerpo en la conjunción con el mástil que le da forma a los llamados “cuernos”. Algunos modelos de bajo no tienen cuernos (Hofner Violin Bass) y otros tienen uno solo (Telecaster Bass ó Fodera). El cutaway inferior suele ser más profundo para dar acceso a las notas agudas.
              <br/><br/>

              <b>Micrófonos (Pickups):</b>
              <br/>
              También llamados pastillas en España. Es el componente que transforma la vibración generada por las cuerdas en pulsos eléctricos que luego son reproducidos por nuestro equipo de amplificación. Típicamente los micrófonos usados en bajos son magnéticos aunque también los hay, más raramente, piezoeléctricos.
              <br/><br/>
              Los micrófonos magnéticos funcionan mediante un principio físico muy sencillo que tiene que ver con una relación entre el magnetismo y la corriente eléctrica. El micrófono está conformado por una serie de imanes que están rodeados por un un alambre de cobre, lo que conocemos como bobina. La cuerda al vibrar produce perturbaciones en el campo magnético del imán que genera pequeñas corrientes eléctricas en la bobina. Esos pulsos eléctricos contienen la información acústica de la vibración que son luego transformados en ondas mecánicas por nuestro equipo amplificador.
              <br/><br/>
              Hay bajos que utilizan micrófonos en distintas combinaciones de bobinado y polaridad para cancelar el ruido. Básicamente existen 2 tipos: bobinado simple (single coil) y bobinado doble (dual coil o humbucker).
              <br/><br/>
              La respuesta tímbrica de los micrófonos magnéticos depende de la calidad del bobinado.
              <br/><br/>
              La posición en la que están puestos los micrófonos va ser determinante para el tipo de sonido que este amplifique. Un micrófono puesto cerca del mástil va a tener un sonido rico en graves, gordo y poco definido, en cambio uno que esté situado cerca del puente va a ser rico en agudos y medios, más definido pero con pocos graves.
              <br/><br/>

              <b>Potenciómetros (Knobs):</b>
              <br/>
              Son las perillas que nos permiten ajustar el volumen de los micrófonos y el control de tono. El tono nos permite cambiar el timbre del sonido, agregando agudos a medida que movemos la perilla en sentido horario.
              <br/><br/>

              <b>Bajos pasivos y activos:</b>
              <br/>
              Hasta ahora todas las características que vimos en los micrófonos y potenciómetros corresponden a bajos pasivos. Los bajos activos en cambio, disponen de un circuito integrado, llamado preamplificador, que tiene como función elevar la señal eléctrica generada por los micrófonos. Esta tecnología es un poco más sofisticada y da como resultado una señal más limpia y de más amplitud, una mejor respuesta en frecuencias y la posibilidad de controlar el volumen de agudos, medios y bajos, lo que llamamos ecualizador. Es decir, en los potenciómetros de un bajo activo tenemos la posibilidad de atenuar o levantar los agudos, medios y graves obteniendo más posibilidades tímbricas. La contra de los bajos activos es que necesitan alimentación externa, una o dos pilas de 9v. Si te quedás sin batería y tu bajo es únicamente activo, no funciona. Hay bajos que disponen de una perilla que permite cambiar de modo pasivo a activo y viceversa, lo cual es muy conveniente.
              <br/><br/>

              <b>Jack:</b>
              <br/>
              Es donde conectamos el cable para poder amplificar nuestro bajo.
              <br/><br/>

              <b>Puente (Bridge):</b>
              <br/>
              Es la pieza de metal en el cual están apoyadas las cuerdas. El puente nos permite controlar la altura de las cuerdas y el largo de la porción de cuerda que vibra. La calidad, tipo de material y tamaño del puente puede influir en el sonido del instrumento.
              <br/><br/>

              <b>Pickguard:</b>
              <br/>
              Es una lámina de plástico o acrílico que sirve para proteger el cuerpo del bajo de los arañazos y golpes de púas al tocar. Típicamente asociado a bajos tipo precision, jazz bass y modelos vintage. Los bajos modernos suelen no tener pickguard.
              <br/><br/>

              <b>Mástil (Neck):</b>
              <br/>
              Es la porción de madera que sujetamos con nuestra mano izquierda. El Diapasón es la superficie donde van colocados los trastes, que son las varillas de metal que nos permiten tocar distintas notas musicales. El número de trastes puede variar entre 20 y 24 dependiendo del bajo. En los bajos de calidad las maderas más utilizadas son arce (maple) para el mástil y palo de rosa (rosewood) para el diapasón. Otra variante es una única pieza de maple para el mástil y el diapasón (como se ve en la figura). En teoría, el diapasón de maple nos da un tono más filoso, con más agudos y el de rosewood un poco más cálido, con menos agudos.
              <br/><br/>

              <b>Cejuela:</b>
              <br/>
              Es una varilla de hueso, plástico ó, menos común, de metal donde se apoyan las cuerdas a través de unas ranuritas. Es el punto donde la cuerda de un lado vibra y del otro no. La cejuela va a determinar la altura mínima a la que podemos fijar la cuerda en relación al diapasón. Algo común en bajos baratos (o mal ajustados) es que la cejuela esté muy alta y esto haga que sea incómodo y difícil de tocar debido a la altura y la tensión de las cuerdas. Esto se puede solucionar llevándolo a un profesional de la construcción de instrumentos, al cual comúnmente se le llama Luthier, para ajustar y/o cambiar la cejuela.
              <br/><br/>

              <b>Alma o Tensor (Truss Rod):</b>
              <br/>
              Es una barra de metal que se encuentra dentro del mástil que sirve para ajustar la curvatura de éste. Algunas almas se ajustan desde el extremo inferior del mástil (como en la figura) y otras desde el otro extremo, arriba de la cejuela, dependiendo del modelo del bajo. El mástil debería estar siempre derecho, con apenas una pequeña curvatura. Si el mástil se ve doblado a simple vista, no es una buena señal.
              <br/><br/>

              <b>Clavijas:</b>
              <br/>
              Son los engranajes donde van enrollados los extremos de las cuerdas y sirven para ajustar la tensión de éstas, cambiando la afinación.
              <br/><br/>
              <hr/>
              Veamos ahora algunos modelos de bajos que sentaron un estándar en su diseño y construcción:
            </div>

              <img className="image" src="/assets/images/precision.jpg" alt="" />
            <p>
              <b>Fender Precision Bass</b>
              <br/><br/>
              Este fué el primer modelo de bajo eléctrico fabricado en serie del mundo. Los primeros ejemplares datan de 1951. Su estética y sonido sentó un estándar en el mercado e influeció innumerables diseños de bajos en esa época y en la actualidad. Se dice que el 90% de la discografía de todos los tiempos fue grabada con este bajo. Un clásico. Hay otros fabricantes que hacen réplicas de este modelo y a éstos bajos se les refiere como P-bass. Es un bajo de diseño simple y bello. Consta de dos micrófonos single coil, con bobinado inverso para cancelar el ruido magnetico. Cada uno se ocupa de 2 cuerdas y funcionan como una sola unidad. Tiene un control para el volumen y otro para el tono. Posee un sonido muy característico, profundo, cálido y “roto”.
            </p>
            <hr/>

              <img className="image" src="/assets/images/jazzbass.jpg" alt="" />
            <p>
              <b>Fender Jazz Bass</b>
              <br/><br/>
              Es un modelo que fue lanzado por Fender en 1960 que introduce varias diferencias respecto al Precision. El cuerpo tiene un diseño offset, es decir que las curvas, lo que sería la “cintura”, del bajo están desfasadas para hacerlo más ergonómico. El mástil a la altura de la cejuela es más fino lo que lo hace más cómodo para tocar. Tiene dos micrófonos single coil, en distintas posiciones, uno cerca del puente y otro cerca del mástil, lo que permite más combinaciones sonoras. Cuenta con un control de volumen para cada micrófono y un control de tono. Tiene un sonido profundo y filoso, rico en medios y agudos.
            </p>
            <hr/>

              <img className="image" src="/assets/images/stingray.jpg" alt="" />
            <p>
              <b>Musicman Stingray</b>
              <br/><br/>
              En 1965 Leo Fender dueño de la compañia de guitarras y bajos Fender vende su empresa a Columbia (CBS). Seis años después, junto a dos antiguos colaboradores fundan en 1971 una empresa llamada Musitek que tres años después pasó a llamarse Musicman. Desde allí introdujo el modelo Stingray cuyo primer ejemplar salío al mercado en el 76. Este modelo introdujo varias novedades: primer bajo fabricado en serie con circuito activo con eq de dos bandas, un micrófono humbucker, un puente grande, que da más resonancia, con mutes que permite apagar un poco la vibración de la cuerda dando un timbre más opaco si se desea. Otra novedad es la configuración de las clavijas 3+1. Es un bajo con mucho punch, con un sonido agresivo y al frente.
            </p>
            <hr/>

              <img className="image" src="/assets/images/fodera.jpg" alt="" />
            <p>
              <b>Bajos Modernos</b>
              <br/><br/>
              Muchos diseños modernos de alta gama tienen combinaciones de distintas maderas con vetas bellas y llamativas. Generalmente son bajos activos, algunos con opción de modo pasivos también. Dos micrófonos Soupbar (barra de jabón) o Humbucker, dispuestos en forma similar al jazz bass. Podemos encontrar bajos de 5 y 6 cuerdas. Un bajo de 5 cuerdas tiene típicamente una cuerda más grave agregada, un Si grave (4ta abajo de la cuarta cuerda mi). También hay casos donde la cuerda agregada es un Do agudo (una 4ta arriba de la primer cuerda sol). Los bajos de 6 cuerdas tienen las dos opciones integradas, el Si grave y el Do agudo. Otra variante son los bajos Fretless, que no tienen trastes, es decir el diapasón es liso. No se asusten, vienen con marcas para saber dónde poner los dedos. Este tipo de instrumento tiene cierta cualidad lírica, que permite que el bajo cante. El principal exponente de bajo fretless es Jaco Pastorius, el primer gran virtuoso del bajo.
            </p>
            <hr/>

              <img className="image" src="/assets/images/hollow2.jpg" alt="" />
            <p>
              <b>Bajos de caja hueca (Hollowbody) y acústicos</b>
              <br/><br/>
              Son bajos cuyos cuerpos son huecos o semihuecos. Los bajos hollowbody tienen un diseño vintage, con perillas y accesorios cromados y micrófonos magnéticos pasivos. Se caracterizan por poseer en el cuerpo “agujeros” en forma de f (llamadas “efes”).
              Los bajos acústicos tienen un estilo clásico similar a las guitarras criollas y poseen generalmente micrófonos piezoeléctricos. Su sonido es grave, con mucho cuerpo. Ninguno de estos dos tipos de instrumentos sirve para tocar acústicamente (sin enchufar) en una situación de grupo, aunque el bajo acústico suele tener más volumen acústico que el hollowbody.
              <br/><br/>
            </p>
              <hr/>
            <p>
              <b>Fabricantes</b>
              <br/><br/>
              La siguiente lista NO está confeccionada con el objetivo de recomendar ninguna marca en particular sino de mostrar algunas de las opciones que podemos encontrar en el mercado:
              <br/>
              Diseños modernos: Fodera, Ken Smith, Michael Tobias Design, Zon, Modulus, Warwick, Spector.
              <br/>
              Diseños modernos y clásicos: Yamaha, Ibanez, Fernandes, Epiphone, G&L, ErnieBall Musicman.
              <br/>
              Diseños clásicos: Hofner, Rickenbacker, Gibson, Fender, Sadowsky, Lakland.
              <br/>
              En nuestro país, Argentina, hay Luthiers que fabrican Instrumentos de excelente calidad como lo son Pablo Batán y Mariano Maese entre muchos otros.
              <br/>
              Algunas de las grandes empresas tienen segundas marcas para la gama de instrumentos económica: Squier (Fender), Epiphone (Gibson), SUB (Sterling Musicman). Y marcas como Cort, Washburn, Dean, Schecter, Esp, SX tienen la mayor parte de sus productos en la gama económica.
              <br/><br/>
            </p>
              <hr/>
            <div>
              <b>Conclusión</b>
              <br/><br/>
              Una posibilidad sería elegir en función a lo que ya sabemos que funciona, algunas personas nos podrán decir: “si querés tocar tal estilo tenés que tener tal bajo”. En mi opinión lo divertido es romper con los estereotipos y cualquier bajo potencialemente puede ser usado en cualquier situación si el que lo ejecuta tiene criterio y visión. Pero obviamente todos estamos un poco influenciados por ciertos estereotipos y elegir en función de eso está perfecto también. 
              <br/><br/>
              Lo importante es entender que un bajo no nos define como músicos, es sólo el instrumento que usamos para expresarnos. El verdadero valor está en nuestra habilidad y la capacidad para expresar nuestras ideas musicalmente, y el bajo que elijamos tiene que enamorarnos y dar ganas de tocarlo, por su imagen, por su tacto, por su sonido, por su historia y lo que representa para nosotros.
              <br/><br/>
              <span className='texto-cursiva' style={{ fontSize: '1.4rem'}}>Tincho </span>
              <span role="img" aria-label="emoji">🤗🎸🎶</span>
              <br/><br/>

              <ScrollTop />

              <div className='btn-anterior-proximo centro'>
                <a href="/blog/el-cuidado-del-oido" className='btn mt-4 centro'>
                  <span className="material-icons">keyboard_arrow_left</span>
                  <span>Anterior: "El Cuidado del Oído"</span>
                </a>

                <a href="/blog/para-que-sirve-la-teoria-musical" className='btn mt-4 centro'>
                  <span>Próximo: "¿Para Qué Sirve la Teoría Musical?"</span>
                  <span className="material-icons">keyboard_arrow_right</span>
                </a>
              </div>

            </div>

          </div>
        </div>
    )
  }
}
