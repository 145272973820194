import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import { BlogVid } from './BlogVid'
import { ScrollTop } from '../../utils/ScrollTop'

export class B10 extends Component {

  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }


  render() {
    return (
        <div className="content"> 
          <Helmet>
            <title>¿Qué Necesito para Empezar a Tocar el Bajo?</title>
          </Helmet>

          <div className="cl-descrip contenido-blog">
            <h3 className='texto-titulo'>¿Qué Necesito para Empezar a Tocar el Bajo?</h3>

            <div className="media my-4">
            <img src="/assets/images/perfil_nuevo_160px.jpg" className="mr-3" alt="" width="40" height="40"
                 style={{ borderRadius: '100%',  border: '2px solid var(--color-cinco)' }}
            />
            <div className="media-body">
              <h6 className="mt-0 texto-cursiva" style={{ margin: 0, fontSize: '1.6rem', color: 'var(--color-primario)' }}>Tincho</h6>
                <small className="text-muted">22 Agosto 2024 - lectura de 7,5 min</small>
              </div>
            </div>
            
            <div>


Si estás por empezar a tocar el Bajo y no sabés bien qué es lo que necesitás: qué bajo comprar, como amplificarlo y otras cuestiones esenciales que todo bajista necesita conocer, en este artículo vamos a estar hablando de todo eso, para que puedas tomar decisiones informadas a la hora de equiparte y acomodarlas a tus posibilidades.
<br/><br/>
<b>¿Cómo funciona?</b>
<br/><br/>
Empecemos entendiendo cómo es que funciona nuestro instrumento, el Bajo.
Cuando pulsamos una cuerda, esta vibra, y esa vibración se propaga a través de las moléculas de aire que rodea todo el espacio en el que estamos, y de esa forma nos llega al oído, que nuestro cerebro traduce como sonido.
<br/><br/>
Esa vibración, o también llamada oscilación, puede variar en velocidad, que medimos como frecuencia, es decir la cantidad de oscilaciones por segundo. Nuestro oído percibe estas frecuencias como notas: si la frecuencia predominante es tal será un Do, si es tal otra será un La, y así con cada nota.
<br/><br/>
    <div className="card my-0" style={{overflow: 'hidden'}}>
      <img src="/assets/images/b10/b10-1.jpg" className="card-img-top" alt=""/>
      <div className="card-body">
        <div className="card-text" style={{margin: '0'}} >La segunda cuerda (Re) del Bajo vibrando.</div>
      </div>
    </div>
<br/>
Pero como seguramente te habrás dado cuenta, el sonido que nos llega del bajo así no más, sin “enchufar”, es decir el sonido acústico, es muy débil, por lo tanto para poder escucharlo con claridad deberemos amplificarlo.
<br/><br/>
¿Cómo logramos amplificar la vibración de las cuerdas? Puede parecer complicado, pero es bastante sencillo, así como la vibración se propaga a través del aire, también lo hace a través de la materia (de hecho, el aire es materia), y en particular de los electrones.
<br/><br/>
Los micrófonos del bajo, o también llamadas pastillas, consisten en imanes rodeados por un alambre de cobre, una bobina. Cuando la cuerda vibra, el campo magnético de los imanes capta esa vibración y los electrones del alambre empiezan a vibrar también, generando pulsos eléctricos.
<br/><br/>
    <div className="card my-0" style={{overflow: 'hidden'}}>
      <img src="/assets/images/b10/b10-2.jpg" className="card-img-top" alt=""/>
      <div className="card-body">
        <div className="card-text" style={{margin: '0'}} >El Micrófono de un Bajo.</div>
      </div>
    </div>
<br/>
Esos pulsos eléctricos contienen toda la información de nuestro sonido, pero son muy débiles por sí mismos como para volver a transformarse en una onda acústica potente. Por eso necesitaremos Amplificarlos.
<br/><br/>
El amplificador entonces convierte esos pulsos débiles en una una señal fuerte, suficientemente potente como para poder generar movimiento mecánico y hacer vibrar esas moléculas de aire con fuerza para escuchar un sonido claro y fuerte.
<br/><br/>
    <div className="card my-0" style={{overflow: 'hidden'}}>
      <img src="/assets/images/b10/b10-3.jpg" className="card-img-top" alt=""/>
      <div className="card-body">
        <div className="card-text" style={{margin: '0'}} >El circuito de un Amplificador de Bajo.</div>
      </div>
    </div>
<br/>
El encargado de generar ese movimiento mecánico es el parlante que, al recibir la señal que envía el amplificador, vibra traduciendo esa señal eléctrica nuevamente en sonido alto y claro.
<br/><br/>
    <div className="card my-0" style={{overflow: 'hidden'}}>
      <img src="/assets/images/b10/b10-4.jpg" className="card-img-top" alt=""/>
      <div className="card-body">
        <div className="card-text" style={{margin: '0'}} >La Caja o Gabinete de Bajo reproduce el sonido a través del Parlante (Bafle/Altavoz) que se encuentra en el centro.</div>
      </div>
    </div>
<br/>
Entender cómo funciona esta cadena y cada uno de los elementos que la componen es fundamental. Ahora que lo comprendemos a nivel general pasemos a lo particular, empecemos con el Bajo.
<br/><br/>
<b>El Bajo</b> es un instrumento muy simple, compuesto por 2 pedazos de madera, el mástil y el cuerpo, cuerdas y electrónica (mics y potenciómetros).
<br/><br/>
Están las clavijas que nos sirven para tensionar las cuerdas y conseguir la afinación adecuada, las cuerdas se apoyan en la cejuela y están enganchadas en el puente.
<br/><br/>
Dentro del mástil tenemos el tensor o alma, que es una varilla de metal que nos permite mantenerlo casi recto ya que la madera puede ceder la tensión de las cuerdas y empezar a curvarse. El tensor se puede ajustar con una llave alem que deberían darte junto con el bajo al momento de comprarlo.
<br/><br/>
    <div className="card my-0" style={{overflow: 'hidden'}}>
      <img src="/assets/images/b10/b10-5.jpg" className="card-img-top" alt=""/>
      <div className="card-body">
        <div className="card-text" style={{margin: '0'}} >Las partes de un Bajo Eléctrico.</div>
      </div>
    </div>
<br/>
Hay muchos modelos de bajos, los hay de 4, 5 y 6 cuerdas, con 1 o varios micrófonos, con trastes y sin trastes, pasivos y activos, algunos que tienen más trastes que otros, distintas formas y colores, etc.
<br/><br/>
Te invito a leer <a href="/blog/breve-historia-del-bajo-electrico">este artículo</a> donde profundizo más acerca de la evolución de los bajos y sus características. 
<br/><br/>
Y te voy a dar mi opinión y <b>recomendación</b> por lo menos a la hora de <b>elegir tu primer bajo:</b>
<br/><br/>
El bajo más recomendable y que a mi parecer es el estándar que todo bajista debería tener es uno de 4 cuerdas, con trastes, pasivo, cuerpo rígido y preferentemente 2 mics ya que te dará más versatilidad de sonidos.
<br/><br/>
Luego podés elegir la marca y estética que mejor se adapte a tus posibilidades y gustos pero cualquier bajo con estas características te va a servir para empezar. Hay mucha variedad de precios y opciones y todas están bien. La vida es larga, y tendrás tiempo para cambiarlo por una marca mejor o adquirir otro con distintas características a medida que ganes experiencia y sepas lo que buscas.
<br/><br/>
Los Bajos de 5 y 6 cuerdas tienen un mástil ancho, incómodo, que requieren una buena técnica de muteo para lograr un sonido limpio y además las cuerdas extra te van a marear.
Y los bajos activos llevan batería, que si se gasta debes reemplazar porque sino no se escucha. Y los fretless (sin trastes) tampoco son recomendables para empezar, si es que querés sonar afinado.
<br/><br/>
Pasemos al <b>Amplificador</b>. Antes que nada, para que el amplificador reciba la señal de nuestro bajo, deberemos conectarlos a través de un <b>cable</b>.
<br/><br/>
En argentina le decimos cable plug, en otros países puede llamarse cable TS, y en general se lo conoce como cable de instrumento. Es un cable simple, mono, que se conecta al jack de nuestro bajo y al input del amplificador.
<br/><br/>
Dentro del amplificador la señal pasa por el <b>Pre</b>, donde podemos modelarla con el <b>ecualizador</b>. ¿Cómo funciona el EQ? bueno, ya vimos que cuando pulsamos una cuerda en el bajo esto produce una vibración, una oscilación, y la velocidad de oscilación es la frecuencia, pero esta oscilación en realidad está compuesta por muchas frecuencias y ese conjunto de frecuencias son la que definen el <a href="https://es.wikipedia.org/wiki/Timbre_(ac%C3%BAstica)" target="_blank" rel='noopener noreferrer'>timbre</a> de nuestro instrumento. 
<br/><br/>
Hay una frecuencia que predomina, llamada <b>frecuencia fundamental</b>, y por eso escuchamos la nota, que puede ser un DO (262 Hz), o un LA (440 Hz), pero cada nota está compuesta a su vez por una superposición de frecuencias. El EQ nos permite subir o bajar estas frecuencias, agrupandolas principalmente en 3 bandas: graves (low/bass), medios (mid) y agudos (high/treble).
<br/><br/>
    <div className="card my-0" style={{overflow: 'hidden'}}>
      <img src="/assets/images/b10/b10-6a.jpg" className="card-img-top" alt=""/>
      <div className="card-body">
        <div className="card-text" style={{margin: '0'}} >Amplificador (o Cabezal) para Bajo.</div>
      </div>
    </div>
<br/>
Luego del pre viene el circuito que amplifica la señal y le da la potencia, medida en watts, para que pueda transformarse en sonido al conectar un parlante. Cuanto más watts más volumen y graves podremos alcanzar.
<br/><br/>
25 a 50 watts son ideales para practicar en tu casa o para tocar en formato acústico a volumen bajo.
<br/><br/>
Para tocar en ensayos o en un club o bar pequeño y tener un buen volumen que corte la guitarra y la bata por lo menos necesitas 60 watts.
<br/><br/>
De ahí en más ya es cuestión de gustos y/o necesidades. Lo ideal para tocar en vivo es tener algo de por lo menos 90 watts. 
<br/><br/>
No es recomendable que toques con el volumen al máximo, siempre es bueno tener un resto, para no exigir el ampli y que se traduzca en un sonido de buena calidad.
<br/><br/>
Si estás empezando no es necesario que inviertas en un amplificador para escucharte, podés comprarte un <b>amplificador de auriculares</b>, que son accesibles y muy buenos y con el que podrás practicar a gusto sin problemas.
<br/><br/>
    <div className="card my-0" style={{overflow: 'hidden'}}>
      <img src="/assets/images/b10/b10-6.jpg" className="card-img-top" alt=""/>
      <div className="card-body">
        <div className="card-text" style={{margin: '0'}} >Amplificador de Auriculares para Bajo 🎧.</div>
      </div>
    </div>
<br/>
También existen los COMBOS, que son equipos que tienen el amplificador y parlante integrado, ya que si los compras por separado será mucho más caro.
<br/><br/>
Existen combos de práctica pequeños y fáciles de trasladar y combos más grandes y potentes para tocar en shows.
<br/><br/>
Finalmente, hablemos del <b>Parlante</b>, que es el elemento que traduce la señal eléctrica en acústica. El parlante es un cono que tiene un gran imán y dentro de ese imán una bobina por donde circula nuestra señal eléctrica y lo magnetiza, de esa forma tenemos 2 imanes que se atraen y repelen, y esto genera movimiento, que se propaga en el cono y convierte en onda acústica.
<br/><br/>
    <div className="card my-0" style={{overflow: 'hidden'}}>
      <img src="/assets/images/b10/b10-7.jpg" className="card-img-top" alt=""/>
      <div className="card-body">
        <div className="card-text" style={{margin: '0'}} >Componentes de un Parlante 🔊.</div>
      </div>
    </div>
<br/>
Cuanto más grande el cono y el imán, más frecuencias graves podremos reproducir, más gordo se va a escuchar, pero para esto necesitaremos más potencia, más watts.
<br/><br/>
En argentina a los parlantes les decimos Cajas, por su forma generalmente cuadrada o rectangular, y la cantidad de pulgadas se refiere al diámetro del parlante. Por ejemplo una caja de 15 significa que tiene un parlante de 15”, también hay cajas 2x10, que contienen 2 parlantes de 10”.
<br/><br/>
Tené en cuenta que si tienes una amplificador de 350 watts y una caja de 150 watts, corres el riesgo de romper la caja, ya que el parlante no resistiría una potencia superior a 150 watts. Por eso si compras el ampli y la caja por separado tenés que fijarte en este detalle: la potencia de la caja tiene que ser igual o superior a la potencia del ampli.
<br/><br/>
Muchas veces es mejor y conveniente comprar un combo, con el ampli y el parlante integrados, que termina siendo una solución más barata y sencilla.

                <br/><br/>
                A seguir grooveando!
                <br/><br/>
                <span className='texto-cursiva' style={{ fontSize: '1.4rem'}}>Tincho </span>
                <span role="img" aria-label="emoji">🤗🎸🎶</span>
                <br/><br/>

                Artículos recomendados:<br/>

                <a href='/blog/como-elegir-un-bajo'>- ¿Cómo Elegir un Bajo?</a><br/>
                <a href='/blog/breve-historia-del-bajo-electrico'>- Breve Historia del Bajo</a><br/>

                <br/>

                
                <BlogVid embed="https://www.youtube.com/embed/ACtF5km91WI"
                         texto="Este texto está basado en el siguiente video:"
                />

                <br/>
                  <ScrollTop />

                  <div className='btn-anterior-proximo centro'>
                    <a href="/blog/encontrando-nuestra-voz-como-musicos" className='btn mt-4 centro'>
                      <span className="material-icons">keyboard_arrow_left</span>
                      <span>Anterior: "Encontrando Nuestra Voz Como Músicos"</span>
                    </a>

                    <a href="/blog/****" className='btn mt-4 centro' style={{display: 'none'}}>
                      <span>Próximo: "****"</span>
                      <span className="material-icons">keyboard_arrow_right</span>
                    </a>
                  </div>

                  
                <br/>              
            </div>

          </div>
        </div>
    )
  }
}
