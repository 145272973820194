import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
// Components
import { Intro } from './Components/Intro'
import { Clases } from './Components/Clases'
//import { Acerca } from './Components/Acerca'
import { Contacto } from './Components/Contacto'

import './Inicio.css'

import {caretDown} from '../utils/svgs'


export function Inicio() {
  // scroll arriba
  useEffect(() => {
    window.scroll({
      top: 0
    })

  }, [])
  
  function scrollFunction() {
    const element = document.getElementById("prueba");
    element.scrollIntoView({behavior: 'smooth'});
  }

  return (
    <div>
      <Helmet>
        <title>Tincho en el Bajo</title>
      </Helmet>
      <main>

        <div className="back back1 inicio" id="bg1">
          {/* <div className="filter"></div> */}
          <div className='caret' onClick={scrollFunction}>
            {caretDown}
          </div>
          <img src="/assets/img/portada_web_nov23.jpg" alt='' />
        </div>
          
          <div style={{position:'relative'}}>
            <div id="prueba" className='mb-5' style={{position:'absolute',bottom:0}}></div>
          </div>

          <Intro />

          <Clases />

        {/* <div className="back back2" id="bg2">
          <Acerca />
        </div> */}

        {/*<div className="back back3" id="bg3">
        </div>*/}

        <div className="inicio back back4" id="bg4">
          <div className="space"></div>
        </div>
        <Contacto />

      </main>
    </div>
  )
}
