import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import { BlogVid } from './BlogVid'
import { ScrollTop } from '../../utils/ScrollTop'

export class B8 extends Component {

  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  render() {
    return (
        <div className="content"> 
          <Helmet>
            <title>Breve Historia del Bajo Eléctrico</title>
          </Helmet>

          <div className="cl-descrip contenido-blog">
            <h3 className='texto-titulo'>Breve Historia del Bajo Eléctrico</h3>

            <div className="media my-4">
            <img src="/assets/images/perfil_nuevo_160px.jpg" className="mr-3" alt="" width="40" height="40"
                 style={{ borderRadius: '100%',  border: '2px solid var(--color-cinco)' }}
            />
            <div className="media-body">
              <h6 className="mt-0 texto-cursiva" style={{ margin: 0, fontSize: '1.6rem', color: 'var(--color-primario)' }}>Tincho</h6>
                <small className="text-muted">23 Noviembre 2023 - lectura de 10 min</small>
              </div>
            </div>
            
            <img src="/assets/images/b8/indiana_90px.png" className="card-img-top mb-4" alt="" style={{width: '90px'}} />
            
            <div>

            El bajo eléctrico es un instrumento relativamente joven. Si bien hubo algunos experimentos anteriores, al día de hoy está comúnmente aceptado que el primer Bajo Eléctrico fue fabricado alrededor de 1935 por un músico y fabricante de instrumentos llamado <b>Paul Tutmarc</b>. 
            <br/><br/>
            El Bajo se llamó <b>Audiovox Bass Fiddle 736</b>, tenía una escala de 30,5 pulgadas, de madera maciza de nogal, un mic magnético, un control de volumen y 16 trastes. 
            <br/><br/>
            <div className='nov-vid mb-3'>
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/aF6usTMwicM?si=j1OGHZz3m7EeAUV-" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
            Más allá de lo innovador, el instrumento no tuvo éxito comercial. Al día de hoy se conoce el paradero de 4 de estos bajos, 3 están en manos de coleccionistas privados y 1 en el <a href="https://mopop.emuseum.com/objects/8245/audiovox-model-736-electric-bass-guitar-c-1935?ctx=5984f8bf232092080c53844a65c2559a1f4c9c9d&idx=10" target='_blank' rel='noopener noreferrer'>Museo de Cultura Pop de Seattle.</a>
            <br/><br/>
            A fines de los años 40 la guitarra eléctrica empezó a aumentar su popularidad. Pequeños grupos que tocaban Boogie Woogie, Rhythm & Blues o música country comenzaban a surgir y a ponerse de moda, demandando instrumentos que pudieran amplificarse fácilmente, que sean resistentes y fáciles de trasladar.
            <br/><br/>
            En ese contexto, en 1948 <b>Leo Fender</b> diseña una guitarra de cuerpo sólido, algo que hasta ese momento era poco común, y en 1950 lanza al mercado la Fender Esquire, y más tarde le agrega otro mic y cambia el nombre a Telecaster, modelo que se fabrica hasta el día de hoy.
            <br/><br/>
            Ese mismo año, comienza a desarrollar un bajo eléctrico de diseño similar a la Telecaster, y en octubre de <b>1951</b> comienza la fabricación del <b>Precision Bass.</b>
                        
            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/pb-1951.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Precision Bass de 1951 por Leo Fender.</div>
              </div>
            </div>
            
            Se llamó <b>Precision Bass</b> porque el hecho de tener trastes permitía tocar todas las notas con afinación precisa, posibilitando que otros músicos, por ejemplo Guitarristas, puedan también tocarlo con facilidad, cosa que con un contrabajo, que era lo normal hasta el momento, no pasaba. Estaba hecho de madera maciza, un mástil de maple con 20 trastes con una escala de 34" y un micrófono (pastilla) magnético con control de volumen y tono.
            
            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/elvis-banda-con-contrabajo.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Elvis Presley con su Banda cerca de 1950. El Contrabajo era el instrumento grave más usado hasta ese momento.</div>
              </div>
            </div>

            Otras ventajas del bajo de Fender respecto al contrabajo es que era muy fácil de transportar, más barato y resistente, y permitía amplificarlo sin problemas de feedback, con un sonido potente que se acoplaba muy bien a la música nueva de la época.
            <br/><br/>

            <div className='texto-cursiva' style={{fontSize:'2rem', fontWeight:'700'}}>La década de los 50</div>

            El Precision Bass fue sin duda un instrumento revolucionario. En los 50 la música estaba cambiando, empezó a popularizarse el rhythm and blues y el rock and roll, músicas de raíz afroamericana que surgen del blues, el gospel, el jazz y el country. Era una música intensa y bailable que hizo eco en las generaciones de jóvenes. Muchos bajistas empezaron a dejar el contrabajo para pasarse al <b>sonido eléctrico</b>, entre los pioneros del instrumento encontramos a Monk Montgomery (el hermano de Wes, el legendario guitarrista), bajista de jazz quien adoptó este instrumento para salir de gira con Lionel Hampton en el 53, Shifty Henry bajista de blues, Bill Black el bajista de Elvis Presley. Más tarde, músicos sesionistas como James Jamerson, Joe Osborn, Donald Dunn y Carol Kaye esparcieron este sonido en infinidad de grabaciones.
            <br/><br/>
            Para competirle, en 1953 <b>Gibson</b> lanzó su versión del bajo eléctrico, el <b>EB-1.</b> En lugar de diseñarlo similar a una guitarra, como hizo Fender, le dieron una forma similar a un contrabajo, hasta tiene el detalle de las "efes" pintadas. Hecho con madera maciza de caoba, con una escala de 30,5" (más pequeño que el Precision que tenía 34").
            
            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/eb-1.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Gibson EB-1 de 1953.</div>
              </div>
            </div>

            Otros fabricantes se fueron sumando, en 1956 <b>Höfner</b> comienza a fabricar su modelo <b>500/1</b>, comúnmente conocido como el Violin Bass, popularizado más tarde por Paul Mccartney. Con un cuerpo de caja hueca, que lo hace muy liviano y con un sonido muy cálido y profundo similar a un contrabajo.
            
            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/hofner.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Höfner 500/1 Violin Bass (1956).</div>
              </div>
            </div>

            Y en 1957 <b>Rickenbacker</b> presenta su modelo <b>4000:</b>
            
            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/ricken-4000.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Rickenbacker 4000 (1957)</div>
              </div>
            </div>

            Otro bajo con un diseño muy particular es el <b>Longhorn Bass</b> de <b>Danelectro:</b>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/dane-long.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Danelectro Longhorn Bass (1958)</div>
              </div>
            </div>

            Pero el Precision lideraba el mercado y se convirtió en el estándar de la industria de bajos eléctricos.
            <br/><br/>
            A lo largo de estos primeros años Fender fue introduciendo mejoras en el diseño de su bajo. En 1954 cambia la forma del cuerpo del Precision de “Slab” (plano) a “Contour” (contorno), moldeando el contorno del instrumento para que sea más cómodo al cuerpo. En 1957 Fender presentó un rediseño del Precision, se modificó el pickguard, el clavijero y se le agregó el icónico mic (o pastilla) simple dividida para eliminar el ruido magnético (humbucker).
            <br/><br/>
            El nuevo mic estaba dividido en 2 partes, una parte para las 2 cuerdas agudas y otra para las 2 graves, con bobinado inverso para evitar ruidos e interferencias. Este mic está ubicado en el centro, entre el mástil y el puente. Estas características le dan al Precision un tono profundo, un sonido grande.
            <br/><br/>
            Este diseño ha probado resistirse al paso del tiempo, convirtiéndose en un clásico: El Precision que se fabrica en la actualidad es esencialmente el mismo.
            
            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/pb-1957.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Rediseño Fender Precision de 1957.</div>
              </div>
            </div>            

            <div className='texto-cursiva' style={{fontSize:'2rem', fontWeight:'700'}}>La década de los 60</div>

            Pero <b>Fender</b> no iba a parar ahí, en 1960 introduce un nuevo modelo, el <b>Jazz Bass</b>, con un diseño emparentado con la guitarra Jazzmaster de Fender. 
            
            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/jb-1960.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Fender Jazz Bass de 1960.</div>
              </div>
            </div>

            El Jazz Bass incorporaba varias características que lo distinguían del Precision:
            <br/><br/>
            <span>🔹</span> El cuerpo tiene un diseño offset, es decir, lo que sería la “cintura” del bajo está desfasada para hacerlo más ergonómico. El mástil a la altura de la cejuela es más fino lo que lo hace más cómodo para tocar.
            <br/><br/>
            <span>🔹</span> El Jazz Bass dispone de 2 mics individuales ubicados una casi en el centro, un poco más cerca del mástil, y otro cerca del puente, que aporta un sonido brillante, rico medios y agudos.
            <br/><br/>
            Este nuevo modelo de Fender ha impactado considerablemente en el sonido y las posibilidades del instrumento.
            
            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/jb-1960-2.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Fender Jazz Bass de 1960: aquí se pueden ver los potenciómetros de doble perilla con ajuste de volumen y tono individuales para cada micrófono. En 1961 esto fue cambiado por una perilla de tono única e individual, y 2 controles de volumen, tal como es hoy en día.</div>
              </div>
            </div>
          
            El los 60 aparecieron también modelos legendarios como:

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/ricken-4001.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Rickenbacker 4001 (1961). Se destacó por su forma distintiva de "crestas y aletas", mics de doble bobina y sonido particular.</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/eb-3.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Gibson EB-3 (1961). Tiene un cuerpo delgado, al estilo de la Guitarra SG, escala 30.5" y 2 mics humbuckers.</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/gib-thunder.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Gibson Thunderbird (1963). Se caracteriza por su diseño. A diferencia de los otros modelos de Gibson, el Thunderbird tiene una escala de 34", como los Fender.</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/vox-side.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Vox Sidewinder IV Bass (1967). Con un cuerpo de caja hueca (hollow body) este bajo es considerado uno de los primeros en tener un circuito activo que incluye distorsión y boost para graves y agudos.</div>
              </div>
            </div>

            <div className='texto-cursiva' style={{fontSize:'2rem', fontWeight:'700'}}>La década de los 70</div>

            En los 70 llegaron los primeros virtuosos del instrumento, como Stanley Clark y Jaco Pastorius, que cambiaron completamente la forma de tocar el bajo eléctrico. A partir de allí la lista de bajistas notables se expande exponencialmente y el instrumento se empieza a utilizar no sólo en su rol tradicional, como acompañamiento, sino en otros contextos como instrumento melódico y solista.
            <br/><br/>
            Si bien el Precision y el Jazz Bass seguían siendo lo más popular y el estándar en bajos eléctricos, empezaron a aparecer numerosos fabricantes y cada uno con su personalidad. 
            <br/><br/>
            Se empezó a explorar variantes en la construcción, materiales y electrónica. Empezaron a parecer los primeros bajos de 5 y 6 cuerdas y los circuitos activos con preamplificadores y eq 3 y 4 bandas, aunque estos no se popularizaron demasiado hasta los 80.

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/alembic.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Alembic Series I Bass (1971). Alembic es una compañía fundada por 2 técnicos de sonido. Estos bajos son de producción artesanal (a los que se suele referir como bajos boutique), con un alto grado de detalle en la construcción y calidad de los componentes, utilizando maderas exóticas y circuitos electrónicos, siendo uno de los primeros bajos activos.</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/ripper.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Gibson L9-S Ripper (1973). Diseño innovador, cuenta con 2 pastillas de "super humbucker" y una gran perilla que permite seleccionar distintos ruteos de la señal entre los mics (serie/paralelo).</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/musicman-stingray.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Music Man StingRay (1976). Music Man es una empresa fundada por Leo Fender luego de vender Fender a Columbia CBS. El Stingray es un clásico con su mic de gran potencia y preamplificador activo, proporcionando un sonido potente y definido.</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/bb1200.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Yamaha BB1200 (1977). Un diseño clásico similar al precision pero con una construcción neck-through (mástil y cuerpo de una sola pieza).</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/kramer-650b.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Kramer 650B (1978). Kramer era conocido por sus instrumentos con mástil de aluminio. Esta marca se hizo muy popular a fines de los 70 y primera parte de los 80 en bandas de Hard Rock y Metal.</div>
              </div>
            </div>            
            
            <div className='texto-cursiva' style={{fontSize:'2rem', fontWeight:'700'}}>La década de los 80</div>
            
            Se incorporan al mercado y comienzan a popularizarse marcas japonesas como Yamaha, Ibanez y Fernandes que ofrecían bajos de muy buena calidad a un precio muy competitivo.
            <br/><br/>
            Fabricantes pequeños ofrecen bajos “Boutique” hechos a mano, con características a medida, experimentando con tecnologías y materiales nuevos: Ken Smith, Fodera, Sadowsky, Michael Tobias.
            <br/><br/>
            Otras marcas como Kramer, Steinberger y Spector aportaron diseños nuevos y características particulares.

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/ksmith6.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Ken Smith 6 string (1980 - 1984). Luego de varios experimentos, el Bajista Anthony Jackson junto a Ken Smith logran construir el primer Bajo de 6 cuerdas de 34" y separación de cuerdas standard.</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/spector-ns2.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Spector NS-2 (1979). La serie NS fué diseñada por Ned Steinberger (que luego fundaría su propia empresa de instrumentos) se hizo muy popular durante los 80 (y aún lo sigue siendo), con un diseño curvo, ergonómico y construcción neck-thru.</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/steinberger-l2.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Steinberger L2 (1980). Ned Steinberger es considerado uno de los diseñadores más innovadores. Es el creador de los bajos headless (sin cabeza) y el primero en experimentar con materiales como la fibra de carbono y el grafito.</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/ibanez-rb824-claro.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Ibanez Roadstar II RB824 (1983). Uno de los primeros modelos de diseño propio de la marca, antecesor de la popular serie SR que se fabrica al día de hoy.</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/fodera-monarch.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Fodera Monarch (1983). Vinnie Fodera había trabajado con Spector y Ken Smith para luego fundar su propia marca creando uno de los bajos de más calidad y mejor valorados. Este es el modelo nro. 1 de Victor Wooten.</div>
              </div>
            </div>

           
            <div className='texto-cursiva' style={{fontSize:'2rem', fontWeight:'700'}}>Década de los 90 en adelante</div>

            Los 90 se caracterizó por la popularización de los bajos de 5 y 6 cuerdas, los circuitos activos y boutique. Gary Willis inventa la rampa que es muy usada por bajistas de jazz fusión. También se empiezan a ver bajos con traste 0, materiales alternativos y bajos multi-escala.

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/warwick-thumb.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Warwick Thumb (1990). Su diseño ergonómico y moderno, y un sonido con mucho punch y presencia enamoró a muchos Bajistas.</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/zon-hyperbass.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Zon Hyperbass (1990). Zon es conocido por sus diseños vanguardistas. Para el diapasón usan un material llamado "Phenowood", que es madera impregnada con resina fenólica, que les da una dureza extrema y tono muy claro, rico en armónicos. Este modelo en particular fue hecho en colaboración con el bajista Michael Manring para afinaciones alternativas. Posee un diapason de 3 octavas.</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/ibanez-gw.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Ibanez GWB1 (1992 - 1999). En el 92 el bajista Gary Willis agrega una innovación a su modelo SR885, incorporando una "rampa" entre los micrófonos, permitiendo que los dedos se puedan mover con más agilidad al pulsar la cuerda. Posteriormente se crea el GWB1 su modelo signature.</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/fodera-imperial.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Fodera Imperial (1995). En los 90 Fodera cobró una gran popularidad entre los bajistas de jazz, con sus diseños y acabados super sofisticados con maderas exóticas muy vistosas. Este modelo es el Matt Garrison signature, un bajo de 33", afinado EADGC (la 1er cuerda es un Do agudo) y tiene una rampa como la utilizada por Gary Willis.</div>
              </div>
            </div>            

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/modulus-sh.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Modulus Sonic Hammer (1996). Modulus hizo su fama haciendo bajos con mástiles de fibra de Carbono, un material muy liviano y estable, a diferencia de la madera que sufre dilataciones y contracciones. Este modelo es el antecesor del Flea/Funk Bass tan popular durante los 2000.</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/mtd.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >MTD (Michael Tobias Design). Michael Tobias comenzó a hacer bajos en los 70 y luego de vender su primera empresa (Tobias) a Gibson, en 1994 funda MTD. Especializado en construir bajos de alta gama hechos a mano, de 35" con traste 0. Muy popular entre los músicos de Gospel, Soul y Hip Hop.</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/dingwall-voodoo.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Dingwall Voodoo (1993). Digwall es conocido por su sistema "fanned-fret" multi-escala, donde al igual que en un piano, cada cuerda tiene su largo óptimo de vibración. Estos bajos han logrado mucha popularidad entre los bajistas de Metal Progresivo moderno.</div>
              </div>
            </div>

            En los últimos años, desde 2010 en adelante hay una fuerte tendencia a revalorar modelos vintage, resultando en la moda de los relic (instrumentos con desgaste estético) y muchos fabricantes están ofreciendo esta terminación desde fábrica.

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b8/jb-cs-hr.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >Fender Jazz Bass Custom Shop Heavy Relic (2020).</div>
              </div>
            </div>

            Obviamente hay muchos más modelos y marcas que podría mencionar, pero creí conveniente resaltar solo los que considero fueron más importantes y han marcado una tendencia en el mercado, impactado en la música y en la historia del Bajo Eléctrico.
            <br/><br/>
            ¡Quién sabe qué cosa nueva nos deparará el futuro!
            <br/><br/>
            Mientras tanto...
                <br/><br/>
                ¡A seguir grooveando!
                <br/><br/>
                <span className='texto-cursiva' style={{ fontSize: '1.8rem'}}>Tincho </span>
                <span role="img" aria-label="emoji"> 🤗🎸🎶</span>
                <br/><br/>

                <BlogVid embed="https://www.youtube.com/embed/uhJ9mpFPRVs"
                         texto="Parte de este texto está basado en el siguiente video:"
                />

                {/* <br/><br/>                
                <hr/>                
                Referencias y links de interés:
                <br/>
                <a href="https://www.seattletimes.com/seattle-news/on-ebay-the-worlds-first-electric-bass-guitar-forgotten-and-made-in-seattle/" target='_blank' rel='noopener noreferrer'>
                  📘 Artículo sobre el Audiovox Bass Fiddle de Tutmarc
                </a>
                <br/>
                <a href="https://www.historylink.org/file/7479" target='_blank' rel='noopener noreferrer'>
                  📘 Tutmarc y Audiovox (ensayo)
                </a>
                <br/>
                <a href="https://youtu.be/aF6usTMwicM?si=UQC0604bN_4AGMMU" target='_blank' rel='noopener noreferrer'>
                  ▶️ Igor Saavedra con el Tutmarc Bass Fiddle
                </a>
                <br/>
                <a href="https://youtu.be/x3OlqJhMD4M?si=6ZNnjIcqVBUml_bf" target='_blank' rel='noopener noreferrer'>
                  ▶️ Precision Bass de 1951
                </a>
                <br/>
                <a href="https://youtu.be/_VM1KLDwMPY?si=8CpBAKgwYEaQ7g1S" target='_blank' rel='noopener noreferrer'>
                  ▶️ Gibson EB-1
                </a>
                <br/>
                                
                <br/>
                <hr/> */}

                <br/><br/>

                  <ScrollTop />

                  <div className='btn-anterior-proximo centro'>
                    <a href="/blog/que-significa-ser-bajista" className='btn mt-4 centro'>
                      <span className="material-icons">keyboard_arrow_left</span>
                      <span>Anterior: "Que Significa Ser Bajista"</span>
                    </a>

                    <a href="/blog/encontrando-nuestra-voz-como-musicos" className='btn mt-4 centro'>
                      <span>Próximo: "Encontrando Nuestra Voz Como Músicos"</span>
                      <span className="material-icons">keyboard_arrow_right</span>
                    </a>
                  </div>

                  
                <br/>              
            </div>

          </div>
        </div>
    )
  }
}