import React, { Component } from 'react'

import './Intro.css'


export class Intro extends Component {
  render() {
    return (
      <div>
        <div className="espacio-portada"></div>

        {/*<svg id="sec-div-top" width="100%" viewBox="0 0 100 10" preserveAspectRatio="none">
          <path className="sec-div" d="M 0 0 L 100 4 L 100 10 L 0 10 Z" />
        </svg>*/}

        <div className="content" id="in">
          <div className="inner-intro-cont">
            <div className="container">

                <h4 className="titulo2 texto-cursiva">
                  Bienvenido a 
                </h4>
                <h3 className="titulo1 font-marca">
                  TINCHO'S WEB
                </h3>
                <h3 className="titulo1 texto-cursiva">
                  ¡A Groovearla!
                </h3>

            </div>
          </div>

          {/*<svg id="sec-div-bottom" width="100%" viewBox="0 0 100 10" preserveAspectRatio="none">
            <path className="sec-div" d="M 0 6 L 100 10 L 100 0 L 0 0 Z" />
          </svg>*/}
        </div>

      </div>
    )
  }
}
