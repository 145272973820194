import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import { BlogVid } from './BlogVid'
import { ScrollTop } from '../../utils/ScrollTop'

export class B7 extends Component {

  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }


  render() {
    return (
        <div className="content"> 
        <Helmet>
          <title>¿Qué Significa Ser Bajista?</title>
        </Helmet>

          <div className="cl-descrip contenido-blog">
            <h3 className='texto-titulo'>¿Qué Significa Ser Bajista?</h3>

            <div className="media my-4">
            <img src="/assets/images/perfil_nuevo_160px.jpg" className="mr-3" alt="" width="40" height="40"
                 style={{ borderRadius: '100%',  border: '2px solid var(--color-cinco)' }}
            />
            <div className="media-body">
              <h6 className="mt-0 texto-cursiva" style={{ margin: 0, fontSize: '1.6rem', color: 'var(--color-primario)' }}>Tincho</h6>
                <small className="text-muted">29 Noviembre 2021 - lectura de 7,5 min</small>
              </div>
            </div>
            
            <div>

              Somos bajistas y nos gusta el bajo. Tocamos el bajo… pero ¿qué significa esto realmente?
              <br/><br/>
              ¿Qué es una línea de bajo? ¿En qué difiere de una melodía? ¿Qué rol cumplimos típicamente en una banda o grupo musical? ¿Por qué?
              <br/><br/>
              Vamos a responder todas estas preguntas y más. 
              <br/>
              Para esto vamos a remontarnos muchos muchos años atrás, a las primeras civilizaciones de la humanidad. 
              <br/><br/>
              Si bien hay pocos datos de cuándo y cómo comenzó la música, se cree que nació con el lenguaje, con la capacidad de comunicarnos a través de la voz humana y la percusión corporal. La música no era considerada un hecho artístico sino que cumplía una función religiosa y social.
              <br/><br/>
              Se estima que prevalecía la <b>monofonía</b> (o también llamada monodia), es decir una única melodía que podía ser cantada o tocada por una o varias personas al unísono. 
              <br/>
              La melodía podría estar acompañada por percusión o de forma muy básica por otro instrumento.
              <br/><br/>
              Quizás te estés preguntando: ¿y qué es una melodía?
              <br/><br/>
              Podríamos definir una <b>melodía</b> como una “sucesión coherente de sonidos musicales que forman una unidad estructurada con sentido musical”.
              <br/><br/>
              ¡Esto suena muy complicado! Pero te voy a dar un ejemplo muy sencillo, cuando cantás una canción estás cantando una melodía.
              <br/><br/>
              Al aparecer las grandes civilizaciones de occidente, como la Griega o la Romana, se podía acompañar el canto solista con instrumentos como la cítara o el aulos. También existía la música puramente instrumental y la puramente coral.
              <br/><br/>
              Las letras asociadas a las melodías eran poemas que alababan dioses, o que hablaban de las guerras y sus héroes, del amor, pero también existían algunas más festivas, con humor grotesco y que hablaban de los bajos instintos del hombre (algo no muy distinto a lo que pasa en la actualidad).
              <br/><br/>
              Se solía asociar a los instrumentos de cuerda, particularmente la lira, con la virtud del hombre, la verdad y la perfección representado por el dios Apolo, y los instrumentos de viento, como el aulos, la flauta o la siringa, al placer y los excesos representado por Dioniso (o Pan).
              <br/><br/>
              Llegando a la edad media, empezamos a encontrar los primeros intentos de escritura en pentagrama, asignando altura y duración a las notas. De a poco se empieza a dar el desarrollo de la <b>Polifonía</b>, dónde en lugar de tener una única melodía tenemos múltiples voces melódicas, independientes o imitativas entre sí, de importancia similar y ritmos diversos.
              <br/><br/>
              La aparición de la Polifonía está íntimamente ligada al desarrollo de la Armonía y el Contrapunto.
              <br/><br/>
              La <b>Armonía</b> es el área de la música que describe los acordes (osea, cuando tocamos varias notas en simultáneo) y su construcción, así como las progresiones de acordes y los principios de conexión que los rigen.
              <br/><br/>
              El <b>contrapunto</b> implica la creación de líneas musicales que se mueven independientemente unas de otras pero guardan una relación armónica entre ellas.
              <br/><br/>
              En el período del barroco (1600 - 1750 dC) vuelve a tomar importancia la monofonía acompañada, pero un poco más sofisticada, utilizando la técnica del <b>Bajo Continuo</b>. Esta técnica es una simplificación de la polifonía, donde se destaca una melodía principal y un acompañamiento.
              <br/><br/>
              En lugar de tener múltiples melodías de igual importancia, el compositor escribe la melodía principal y la voz del bajo o línea de bajo (la voz más grave, muy importante siendo el soporte de los acordes) y las voces intermedias se simplifican en acordes que el músico improvisa a partir de un cifrado o a partir de la línea de bajo.
              <br/><br/>
              De esta manera se jerarquiza la melodía, distinguiéndose del resto de las voces que pasan a formar parte del acompañamiento.
              <br/><br/>
              En cuanto a la instrumentación, la melodía principal podía ser cantada o tocada por un instrumento melódico como el violín o la flauta; los acordes por un instrumento de teclado (clavicordio, pianoforte, órgano) u otros como el laúd; y la voz de bajo podía ser tocada por el teclado y/o por un instrumento de tesitura grave como el violonchelo, fagot, la viola da gamba o el contrabajo.
              <br/><br/>
              El bajo continuo cobró mucha popularidad y gozó de una gran madurez, influyendo tanto en la música popular de la época como en la de cámara y orquesta.
              <br/><br/>
              Si vamos a la <b>música popular del siglo XX</b> en adelante podemos ver que sigue esencialmente el mismo esquema: melodía y acompañamiento: acordes, bajo y, gracias al aporte de la cultura africana, percusión.
              <br/><br/>
              Típicamente en una banda moderna encontramos la siguiente configuración:
              <br/><br/>
              a) <b>Melodía</b> o voz principal: a cargo del cantante o instrumento melódico (ej. Saxo).
              <br/><br/>
              Luego la sección rítmica (ó acompañamiento):
              <br/><br/>
              b) <b>Acordes:</b> a cargo de un instrumento armónico como la guitarra o el teclado.
              <br/><br/>
              c) <b>Línea de bajo</b>: a cargo del bajo eléctrico, y en algunos casos sintetizador bajo. 
              <br/><br/>
              d) <b>Percusión</b>: a cargo de la batería o instrumentos de percusión.
              <br/><br/>
              Vemos que como Bajistas, la ejecución y también la composición de la Línea de Bajo es nuestra responsabilidad y va a ser el rol más común que adoptaremos en una banda. 
              <br/><br/>
              Podemos decir entonces que una línea de bajo es la melodía más grave y sirve como puente entre la batería y los acordes, generando cohesión rítmica y armónica.
              <br/><br/>
              Ser un buen bajista implica conocer cómo funciona la armonía, pero aún más importante es tener un muy buen sentido del ritmo, ya que como dice Don Victa: 
              <br/><br/>              
              <blockquote className="blockquote"
                            style={{
                              margin: '0',
                              padding: "0 1rem",
                              fontSize: '1.1rem',
                              borderLeft: '2px solid var(--color-cuatro)'
                            }}
                >
                  <div className="mb-0 mt-0">“You can't hold no groove if you aint got pocket”.</div>
                  <div className="blockquote-footer">Victor Wooten, <cite>Bajista Zarpado</cite></div>
                </blockquote>
                <br/>
                Algo así como <b>"no podés sostener un Groove si no tenés Bolsillo"</b>.
                <br/><br/>
                En la jerga musical <b>Groove</b> es una sensación positiva en la interacción entre músicos de la sección rítmica, sonando todo como una unidad. También puede referirse a un ritmo o una línea de bajo.
                <br/><br/>
                <b>Pocket</b> (bolsillo) es la sensibilidad rítmica, la capacidad de mantener buen tempo, el fraseo y que se sienta bien lo que se está tocando.
                <br/><br/>
                Si bien tocar líneas de bajo va a ser la actividad más común como bajistas, en ciertas situaciones también podemos tocar acordes, melodías y solos!.
                <br/><br/>

                Conclusión:
                El Bajo además de tener un sonido increíble, tiene un rol súper importante en una banda y además es muy versátil, ya que se puede tocar de muchas formas distintas y también, dado el caso, puede adoptar otros roles.

                <br/><br/>
                A seguir grooveando!
                <br/><br/>
                <span className='texto-cursiva' style={{ fontSize: '1.4rem'}}>Tincho </span>
                <span role="img" aria-label="emoji">🤗🎸🎶</span>
                <br/><br/>


                <BlogVid embed="https://www.youtube.com/embed/efEYBr6Le4Y"
                         texto="Este texto es una transcripción del siguiente video:"
                />

                <br/>
                  <ScrollTop />

                  <div className='btn-anterior-proximo centro'>
                    <a href="/blog/cosas-que-evitan-tu-crecimiento-como-musico" className='btn mt-4 centro'>
                      <span className="material-icons">keyboard_arrow_left</span>
                      <span>Anterior: "Cosas que Evitan tu Crecimiento como Músico"</span>
                    </a>

                    <a href="/blog/breve-historia-del-bajo-electrico" className='btn mt-4 centro'>
                      <span>Próximo: "Breve Historia del Bajo Eléctrico"</span>
                      <span className="material-icons">keyboard_arrow_right</span>
                    </a>
                  </div>

                  
                <br/>              
            </div>

          </div>
        </div>
    )
  }
}
