import React from "react"


const Error = props => (
  <div className="contenido-texto" style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85vh',
    background: 'var(--background-oscuro)',
  }}>
    <div style={{ textAlign: 'center', color: 'var(--color-secundario)'}}>
      <span role="img" aria-label="emoji" style={{fontSize: '4rem'}}>🪐🛸🌠</span>
      <h4 className="texto-cursiva"style={{color: 'var(--color-secundario)', fontSize: '2.6rem'}}>Página no encontrada.</h4>
      <h4 className="texto-titulo" style={{color: 'var(--color-cinco)'}}>Quizás en otro multiverso...</h4>

      <a className='btn mt-4' href="/">Volver a inicio</a>
    </div>
  </div>
);

export default Error;