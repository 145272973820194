import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import './B1.css'
import { ScrollTop } from '../../utils/ScrollTop'


export class B1 extends Component {

  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  render() {
    return (
      <div className="content blog-n1"> 
      <Helmet>
        <title>La Música y la Práctica</title>
      </Helmet>

        <div className="cl-descrip contenido-blog">
          <h3 className='texto-titulo'>La Música y la Práctica</h3>

          <div className="media my-4">
            <img src="/assets/images/perfil_nuevo_160px.jpg" className="mr-3" alt="" width="40" height="40"
                  style={{ borderRadius: '100%',  border: '2px solid var(--color-cinco)' }}
              />
              <div className="media-body">
                <h6 className="mt-0 texto-cursiva" style={{ margin: 0, fontSize: '1.6rem', color: 'var(--color-primario)' }}>Tincho</h6>
              <small className="text-muted">25 May 2018 - lectura de 3 min</small>
            </div>
          </div>

          <div>
            Quiero reflexionar sobre la importancia de practicar regularmente para mejorar como músico y darte algunos tips para saber cómo organizar la práctica.
            <br/><br/>
            Nadie nace sabiendo tocar un instrumento. Todos pasamos por un momento en la vida donde no sabemos nada del bajo, ni como tocarlo, ni donde están las notas, dónde y cómo poner los dedos, etc. Es sólo mediante la práctica que podemos adquirir las habilidades necesarias para tocar música. Practicando incorporamos la data que recibimos de las clases (el profe debería guiarnos en relación a cómo practicar el material que nos da), libros, internet, hasta de los temas que tocás con tu banda. Seguramente hubo un tiempo donde a ese bajista que tanto admirás le tocó estar en el lugar donde estás vos ahora. Lo único que te separa de él es la experiencia, experiencia que se obtiene con práctica, con HACER.
            <br/><br/>
            Ok, tengo que practicar. Pero, qué? cómo?
            Independientemente de tus objetivos con la música, tanto si te lo tomás como hobby-amateur ó querés ser un bajista profesional, hay dos áreas importantes que debemos abarcar: Técnica y Creatividad. Estas áreas están relacionadas, cuanto más avanzamos más se entrecruzan. Pero para organizar bien nuestro tiempo es necesario separarlas.
            <br/><br/>
            <b>Técnica:</b> es la capacidad de un músico de ejecutar una idea músical. Si podés tocar cosas sin importar el nivel de dificultad, y lo hacés con control, en forma precisa y limpia, significa que tenés buena técnica. Pero la técnica no es sólo una cuestión gimnástica, también abarca el nivel de comprensión del lenguaje musical que consta de tres elementos ritmo, melodía y armonía. Por lo tanto la práctica de escalas y arpegios en distintas subdivisiones usando metrónomo es una buena manera de desarrollar esta área.
            <br/><br/>
            <b>Creatividad:</b> es la aplicación y desarrollo de los recursos técnicos en un contexto musical. Abarca el estudio de estilos y el desarrollo de la intuición y la inventiva. Tocar las líneas de tus bajistas preferidos, componer, improvisar, entender los elementos que caracterizan a los distintos estilos son cosas que te van a ayudar a desarrollar esta área.
            <br/><br/>
            Todo esto se puede abordar de muchas maneras y con distintos niveles de profundidad dependiendo de tus objetivos como músico.
            <br/><br/>
            Algunos tips sobre cómo abordar estas áreas:
            <br/><br/>
            1) Definir, visualizar y comprender de antemano el ejercicio que estás por hacer.
            <br/>
            2) Empezá despacio.
            <br/>
            3) Usá metrónomo.
            <br/>
            4) Grabate, escuchate y juzgate.
            <br/><br/>
            Lo importante es tener paciencia. El crecimiento de un músico no se da de forma lineal, sino de a pequeños saltos. Dar uno de estos saltos nos puede llevar semanas ó meses.
          </div>

            <iframe className="my-4" src="https://www.youtube.com/embed/f2O6mQkFiiw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="ted"></iframe>

          <div>
            Te dejo este interesante video de TED que nos explica como funciona el cerebro cuando practicamos y cómo este proceso es necesario para dominar cualquier actividad. Podés ver el video con subtítulos en español.
            <br/><br/>
            Practicar, y disfrutar de practicar, ser constante y paciente es la clave del éxito.
            <br/><br/>
            Así que, y me incluyo también, qué estamos esperando? a practicar!
            <br/><br/>
            <span className='texto-cursiva' style={{ fontSize: '1.4rem'}}>Tincho </span>
            <span role="img" aria-label="emoji">🤗🎸🎶</span>
            <br/><br/>

            <ScrollTop />
            
            <div className='btn-anterior-proximo centro'>
              <a href="/blog/para-que-sirve-la-teoria-musical" className='btn mt-4 centro'>
                <span className="material-icons">keyboard_arrow_left</span>
                <span>Anterior: "¿Para qué sirve la Teoría Musical"</span>
              </a>

              <a href="/blog/tips-para-ser-un-mejor-bajista" className='btn mt-4 centro'>
                <span>Próximo: "Tips para Ser un Mejor Bajista"</span>
                <span className="material-icons">keyboard_arrow_right</span>
              </a>
            </div>

          </div>
        </div>
      </div>
    )
  }
}
