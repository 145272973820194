export const testimonios = [
  {
    nombre: 'Marcelo',
    img: '👨‍🦲',
    opinion: 'Te elegí primero por la cercanía, después me gustaron las clases y la paciencia que me tenías. Tenés alma de docente.',
  },
  {
    nombre: 'Daniel',
    img: '👨',
    opinion: 'Un excelente profesor, se aprende mucho con sus clases.',
  },  
  {
    nombre: 'Ezequiel',
    img: '🧑',
    opinion: 'Excelente profe Martín, genio!',
  },
  {
    nombre: 'Carlos',
    img: '🧔',
    opinion: 'Un genio la forma sencilla de enseñar, la innovación y dedicación.',
  },
  {
    nombre: 'Nicolás',
    img: '👨‍🦱',
    opinion: 'Muy buen docente. Martín es dedicado y comprometido con su profesión.',
  },
  {
    nombre: 'Sol',
    img: '👩',
    opinion: 'Me gustaron las clases, fueron dinámicas y entretenidas.',
  },
  {
    nombre: 'Ramiro',
    img: '👨',
    opinion: 'Sinceramente disfruto mucho las clases, son un cable a tierra.',
  },
  {
    nombre: 'Leandro',
    img: '👱‍♂️',
    opinion: 'Es un camino fascinante para recorrer y siempre hay bocha de cosas copadas para hacer y aprender.',
  },
  {
    nombre: 'Marcelo',
    img: '👨‍🦰',
    opinion: 'Lo más importante es el método que usas y lo motivador.',
  },
]