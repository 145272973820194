import React, { Component } from "react"
//import { Link } from "react-router-dom"

import './Footer.css'
import { youtube, instagram, facebook, tiktok, spotify } from '../utils/svgs'


export class Footer extends Component {


  render() {
    const date = new Date()

    return (
      <footer>

          <div className="foot-box">

            <div className="f-media items">
              <h5 className="texto-cursiva">Media</h5>
              <a href="/musica">Música</a>
              <a href="/videos">Videos</a>
              <a href="/blog">Blog</a>
              <a href="/partituras">Partituras</a>
              {
              //<Link to="/blog">Blog l</Link>
              //<Link to="/partituras">Partituras l</Link>
              }
            </div>

            <div className="f-clases items">
              <h5 className="texto-cursiva">Clases</h5>

              <a href="/clases/#intro">Información</a>
              <a href="/clases/#bio">Acerca de mí</a>
              <a href="/clases/#contact">Contacto</a>
            </div>

            <div className="f-contact items">
              <h5 className="texto-cursiva">Social</h5>
              <a href="https://www.youtube.com/c/tinchoenelbajo" target="_blank" rel="noopener noreferrer">{youtube} YouTube</a>
              <a href="https://www.instagram.com/tinchoenelbajo" target="_blank" rel="noopener noreferrer">{instagram} Instagram</a>
              <a href="https://www.facebook.com/tinchoenelbajo" target="_blank" rel="noopener noreferrer">{facebook} Facebook</a>
              <a href="https://www.tiktok.com/@tinchoenelbajo" target="_blank" rel="noopener noreferrer">{tiktok} TikTok</a>
              <a href="https://open.spotify.com/intl-es/artist/5r1neyXPnXHHlbAt2rhelM" title="Spotify" target="_blank" rel="noopener noreferrer">{spotify} Spotify</a>
            </div>

            <div className="data-fiscal items">
              <a href="http://qr.afip.gob.ar/?qr=4QUy4z39UzAP2DPjk6lUJQ,," target="_F960AFIPInfo"><img src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0"/></a>
            </div>

          </div>
          <div className="cr">
            <p>© 2008 - {date.getFullYear()} Tincho en el Bajo ®</p>
            <p>tinchoenelbajo.com.ar</p>
            <p className="pt-1">de Buenos Aires con <span role="img" aria-label="heart">💚</span> para el mundo</p>
          </div>
      </footer>
    )
  }
}
