import React, { Component } from 'react'

import './Intro.css'


export class Intro extends Component {
  render() {
    return (
      <div>
        <div className="espacio-portada"></div>

        {/*<svg id="sec-div-top" width="100%" viewBox="0 0 100 10" preserveAspectRatio="none">
          <path className="sec-div" d="M 0 0 L 100 4 L 100 10 L 0 10 Z" />
        </svg>*/}

        <div className="content" id="intro">
          <div className="inner-intro-cont">
            <div className="container">

                <h3 className="titulo1 texto-titulo">
                  Clases de Bajo Eléctrico
                </h3>
                <p className="leyend">
                  ¡Maximizá tu potencial musical y obtené las herramientas para ser un Bajista creativo!
                </p>

            </div>
          </div>

          {/*<svg id="sec-div-bottom" width="100%" viewBox="0 0 100 10" preserveAspectRatio="none">
            <path className="sec-div" d="M 0 6 L 100 10 L 100 0 L 0 0 Z" />
          </svg>*/}
        </div>

      </div>
    )
  }
}
