import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

//import './B0.css'
import { ScrollTop } from '../../utils/ScrollTop'


export class B0 extends Component {

  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  render() {
    return (
      <div className="content"> 
      <Helmet>
        <title>Acerca de mí</title>
      </Helmet>

        <div className="cl-descrip contenido-blog">
          <h3 className='texto-titulo'>Acerca de Mí</h3>

          <div className="media mt-4">
            <img src="/assets/images/perfil_nuevo_160px.jpg" className="mr-3" alt="" width="40" height="40"
                 style={{ borderRadius: '100%',  border: '2px solid var(--color-cinco)' }}
            />
            <div className="media-body">
              <h6 className="mt-0 texto-cursiva" style={{ margin: 0, fontSize: '1.6rem', color: 'var(--color-primario)' }}>Tincho</h6>
              <small className="text-muted">Ene 2019 (actualizado nov 2023) - lectura de 9 min</small>
            </div>
          </div>

          <div className='mt-4'>
            Mi historia con la música comienza en la adolescencia temprana, escuchando los cassettes que había en mi casa: los Beatles, mixes de rock nacional, Michael Jackson, uno de Luther Vandross (años después descubrí que el bajista era Marcus Miller) y alguna que otra cosa mas, no teniamos una gran colección. Escuchaba mucha radio, los top 40 y ese tipo de cosas. Recuerdo mi emoción cuando me regalaron para mi cumpleaños un reproductor de CD, ya que en ese momento no teníamos ninguno. El primer disco que me compré fue Greatest Hits II de Queen, y no paraba de escucharlo! Cada tema me transportaba a un mundo nuevo. Muy de a poco fui armando mi pequeña pero querida colección de discos: había de los Beatles, Queen, Aerosmith, Metallica (el album negro) y algunos más. Me gustaba el rock! <span role="img" aria-label="emoji">🤘</span>
            <br/><br/>
            Mi primo Luciano había tomado algunas lecciones de guitarra pero no le gustó mucho, y se la pedí prestada para ver si podía aprender algo por mi cuenta. No me lo tomaba muy en serio, para mi era un juego, pero aprendí a tocar algunos acordes y riffs.
            <br/><br/>
            El punto de inflexión fue al escuchar Bood Sugar Sex Magic de los Red Hot Chili Peppers. Me llamó mucho la atención las increíbles líneas de bajo de Flea, el legendario bajista de la banda: la creatividad, el sonido, el groove. En esa época también empecé a inclinarme hacia el hip hop y la música funky.
            <br/>
            Otros discos que me marcaron fueron Check Your Head de los Beastie Boys y el disco debut de Rage Against the Machine.
            <br/><br/>
            Me decidí entonces a tocar el bajo, encarar en serio el camino de la música y tomar clases. Comencé estudiando en un conservatorio de barrio en Villa del Parque. Asistía al salir del colegio, y volvía caminando (unos 4 km) para ahorrarme la plata del colectivo y comprarme una hamburguesa <span role="img" aria-label="emoji">😋</span>. Nunca falté a una clase. No recuerdo el nombre del profe, que era guitarrista, pero era todo lo que el estereotipo de rockero era en ese momento, algo así como Slash. Mis primos que también tocaban música me prestaron gentilmente un bajo. Era un bajo destartalado pero para mí era lo máximo, estaba feliz.
            <br/><br/>
            Pronto comencé a tocar con mis amigos del colegio. Recuerdo mi primer concierto que fue en un club de barrio y tocaban varias bandas. Nos pintamos la cara de blanco para parecer calaveras <span role="img" aria-label="emoji">😆</span>. La banda que tocó después que nosotros tenía un bajista que la descosía con el Slap, tocaba tipo metralleta, una nota después de otra. Nunca había escuchado algo así! tenía que aprender a tocar eso. En los 90 no había internet, el acceso a la información era limitada, tenías que toparte con eso cara a cara para saber que existía.
            <br/><br/>
            Entonces me dí cuenta que necesitaba un profe especializado en bajo, que me enseñara también cosas específicas del instrumento, más de nicho. Me costó encontrar referencias, pero a través del profesor del guitarrista con el que yo tocaba me pasaron el teléfono de Rafael Surijón, con el que estudié durante los próximos 3 años. Con Rafa aprendí a leer música, los fundamentos de la técnica, el concepto de groove, a tocar slap, pero lo más importante es que me introdujo a un montón de música que no era fácil de encontrar en ese momento: el jazz y la improvisación.
            <br/><br/>
            Así empezó mi etapa de descubrimiento de la música afroamericana: el funk, el blues, el jazz, jazz-fusión. Rafa me recomendó leer algunas biografías, como la de Miles Davis y Charlie Parker, que no sólo me ayudaron a entender conceptual e históricamente el jazz, sino que además era una fuente para conocer otros músicos. Como no tenía plata para comprar discos constantemente, me hacía una lista de nombres e iba a Tower Records a ver si había discos con esos músicos para escucharlos. Aunque había una pequeña sección de jazz en las disquerías, el acceso a esta música era difícil, tenías que conocer gente que le interese lo mismo, y salvo por mi profe que me prestaba CDs, yo había quedado solo en esta búsqueda ya que a mis amigos músicos estaban en otra sintonía.
            <br/><br/>
            Sabía que me faltaba mucho por aprender. Si bien sabía tocar escalas y arpegios, no entendía bien cómo funcionaba la tonalidad. Ni sabía improvisar como lo hacían mis ídolos Jaco Pastorius, Marcus Miller, Victor Wooten, Miles Davis y tantos otros. Podía improvisar una línea de bajo sobre un acorde o un modo, o simular un solo usando la pentatónica, pero no podía salir de ahí.
            <br/><br/>
            Al finalizar el secundario me inscribí en la Escuela de Música Contemporánea (EMC – Berklee). Aprendí de teoría, composición y arreglos entrené mi oído para reconocer melodías y acordes. Además, conocí a otros músicos que compartían los mismos gustos e intereses e hice grandes amigos. Pero era mucha información de repente y no estaba claro cuál era el proceso y la forma de asimilar todo eso internamente. Había un programa específico para las materias teóricas pero no así para las pocas materias prácticas, por lo tanto, no sabía establecer prioridades y entender específicamente qué ejercicios debería hacer para avanzar.
            Me preguntaba: ¿Cómo traslado estos conceptos a lo que toco? ¿Qué habilidades tengo que desarrollar para ser un bajista profesional? ¿cómo entreno estas habilidades? ¿Qué significa ser un bajista profesional?
            <br/><br/>
            Con el pasar de los años, me dí cuenta que el camino que transitamos para contestar estas preguntas es la respuesta en sí.
            <br/><br/>
            Durante mi último año en la EMC empecé a estudiar contrabajo en el Conservatorio de San Martín con Omar Moreno. Se me ocurrió que si tocaba el contrabajo tendría más chances de conseguir trabajo como bajista. El contrabajo tiene un aspecto más serio, más académico, lleva varios siglos formando parte de orquestas clásicas y populares. Si toco el contrabajo subiría de jerarquía como músico… o no?
            Y aunque parte de mí pensaba de esta manera (errada, claramente), sentía un interés genuino hacia el contrabajo que se tradujo en una pasión por muchos años.
            <br/><br/>
            Estudiando contrabajo perfeccioné mi lectura a primera vista, generé un hábito de práctica que no tenía hasta ese momento y lo más importante de todo: en el conservatorio se enseñaba con un método. Disponía de un programa, con una serie de ejercicios específicos, ordenados progresivamente que tenían como fin alcanzar objetivos concretos. Si querías tocar en una orquesta a nivel profesional tenías que dominar todos los ejercicios de estos libros, había un camino. Y este camino te lleva a un objetivo claro: tocar muy bien tu instrumento. Esa es la base sobre la cual hay que apoyarse para luego encontrar una personalidad musical propia. Tocar bien, dominar tu instrumento, te da contexto y perspectiva para innovar y ser creativo.
            <br/><br/>
            Durante mi paso por la EMC había formado parte de muchos proyectos de distintos géneros: canciones, rock, jazz, fusión, tango. Y así, mi primer trabajo remunerado como músico. Fue con un cuarteto de jazz: trompeta, guitarra, bajo y batería. Nos habían contratado para tocar jazz para un evento particular, un cumpleaños. Fue una experiencia muy linda y recuerdo sentirme muy bien, no necesariamente por el dinero (50 patacones, plena crisis 2002), sino por la satisfacción de sentir que estaba en el rumbo correcto. Pero no es tan fácil vivir de la música, para lograr un ingreso decente debería tener eventos de este estilo en forma periódica, y esto era algo esporádico. ¿Cómo voy a vivir de la música? ¿Cuánto tiempo tendría que transcurrir para que pueda lograr un ingreso estable?
            <br/><br/>
            Así pasaron los años, con ocupaciones y trabajos dentro y fuera de la música. Siempre practicando, tratando de superarme, tocando, sumando proyectos.
            <br/><br/>
            Más adelante estudié contrabajo y jazz con Carlos Álvarez que fue mi maestro durante muchos años, con el que profundicé la técnica de contrabajo en jazz y a aprendí a desarrollar un vocabulario para la improvisación. También tomé clases de ensamble con Sebastián Groshaus, donde entrenamos el lenguaje y la interacción grupal en el jazz.
            <br/><br/>
            De esta manera, cada vez más metido en el mundo del jazz, ingresé en la carrera de Jazz del Conservatorio Manuel de Falla, donde enseñan los referentes más importantes de Argentina dentro de este estilo. Allí aprendí muchas cosas, directa e indirectamente, referidas a la música y a lo personal. Tuve que enfrentarme a todas mis falencias y debilidades, pero aprendí mucho, me hice más fuerte como músico y lo más importante es que gané perspectiva para darme cuenta qué es lo que quería hacer.
            Fui tomando conciencia de que mi camino iba por otro lado, musical y estilísticamente. Al concluir los 3 años de carrera tomé la decisión de dejar el contrabajo y dedicarme exclusivamente al bajo eléctrico y a estudiar la música que realmente me gusta y apasiona, y compartir esa pasión con otros.
            <br/><br/>
            Si bien había tenido la oportunidad de enseñar bajo con anterioridad en situaciones aisladas, comencé formalmente a dictar clases de bajo particulares en el 2007. De a poco fui ganando experiencia como profesor y surgió la posibilidad de dictar clases para un taller de música en un colegio, y luego como suplente en un colegio primario, y así se fueron sumando cosas. De repente me vi no sólo dictando clases de bajo, sino también de guitarra, flauta y música, participando en actos escolares, tratando de que los chicos se porten bien y presten atención.
            <br/><br/>
            En base a mi experiencia en el aprendizaje de la música, creo que existe un vacío metodológico en lo que concierne a la música popular, una dificultad para transmitir la importancia de los fundamentos de la música y el proceso para incorporarlo a nuestro instrumento de forma práctica. Suele haber una desconexión con la psicología del alumno, se presupone cierto entendimiento conceptual que generalmente el alumno no tiene. Por lo tanto, en mi rol de docente, he buscado (y lo sigo haciendo) la forma más eficiente de transmitir lo que he aprendido, diseñando un programa con consignas y ejemplos claros que permitan al alumno incorporar los fundamentos del lenguaje musical, que es indispensable para desarrollar un vocabulario rico y expresarse a través del instrumento.
            <br/><br/>
            A lo largo de todos estos años formé parte de numeros grupos con los que toqué Rock, Pop, Jazz, Fusión, Folclore, algo de Tango y música Clásica, incluso música infantil. 
            Además de enseñar música y ser Bajista, he trabajado haciendo transcripciones a pedido y también en algunos proyectos como arreglador/productor.
            <br/><br/>
            Actualmente me dedico a <a href="/clases">enseñar</a> bajo eléctrico tanto para principiantes como músicos con experiencia y a mi actividad en <a href="https://youtube.com/tinchoenelbajo" target='_blank' rel="noopener noreferrer">YouTube.</a> Además estoy componiendo, arreglando y produciendo <a href="/musica">música propia.</a>
            <br/><br/>
            Siento pasión por la música y la enseñanza, y particularmente por el bajo. Siento la responsabilidad y el compromiso por formar nuevos y buenos músicos, quiero que mis alumnos se superen. Durante el transcurso de todos estos años, condensé mi experiencia como profesor en una síntesis que se expresa en el programa y el método con el que enseño los fundamentos de la música y la técnica en el bajo eléctrico.
            <br/><br/>
            La música tiene el poder de hacernos reir y llorar, de exaltarnos, de elevar nuestro espíritu, de despertar emociones, y el mejor aporte que puedo hacer es fomentarla. ¡A Groovearla!
            <br/><br/>
            <span className='texto-cursiva' style={{ fontSize: '1.4rem'}}>Martín "Tincho" Satorre</span> 
            <br/>
            <span role="img" aria-label="emoji">🤗🎸🎶</span>
            <br/><br/>

            <ScrollTop />


            <div className='btn-anterior-proximo centro'>
              <a href="/blog/****" className='btn mt-4 centro' style={{display: 'none'}}>
                <span className="material-icons">keyboard_arrow_left</span>
                <span>Anterior: "****"</span>
              </a>

              <a href="/blog/el-cuidado-del-oido" className='btn mt-4 centro'>
                <span>Próximo: "El Cuidado del Oído"</span>
                <span className="material-icons">keyboard_arrow_right</span>
              </a>              
            </div>

          </div>
        </div>
      </div>
    )
  }
}
