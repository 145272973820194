import React, { useState } from 'react'
import { Collapse, Alert } from 'react-bootstrap'
import { Spotify } from 'react-spotify-embed'
import { ScrollTop } from '../../utils/ScrollTop'

import './Clases.css'
import { items } from './cl-handle-data'
import { youtube, spotify, apple, deezer, amazon, tidal } from '../../utils/svgs'


export function Clases() {

    const [open, setOpen] = useState(false);

    return (
      <div className="content contenido-texto sencillo">
        <div className="ini-descrip" id="inicio" style={{width: '100%'}}>

          <h1 className='texto-titulo text-center mb-0'>CORRIENDO AL MAR</h1>
          <h4 className='texto-cursiva' style={{rotate: '-2deg', position: 'relative', top: '-1.6rem'}}>por Tincho en el Bajo</h4>

          <div className="container" style={{}}>
            <div className='pb-4'>

             
              <div className='text-lanz pt-1' style={{maxWidth: '560px', margin: '0 auto'}}>

                <div className='spoti desktop mb-2'>
                  {<Spotify link='https://open.spotify.com/album/2WgsA9WcCKLjz26QWsoI8q' />}
                </div>
                <div className='spoti mobile mb-2'>
                  {<Spotify wide link='https://open.spotify.com/album/2WgsA9WcCKLjz26QWsoI8q' />}
                </div>

                {/* video */}
                <div className='nov-vid mb-2 d-none'>
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>


                <div className='pt-3'>
                  Corriendo al Mar es un sencillo compuesto por 2 canciones, un lado A y un lado B al estilo de la vieja escuela, tal como era antes con los discos de pasta o los cassettes que contenian una canción de un lado y otra del otro.
                  <br/><br/>
                  El <it>lado A</it> se titula <b><a href="/corriendoalmar/#corriendo">Corriendo al Mar</a></b>, una canción instrumental muy power, cuyo protagonista es el Bajo Fretless.
                  <br/><br/>
                  El lado B contiene una canción especial llamada <b><a href="/corriendoalmar/#trapdelbajo">Trap del Bajo</a></b> donde rindo homenaje a mi instrumento preferido, el Bajo; rapeo, canto y relato con un poco de humor mi experiencia como Bajista.
                  <br/>
                </div>

                <br/>
                <div className='texto-cursiva text-center mb-1' style={{fontSize:'2rem'}}>¡Escuchalo en las plataformas digitales!</div>

                <div className='plataformas-musica'>
                  <a className='btn boton2 btn-miembros' href="https://open.spotify.com/intl-es/album/2WgsA9WcCKLjz26QWsoI8q" target='_blank' rel='noopener noreferrer'>
                    {spotify} Spotify
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://music.youtube.com/playlist?list=OLAK5uy_m2Cksb-888l-AajZUqprv7AN_ads6ZNec" target='_blank' rel='noopener noreferrer'>
                    {youtube} YouTube
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://music.apple.com/ar/album/corriendo-al-mar-single/1745782785" target='_blank' rel='noopener noreferrer'>
                    {apple} Apple
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://music.amazon.com/albums/B0D3WGLVXS" target='_blank' rel='noopener noreferrer'>
                    {amazon} Amazon
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://www.deezer.com/es/album/585292262" target='_blank' rel='noopener noreferrer'>
                    {deezer} Deezer
                  </a>
                  <a className='btn boton2 btn-miembros' href="https://listen.tidal.com/album/362548222" target='_blank' rel='noopener noreferrer'>
                    {tidal} Tidal
                  </a>

                </div> 
                
                <br/>
                También está disponible en {/* 
                  <a href="https://www.pandora.com/AL:34621268" target='_blank' rel='noopener noreferrer'> Pandora</a>, 
                  <a href="https://www.claromusica.com/embed/track/****" target='_blank' rel='noopener noreferrer'> Claro Música</a>,
                  <a href="https://web.napster.com/track/****" target='_blank' rel='noopener noreferrer'> Napster</a>,
                   
                  y en */} muchas otras plataformas de streaming y descarga de música digital.
                
                <hr/>
                <br/>

                🎵 Ambas canciones compuestas, interpretadas y producidas por Tincho en el Bajo (o sea yo 😝). Todos los derechos reservados.
                  <br/><br/>
                🎙️ Grabado, mezclado y masterizado durante los meses de diciembre 2023 y mayo 2024.
                  <br/><br/>
                🚀 Fecha de lanzamiento: 24 de mayo 2024.
                  <br/><br/>
                ⚙️ Gear: 
                  <br/>
                En Corriendo al Mar utilicé mi bajo Fretless Medina con cuerdas halfrounds.
                  <br/>
                En Trap del Bajo usé mi Fender Jazz Bass Mexicano 2007 con cuerdas roundwounds.                
                <br/><br/>
                Los bajos fueron grabados a través del cabezal Aguilar Tone Hammer 350 (sin pasar por el Pre), por línea directa (DI) a la interfaz de audio Focusrite Scarlett 2i2.
                <br/><br/>
                En el puente de corriendo al mar usé el pedal Boss Bass Chorus CE-2B analógico. 
                <br/><br/>
                Producido en Ableton Live.
                <br/><br/>

                <h1 className='text-center texto-primario texto-cursiva'>
                  <span className='resaltado2' id="corriendo">Corriendo al Mar</span>
                </h1>

                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <img className="" style={{width: '100%'}} src="/assets/img/mar_olas.jpg" />
                </div>

                <br/>

                
                <div>
                  Esta canción representa los años transcurridos entre el 2020 y el 2023. Muchas cosas pasaron, una pandemia, una crisis creciente en el país y situaciones personales complejas que hubo que atender. Encontré refugio en la actividad física, en la calistenia y el running, que me sirvieron como cable a tierra.
                  <br/><br/>
                  Corrí muchísimos kilómetros. Y lo lindo es que cuando corrés entrás en un trance donde te olvidás de todo y solo podés prestarle atención al presente. Todos nuestros mounstruos y miedos van quedando atrás y una energía positiva, un fuego y un impulso de fuerza interior nace.
                  <br/><br/>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img className="mb-3" style={{width: '100%'}} src="/assets/img/tinchoenelmar2.jpeg" />
                    </div>
                  Mientras entrenaba para mi primer maratón de 42km que correría en Mar del Plata, durante esas largas y calurosas jornadas de verano, se me vino la idea de componer una canción que pueda reflejar todo esto y nació Corriendo al Mar. 
                  <br/><br/>
                  Decidí grabar esta canción con mi Bajo Fretless, porque sentí que el sonido de este reflejaba mejor el aroma y el espíritu del mar. Es un Bajo que no he usado mucho desde que lo tengo y merecía ser protagonista de una canción, y una canción muy personal. Además fue hecho por mi amigo Guido y también un regalo de mi Mamá.
                </div>

                <br/>

                {/* AGREGAR FOTO y descrip Bajo GM, JB y BOSS CE-2B*/}
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img className="" style={{width: '100%'}} src="/assets/img/fretless_gm.jpg" />
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img className="" style={{width: '100%'}} src="/assets/img/fretless_gm_atras.jpg" />
                  </div>
                  <br/>
                <h5 className='texto-primario'><b>El Fretless</b></h5>
                <div>
                  Diseñado y construido por Guido Medina, cuerpo de Raiz Maple y Nogal Italiano Flameado, mango de 5 Piezas Wengue, Purpleheart y Maple, tastiera y puente de Ebano, micrófono Bartolini M4CBC, circuito Pasivo Control con volumen y tono, clavijas Gotoh.
                </div>
                  
                <br/>


                <h1 className='text-center texto-primario texto-cursiva'>
                  <span className='resaltado2' id="trapdelbajo">Trap del Bajo</span>
                </h1>
                
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <img className="" style={{width: '100%'}} src="/assets/img/alien_en_el_desierto_web.jpg" />
                </div>

                <br/>

                <h4>Letra</h4>
                <div className='text-center texto-cursiva' style={{fontSize: '1.8rem'}}>
                  Yo toco cuatro cuerdas<br/>
                  A veces tengo cinco<br/>
                  Para seis no me da<br/>
                  Tengo que vender el twingo<br/>
                  Mi mamá me hizo bueno<br/>
                  Me dice sos fachero<br/>
                  Si el bajo no me cuelgo<br/>
                  No me da bola ni el perro<br/>
                  Y esto se lo debo<br/>
                  A Deacon, Flea y McKagan<br/>
                  No me olvido de Bootsy<br/>
                  Jaco, Marcus y Don Victa<br/>
                  No me diga ¿eso es guitarra?<br/>
                  Que me pongo picante<br/>
                  Mira como suena esto<br/>
                  Y te rompo el parlante<br/>
                  <br/>
                  Tirate un groove p'acá<br/>
                  Tirate un groove p'allá<br/>
                  Sentí la music, música<br/>
                  Groovy groovy ya<br/>
                  <br/>
                  Yo no discrimino<br/>
                  Gusta el maple y adivino<br/>
                  Palo rosa, arce, wengue,<br/>
                  Hollowbody o meta chino<br/>
                  Empecé escuchando heavy<br/>
                  Tiré pa el funk y el soul<br/>
                  El blues me da en la vena<br/>
                  El corazón de rock and roll<br/>
                  Ya no toco jazz<br/>
                  Eso es pa los careta<br/>
                  Volví a ser el pibe<br/>
                  Que me metió en esta<br/>
                  Pero esta todo ok<br/>
                  Mientras haya groove está bien<br/>
                  Caminando, slapeando,<br/>
                  Púa, dedos, voy zapando!<br/>
                  <br/>
                  Tirate un groove p'acá<br/>
                  Tirate un groove p'allá<br/>
                  Sentí la music, música<br/>
                  Groovy groovy ya<br/> 
                </div>

                <br/>

                
                <div>
                  La idea de esta canción surgió como parodia, con una letra humorística que relata mis vivencias como bajistas y con las que creo muchos otros bajistas se pueden identificar.
                  <br/><br/>
                  Es un homenaje a nuestro instrumento tan querido, y a la música, que me han acompañado durante tantos años.
                </div>

                <br/>


                ¡A Groovearla! 💚👽🎸🎶
                <br/><br/>
                <span className='texto-cursiva' style={{fontSize: '3rem'}}>Tincho</span>

                <hr/>

                <br/><br/>

                <ScrollTop />

              </div>
              
            </div>
          </div>
          
        </div>
      </div>
    )

}
