import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

// Components
import { VidList } from './Components/VidList'

import './Video.css'


export function Video() {
  // scroll arriba
  useEffect(() => {
    window.scroll({
      top: 0
    })
  }, [])

  return (
    <div>      
      <Helmet>
        <title>Videos | Tincho en el Bajo</title>
      </Helmet>

      <main>

        <VidList />

      </main>
    </div>
  )
}
