import React, { Component, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

import './Contacto.css'

import { Form } from './Form'


export class Contacto extends Component {

  openWin() {
    const url = 'https://wa.me/',
          newWin = (window.open(url, '', "width=500,height=300,left=200,resizable=yes,scrollbars=yes,status=yes"));
    if (newWin) newWin.focus();
  }

  render() {
    return (
      <div className="content" id="contact">
        <div className="container cl-descrip">
          <h3 className='texto-titulo'>CONTACTO</h3>

          <Form />

          <div className="cont-data">

            <div className="data-item" style={{ display: 'none' }}>
              <span>Celular</span><br/>
              <span className="desktop-tel"></span>

              <div className="celular">
                <button className="wapp" onClick={this.openWin}>
                  <i className="fab fa-whatsapp-square"></i>
                  <span className="mobile-tel"></span>
                </button>
              </div>
            </div>

            <div className="data-item p-0 mb-4">
              <p className="comment m-0 mt-3"><span role="img" aria-label="emoji" style={{ fontSize: '1.6rem' }}>📧</span> Si preferís, también podés contactarme vía email a:
                <a className="mail" style={{ fontSize: '1rem' }} href="mailto:hola@tinchoenelbajo.com.ar?subject=Consulta%20Clases%20de%20Bajo">
                  <b> hola@tinchoenelbajo.com.ar</b>
                </a>
              </p>
              {/* <span>E-mail</span><br/> */}
            </div>

            <div className="data-item dir"  style={{ display: 'none' }}>
              <span>Dirección</span><br/>
              <a href="/" target="_blank" rel="noopener noreferrer">
                , Tribunales, CABA<br/>
              </a>
              <a href="/" target="_blank" rel="noopener noreferrer">
                , Villa Devoto, CABA
              </a>
            </div>
          </div>

          <EnviarMensaje />
        </div>
      </div>
    )
  }
}


function EnviarMensaje() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button className="mensaje btn" onClick={handleShow}>
        <span className="material-icons" style={{fontSize: '2.4rem'}}>email</span>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className='modal-fondo'>
          <Modal.Title className='texto-cursiva'>Escribime un mensaje!</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form />

        </Modal.Body>
      </Modal>
    </>
  );
}
