import React, { useState, useEffect } from "react"
import { Route, Link } from "react-router-dom"
import { Helmet } from 'react-helmet'

import './Blog.css'
import { B0 } from './Components/B0'
import { B1 } from './Components/B1'
import { B2 } from './Components/B2'
import { B3 } from './Components/B3'
import { B4 } from './Components/B4'
import { B5b } from './Components/B5b'
import { B5 } from './Components/B5'
import { B6 } from './Components/B6'
import { B7 } from './Components/B7'
import { B8 } from './Components/B8'
import { B9 } from './Components/B9'
import { B10 } from './Components/B10'

export function Blog({ match }) {
  // scroll arriba
  useEffect(() => {
    window.scroll({
      top: 0,
    })
  }, [])


  // hooks
  const [blogList, setBlogList] = useState('closed'),
        [filter, setFilter] = useState('');

  function toggleList() {
    filter === '' ? setFilter('filter') : setFilter('');
    blogList === 'closed' ? setBlogList('open') : setBlogList('closed');
    return;
  }

  return (<>  
    <Helmet>
      <title>Blog | Tincho en el Bajo</title>
    </Helmet>

    <div id="seccion-blog" className="blog-sec">

      <div onClick={toggleList} className={filter}></div>

      <div className="row contenido-texto">
        <div className={"col col-sm-2 a " + blogList}>

          <button onClick={toggleList} className="toggleListButton" title="Ver más publicaciones">
            <span className="material-icons" style={{fontSize: '3.6rem'}}>more</span>
          </button>

          <ul>
            <li><h3 className="texto-titulo">Blog</h3></li>
            <hr/>
            <li>
              <Link to={`${match.url}/que-necesito-para-empezar-a-tocar-el-bajo`} onClick={toggleList}className="btn"><span role="img" aria-label="emoji">⚡ </span>¿Qué Necesito para Empezar a Tocar el Bajo?</Link>
            </li>
            <li>
              <Link to={`${match.url}/encontrando-nuestra-voz-como-musicos`} onClick={toggleList}className="btn"><span role="img" aria-label="emoji">👨‍🚀 </span>Encontrando Nuestra Voz Como Músicos</Link>
            </li>
            <li>
              <Link to={`${match.url}/breve-historia-del-bajo-electrico`} onClick={toggleList}className="btn"><span role="img" aria-label="emoji">📜 </span>Breve Historia del Bajo Eléctrico</Link>
            </li>
            <li>
              <Link to={`${match.url}/que-significa-ser-bajista`} onClick={toggleList}className="btn"><span role="img" aria-label="emoji">🎸 </span>¿Qué Significa Ser Bajista?</Link>
            </li>
            <li>
              <Link to={`${match.url}/cosas-que-evitan-tu-crecimiento-como-musico`} onClick={toggleList} className="btn"><span role="img" aria-label="emoji">📈 </span>Cosas que Evitan Tu Crecimiento como Músico</Link>
            </li>
            <li>
              <Link to={`${match.url}/que-significa-estudiar-musica`} onClick={toggleList} className="btn"><span role="img" aria-label="emoji">📚 </span>¿Qué significa estudiar música?</Link>
            </li>
            <li>
              <Link to={`${match.url}/tips-para-ser-un-mejor-bajista`} onClick={toggleList} className="btn"><span role="img" aria-label="emoji">💪 </span>Tips para Ser un Mejor Bajista</Link>
            </li>
            <li>
              <Link to={`${match.url}/la-musica-y-la-practica`} onClick={toggleList} className="btn"><span role="img" aria-label="emoji">🔁 </span>La Música y la Práctica</Link>
            </li>
            <li>
              <Link to={`${match.url}/para-que-sirve-la-teoria-musical`} onClick={toggleList} className="btn"><span role="img" aria-label="emoji">🎼 </span>¿Para qué sirve la Teoría Musical?</Link>
            </li>
            <li>
              <Link to={`${match.url}/como-elegir-un-bajo`} onClick={toggleList} className="btn"><span role="img" aria-label="emoji">✔️ </span>¿Cómo Elegir un Bajo?</Link>
            </li>
            <li>
              <Link to={`${match.url}/el-cuidado-del-oido`} onClick={toggleList} className="btn"><span role="img" aria-label="emoji">👂 </span>El Cuidado del Oído</Link>
            </li>
            <li>
              <Link to={`${match.url}/acerca-de-mi`} onClick={toggleList} className="btn"><span role="img" aria-label="emoji">🚶‍♂️ </span>Acerca de Mí</Link>
            </li>
          </ul>
        </div>

        <div className="col col-sm b">
          <Route path={`${match.path}/:topicId`} component={Topic} />

          {/* COMPONENTE INICIAL */}
          <Route exact path={match.path} component={B10}/>
        </div>
      </div>

    </div>
  </>);
}

function Topic({ match }) {
  let { topicId } = match.params

  return (
    <div>
      {topicId === 'que-necesito-para-empezar-a-tocar-el-bajo' && <B10 />}
      {topicId === 'encontrando-nuestra-voz-como-musicos' && <B9 />}
      {topicId === 'breve-historia-del-bajo-electrico' && <B8 />}
      {topicId === 'que-significa-ser-bajista' && <B7 />}
      {topicId === 'cosas-que-evitan-tu-crecimiento-como-musico' && <B6 />}
      {topicId === 'que-significa-estudiar-musica' && <B4 />}
      {topicId === 'tips-para-ser-un-mejor-bajista' && <B5 />}
      {topicId === 'la-musica-y-la-practica' && <B1 />}
      {topicId === 'para-que-sirve-la-teoria-musical' && <B5b />}
      {topicId === 'como-elegir-un-bajo' && <B3 />}
      {topicId === 'el-cuidado-del-oido' && <B2 />}
      {topicId === 'acerca-de-mi' && <B0 />}
    </div>
  );
}
