import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
// Components
import { Intro } from './Components/Intro'
import { Clases } from './Components/Clases'
import { Acerca } from './Components/Acerca'
import { Contacto } from './Components/Contacto'

import './lovdisyit-style.css'


export function Lovdisyit() {
  // scroll arriba
  useEffect(() => {
    window.scroll({
      top: 0
    })

  }, [])


  return (
    <div className='lovdisyit-main'>
      <Helmet>
        <title>Tincho en el Bajo</title>
      </Helmet>
      <main>

        <div className="back1" id="bg1">
          <div className="filter"></div>
          <img src="/assets/img/lovdisyit_500px.jpg" />
        </div>

          <Clases />

      </main>
    </div>
  )
}
