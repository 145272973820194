// ENV
const PROD = true // cambiar a true en PRODUCCION solo para npm run build

export let  _SERVER,
            _SID = 'ScdbmcA=',
            _AUTH = '/auth.php'

PROD ? _SERVER = '/server_php' : _SERVER = 'http://tincho-dev-server-php'

// Routers browser
export async function req() {
  try {
    const res = await fetch(_SERVER+'/path.php')

    if (res.ok) {
      return res.json()
    } else {
      return { path: '/' }
    }
  }
  catch (err) {
    console.log(err)
  }
}


// Cookie Parse
export function getCookie(cname) {
  //let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  //console.log(decodedCookie)
  let ca = decodedCookie.split(';');
  //console.log(ca)
  for (let x of ca) {
    //console.log(x.includes(cname))
    if (x.includes(cname)) {
      if (x.startsWith(' ')) x = x.slice(1)
      //console.log('slice ' + x)
      return x.slice(cname.length)
    }
  }
}
