import React, { useState } from 'react'
import { Collapse, Alert } from 'react-bootstrap'

import './Clases.css'
import { items } from './cl-handle-data'


export function Clases() {

    const [open, setOpen] = useState(false);

    return (
      <div className="content contenido-texto">

        <div className="cl-descrip" id="cla">

          <h3 className="texto-titulo">Online</h3>
          <p>
            Clases <b>online</b> por videocall. Individuales y personalizadas.<br/>
            ¡Aprendé Bajo desde la comodidad de tu casa!
          </p>
          {/* <Row>
            <Col>
              <div>
                <div className="circle-icon">
                  <i className="fas fa-home"></i>
                  <p><small>Presenciales</small></p>
                </div>
              </div>
            </Col>
            <Col>
              <div>
                <div className="circle-icon">
                  <i className="fas fa-desktop"></i>
                  <p><small>Online</small></p>
                </div>
              </div>
            </Col>
          </Row> */}
            <div className="alerta-horario-clases">
              <Alert variant="success">
                <a href="/#contact">
                  <b>Consultar horarios disponibles</b> (Horario Argentina: GMT-3).
                </a>
              </Alert>
            </div>
          <br/>

          <h3 className="texto-titulo">Objetivos</h3>
          <p>Las clases te brindarán las <b>herramientas</b> y el <b>camino</b> necesario
            para <b>crecer como músico</b>, desarrollando tu vocabulario, adquiriendo
            un entendimiento amplio del lenguaje musical.
          </p>
          <p>Vamos a perfeccionar tus <b>habilidades</b> como instrumentista abordando
            un programa donde trabajaremos <b>en forma progresiva</b>: técnica, teoría,
             grooves, estilos, transcripciones, creación de líneas de bajo,
             improvisación, solos, lectura y escritura, entrenamiento auditivo y más. <br/>
            Clases tanto para principiantes como para bajistas en actividad.
          </p>

          <div className="center">
            <div
              className="btn"
              onClick={() => setOpen(!open)}
              aria-controls="collapse-text"
              aria-expanded={open}
            >
              <span className="material-icons" style={{fontSize: '6rem'}}>keyboard_arrow_down</span>
            </div>
          </div>
        </div>

        <div>
          <Collapse in={open}>
            <div id="collapse-text">
              <div className="container-info">
                <div className="cl-info" id="cl-handle-data">
                  {items.map((item, index)=> {
                    return <div className="cl-descrip-more" key={index}>
                              <p className="title texto-titulo">{item.title}</p>
                              <p>{item.descrip}</p>
                            </div>
                  })}
                </div>
              </div>

            </div>
          </Collapse>
        </div>

      </div>
    )

}
