import React from 'react'
import { Alert } from 'react-bootstrap'

import './BlogVidStyle.css'

export function BlogVid(props) {
  return (
    <>
      <div className="mt-0" style={{ color: 'var(--background-oscuro)' }}><b>{props.texto}</b></div>
      <Alert variant="info" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '' }} className='blog-alert'>
        <div className="playBlog">
          <iframe src={props.embed} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Video"></iframe>
        </div>
      </Alert>
    </>
  )
}
