import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import './B2.css'
import { ScrollTop } from '../../utils/ScrollTop'


export class B2 extends Component {

  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }


  render() {
    return (
      <div className="content blog-n2"> 
      <Helmet>
        <title>El Cuidado del Oído</title>
      </Helmet>

        <div className="cl-descrip contenido-blog">
          <h3 className='texto-titulo'>El Cuidado del Oído</h3>

          <div className="media my-4">
            <img src="/assets/images/perfil_nuevo_160px.jpg" className="mr-3" alt="" width="40" height="40"
                  style={{ borderRadius: '100%',  border: '2px solid var(--color-cinco)' }}
              />
              <div className="media-body">
                <h6 className="mt-0 texto-cursiva" style={{ margin: 0, fontSize: '1.6rem', color: 'var(--color-primario)' }}>Tincho</h6>
              <small className="text-muted">Jul 2017 - lectura de 4 min</small>
            </div>
          </div>

            <div>
              El oído es el órgano que nos permite registrar los sonidos y es una herramienta de comunicación fundamental para cualquier persona, sea músico o no. En la época que vivimos estamos expuestos a una cantidad de situaciones que pueden dañar permanentemente nuestra audición, y en lo que sigue me gustaría que tomemos conciencia y brindar información sobre como protegernos.
              <br/><br/>
              La exposición frecuente a ruido alto o moderadamente alto durante un período de tiempo prolongado, puede dañar el tejido blando del oído interno. Las células y los nervios del oído interno se destruyen con la exposición continua o repetitiva a ruidos altos. Si un número suficiente de células y nervios se destruyen hay daño permanente de la audición.
              <br/><br/>
              Que el ruido te haga daño o no, depende del volumen, del tono y de la cantidad de tiempo que estás expuesto al ruido. El volumen del sonido medido en decibeles (dB) y la duración de la exposición se relacionan. Mientras más alto sea el sonido, menor será el tiempo de exposición antes de que ocurra daño. Por ejemplo, ocho horas de exposición a un ruido de 85 dB diarios, con el tiempo, puede comenzar a dañar los oídos de una persona. Usar herramienta pesada produce ruido a un volumen aproximado de 100 dB, escuchar con audífonos a alto volumen (aproximadamente a 110 dB), asistir a un concierto de música Rock (aproximadamente a 120 dB) o escuchar un disparo (entre 140 y 170 dB) puede dañarle la audición a algunas personas con que esto ocurra solamente unas cuantas veces.
              <br/><br/>
              Una razón por la cual la gente no nota el daño que el ruido produce es porque la exposición excesiva a éste causa pocos síntomas. La pérdida de la audición rara vez es dolorosa. Los síntomas usualmente son sensaciones vagas de presión o de pesadez en los oídos, habla que parece que proviniera de una boca tapada o que se escucha lejos y un zumbido en los oídos (tinnitus) que notás cuando estás en lugares silenciosos. Estos síntomas pueden desaparecer en minutos, horas o días o después de que la exposición al ruido termina.
              <br/><br/>
              La gente asume que si los síntomas desaparecen los oídos van a volver a escuchar normalmente. Esto realmente no es cierto. Incluso si no existen más síntomas, algunas de las células en el oído interno pudieron haber sido destruidas por el ruido. Tu audición vuelve a ser normal si te quedan suficientes células sanas en tu oído interno. Pero vas a desarrollar una pérdida de la audición permanente si la exposición al ruido se repite y se destruyen más células.
              <br/><br/>

              Las siguientes señales deben ser una alarma de que el ruido a tu alrededor es demasiado alto:
              <br/>
            </div>

            <ul>
              <li>Si tenés que gritar para ser escuchado por encima del ruido.</li>
              <li>Si no podés entender a alguien que te está hablando a menos de 20 cm.</li>
              <li>Si una persona que está parada cerca tuyo puede oír el sonido que proviene de tus auriculares mientras los tenés puestos.</li>
            </ul>

            <div>
              <br/>
              Tips para prevenir la pérdida de la audición:
              <br/>
            </div>

              <ul>
                <li>Minimizá tu exposición al ruido.</li>
                <li>Desarrollá el hábito de usar tapones en los oídos cuando sepas que vas a estar expuesto a ruido durante períodos de tiempo largo. Estos tapones, que pueden eliminar hasta 25 dB de sonido pueden representar la diferencia entre un nivel de ruido peligroso y un nivel de ruido sano.</li>
                <li>Evitá el uso de audífonos por largos ratos.</li>
                <li>Usá materiales que absorban el ruido para disminuir la cantidad de ruido en tu casa o en el trabajo.</li>
                <li>No utilices varias máquinas ruidosas al mismo tiempo (para las bandas con dos guitarristas…). <br/>
                    Tratá de mantener los videojuegos, el estéreo y los audífonos con el volumen bajo.</li>
                <li>No trates de tapar el ruido indeseable con otros sonidos. Por ejemplo, no le subas el volumen al estéreo de tu auto o a los auriculares para evitar oír el ruido del tráfico.</li>
                <li>Hacete un examen de audición (Audiometría).</li>
              </ul>

            <div>
              En conclusión, nada de lo que hagamos tiene sentido si no cuidamos nuestra herramienta más preciada: el oido. Cuidemonos y cuidemos a los demás, ya sea en los ensayos, conciertos y/o cualquier situación que seamos responsables de música a alto volumen.
              <br/><br/>
              <span className='texto-cursiva' style={{ fontSize: '1.4rem'}}>Tincho </span>
              <span role="img" aria-label="emoji">🤗🎸🎶</span>
              <br/><br/>

              <ScrollTop />

              <div className='btn-anterior-proximo centro'>
                <a href="/blog/mi-historia" className='btn mt-4 centro'>
                  <span className="material-icons">keyboard_arrow_left</span>
                  <span>Anterior: "Mi Historia"</span>
                </a>

                <a href="/blog/como-elegir-un-bajo" className='btn mt-4 centro'>
                  <span>Próximo: "Cómo Elegir un Bajo"</span>
                  <span className="material-icons">keyboard_arrow_right</span>
                </a>
              </div>

            </div>
          </div>
      </div>
    )
  }
}
