import React, { Component, useState } from 'react'
import { Modal } from 'react-bootstrap'

import './VidList.css'

import { ScrollTop } from '../../utils/ScrollTop'

// Traer lista de videos
import vidListDb from '../../_json_data/vidListDB' // solo dev json


export class VidList extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {}


  render() {
    const vidList = vidListDb

    return (
      <div>

        <div className="content media" id="vid-pad">
          <div className="cl-descrip">

            <div className="container vid-container">
              <h3 className='texto-titulo'>VIDEOS</h3>

              <p><b>Lecciones:</b> conceptos, técnica, interpretación, estilos y más.</p>
              <div className="videos">
                {vidList.map((item, index) => {
                  if (item.categoria === 'leccion') {
                    return <div key={index} className="v">
                      <ModalVid item={item} />
                    </div>
                  }
                  return null
                })}
              </div>


              {/* <p><b>Tutoriales</b>: aprendé a tocar las mejores líneas.</p>
              <div className="videos">
                {vidList.map((item, index) => {
                  if (item.categoria === 'tutorial') {
                    return <div key={index} className="v">
                      <ModalVid item={item} />
                    </div>
                  }
                  return null
                })}
              </div> */}


              <p>Interpretando <b>covers</b> y <b>transcripciones</b> de líneas de bajo y/o solos</p>
              <div className="videos">
                {vidList.map((item, index) => {
                  if (item.categoria === 'cover') {
                    return <div key={index} className="v">
                      <ModalVid item={item} />
                    </div>
                  }
                  return null
                })}
              </div>

              <p>Visitá mi canal de <a href="https://www.youtube.com/c/tinchoenelbajo" rel="noopener noreferrer" target="_blank">YouTube <i className="fab fa-youtube"></i></a></p>
              <ScrollTop />
            </div>

          </div>
        </div>
      </div>
    )
  }
}


function ModalVid(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { item } = props

  return (
    <>
        <div className="" onClick={handleShow}>
          <img src={"/assets/vids/"+item.img} alt=""/>
          <h2>{item.tema}</h2>
          <h4>{item.artista}</h4>
        </div>

      <Modal show={show} onHide={handleClose}>

          <div className="vid-rep" onClick={handleClose}>
            <h1 id="cancel">X</h1>
            <div className="play">
              { item.tipo === 'youtube' &&
                <iframe src={item.embed} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen title="Video"></iframe>
              }

              { item.tipo === 'directo' &&
                <div>
                </div>
              }
            </div>
          </div>

      </Modal>
    </>
  );
}
