import React from 'react'


const arribaEst = {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems:'center',

    margin: '0 auto',
    padding: '.5rem 2rem',
    /* border: 'none',
      borderRadius: '2rem',
      backgroundColor: 'hsl(200, 40%, 50%)',
      color: 'white' */
    fontWeight: '700',
  }
  /* iEst = {
    fontSize: '1.2rem',
    marginLeft: '.6rem'
  } */


export function ScrollTop() {

  function arriba() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
    return
  }

  return (
    <div>
      <button className="btn terciario" style={arribaEst} onClick={arriba}>
        <span className="material-icons mr-2">arrow_upward</span>
        Subir
      </button>
    </div>
  )
}
