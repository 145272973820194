export const items = [
  {
    icon: './img/corchea.svg',
    title: 'Técnica',
    descrip: 'Postura, independencia, velocidad, sincronización. Dedos, slap, mute, armónicos, acordes y más.'
  },
  {
    icon: './img/corchea.svg',
    title: 'Teoría',
    descrip: 'Armonía funcional, estudio de la tonalidad y su aplicación.'
  },
  {
    icon: './img/corchea.svg',
    title: 'Groove',
    descrip: 'Desarrollo del vocabulario rítmico.'
  },
  {
    icon: './img/corchea.svg',
    title: 'Notación',
    descrip: 'Lectura y escritura en pentagrama.'
  },
  {
    icon: './img/corchea.svg',
    title: 'Variedad Estilística',
    descrip: 'Estudio de distintos estilos y géneros musicales.'
  },
  { icon: './img/corchea.svg',
    title: 'Vocabulario',
    descrip: 'Transcripciones y estudio de material de bandas y artistas.'
  },
  {
    icon: './img/corchea.svg',
    title: 'Improvisación',
    descrip: 'Improvisación de líneas de bajo y solos, "walking" y lenguaje "jazzístico".'
  },
  {
    icon: './img/corchea.svg',
    title: 'Composición y Arreglos',
    descrip: 'Recursos para componer nuestras propias creaciones, modificar o desarrollar ideas.'
  },
  {
    icon: './img/corchea.svg',
    title: 'Entrenamiento Auditivo',
    descrip: 'Aprender a reconocer melodías y acordes con y sin el instrumento.'
  },
  {
    icon: './img/corchea.svg',
    title: 'Sonido',
    descrip: 'Desarrolar un buen audio con o sin el uso de efectos. Entender como funciona un Pre, Eq, compresores, delay, y otros tipos de efectos.'
  },
  {
    icon: './img/corchea.svg',
    title: 'Mantenimiento',
    descrip: 'Principios básicos para que vos mismo puedas hacer el seteo y calibración de tu bajo.'
  },
  {
    icon: './img/corchea.svg',
    title: 'Práctica',
    descrip: 'Cómo hacer eficiente nuestro tiempo de práctica y desarrollar el hábito necesario para obtener mejores resultados.'
  }
]
