import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

//import './B4.css'
//import { BlogVid } from './BlogVid'
import { ScrollTop } from '../../utils/ScrollTop'


export class B5b extends Component {

  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }
  

  render() {
    return (
        <div className="content"> 
        <Helmet>
          <title>¿Para qué sirve la Teoría Musical?</title>
        </Helmet>

          <div className="cl-descrip contenido-blog">
            <h3 className='texto-titulo'>¿Para qué sirve la Teoría Musical?</h3>

            <div className="media my-4">
              <img src="/assets/images/perfil_nuevo_160px.jpg" className="mr-3" alt="" width="40" height="40"
                  style={{ borderRadius: '100%',  border: '2px solid var(--color-cinco)' }}
              />
              <div className="media-body">
                <h6 className="mt-0 texto-cursiva" style={{ margin: 0, fontSize: '1.6rem', color: 'var(--color-primario)' }}>Tincho</h6>
                  <small className="text-muted">16 Feb 2018 - lectura de 4 min</small>
              </div>
            </div>

            <div>
              Existe cierta creencia de que la <i>Teoría Musical</i> es un conjunto de reglas y fórmulas que uno aplica mecánicamente, despojando a la música de toda personalidad y emotividad, y que termina estructurándonos y privándonos de nuestro verdadero ser creativo.
              <br/><br/>
              Esto realmente no es así y está basado en un absoluto prejuicio. Podemos ser creativos con poco, pero no por saber poco somos más creativos.
              <br/><br/>
              La <i>Teoría Musical</i> se encarga de <b>describir</b> los sonidos, definir y dar forma a
              <b> conceptos musicales</b>. Sirve para ayudarnos a organizar los ideas y comprender en profundidad cómo funciona el lenguaje de la música.
              <br/><br/>
              No estamos obligados a aplicar ninguna regla, simplemente nos ayuda a entender cómo piensan, desarrollan y organizan sus ideas otros músicos.
              <br/><br/>
              Tal como un pintor usa las formas geométricas, las proporciones, la luz y la paleta de colores para plasmar una imagen en un lienzo y expresar una idea ó contar una historia, sabemos que los sonidos, que llamamos notas, pueden ser agrupadas de tal manera que se perciben “formas” y “colores” y que a su vez crean un paisaje sonoro.
              <br/><br/>
              En mundo visual vemos círculos, cuadrados, rojos, azules, luces y sombras; y de la misma manera en música tenemos intervalos, escalas, acordes y cadencias.
              <br/><br/>
              Un pintor entiende la diferencia entre un círculo y un cuadrado, el rojo y el azul. Entonces, ¿un músico no debería entender la diferencia entre un intervalo mayor de uno menor, entre una cadencia dominante y una subdominante?
              <br/><br/>
              Elegir no aprender los fundamentos de la música y pretender ser un músico bueno y creativo es como querer ser un gran escritor siendo analfabeto.
              <br/><br/>
              Las ideas que salen son proporcionales a las que entran. Si nos nutrimos con ideas, mantenemos viva la curiosidad y las ganas de aprender vamos a estar alimentando nuestro vocabulario musical y eventualmente eso se va traducir en ideas de mejor calidad.
              <br/><br/>
              La teoría ayuda a darnos un contexto, una referencia en la cual apoyarnos para crecer como músicos. Esto acelera el aprendizaje que de otra manera sería más lento ya que tendríamos que pasar por el proceso de prueba y error una y otra vez, y aun así, nosotros solos no podríamos abarcar todas las ideas que durante cientos de años infinidad de músicos fueron desarrollando.
              <br/><br/>
              Además la teoría nos da perspectiva, nos ayuda a tener mapa musical amplio y a identificar patrones estilísticos dentro de distintos contextos históricos, que por supuesto aplican también a la música moderna.
              <br/><br/>
              Por más original que se crea, no hay nada que toque un músico que no sabe de teoría, que esté fuera de la teoría.
              <br/><br/>
              La música es un lenguaje, y los músicos aprendemos repitiendo lo que escuchamos, asimilamos esa información y luego innovamos. La teoría nos da un contexto, nos ayuda a pensar y organizar la información en la cabeza y en el oído.
              <br/><br/>
              No conozco músico que lamente haber estudiado teoría. Por otra lado, los que no la conocen generalmente sienten muchas limitaciones.
              <br/><br/>
              Pero tampoco se puede ser músico sólo con la teoría. Hay que incorporar estos elementos y
              recursos con la experiencia, la práctica.
              <br/><br/>
              Tener información no es lo mismo que tener conocimiento de un tema. La información en sí
              misma no es nada si no la procesamos y usamos con naturalidad. Una vez que logramos incorporar y asimilar esa información se convierte en conocimiento. Esto nos permite conectar y desarrollar ideas, hacer asociaciones de forma original y creativa.
              <br/><br/>
              Y esto en música se logra practicando, dedicando tiempo a la comprensión del lenguaje musical a través de nuestro intrumento, el bajo.
              <br/><br/>
              A mi parecer el área más importante y fundamental de la teoría es la <i>Armonía Funcional</i>, que describe cómo funciona la tonalidad a través de agrupar u organizar los sonidos en estructuras llamadas acordes y las relaciones de consonancia y disonancia que se establece entre ellos.
              <br/><br/>
              A seguir grooveando!
              <br/><br/>
              <span className='texto-cursiva' style={{ fontSize: '1.4rem'}}>Tincho </span>
              <span role="img" aria-label="emoji">🤗🎸🎶</span>
              <br/><br/>

              <ScrollTop />

              <div className='btn-anterior-proximo centro'>
                <a href="/blog/como-elegir-un-bajo" className='btn mt-4 centro'>
                  <span className="material-icons">keyboard_arrow_left</span>
                  <span>Anterior: "Cómo Elegir un Bajo"</span>
                </a>

                <a href="/blog/la-musica-y-la-practica" className='btn mt-4 centro'>
                  <span>Próximo: "La Música y la Práctica"</span>
                  <span className="material-icons">keyboard_arrow_right</span>
                </a>
              </div>

            </div>


          </div>
        </div>
    )
  }
}
