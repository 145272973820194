import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import { BlogVid } from './BlogVid'
import { ScrollTop } from '../../utils/ScrollTop'

export class B9 extends Component {

  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }


  render() {
    return (
        <div className="content"> 
          <Helmet>
            <title>Encontrando Nuestra Voz Como Músicos</title>
          </Helmet>

          <div className="cl-descrip contenido-blog">
            <h3 className='texto-titulo'>Encontrando Nuestra Voz Como Músicos</h3>

            <div className="media my-4">
            <img src="/assets/images/perfil_nuevo_160px.jpg" className="mr-3" alt="" width="40" height="40"
                 style={{ borderRadius: '100%',  border: '2px solid var(--color-cinco)' }}
            />
            <div className="media-body">
              <h6 className="mt-0 texto-cursiva" style={{ margin: 0, fontSize: '1.6rem', color: 'var(--color-primario)' }}>Tincho</h6>
                <small className="text-muted">12 Julio 2024 - lectura de 9 min</small>
              </div>
            </div>
            
            <div>

Hace un tiempito subí un video (<a href="https://youtu.be/KW6ii7_cni4" target="_blank" rel="noopenner noreferrer">podés verlo acá</a>) donde hablo de la importancia de encontrar nuestra propia voz cómo músicos, nuestra forma personal de abordar la música y expresarnos a través de ella.
<br/><br/>
A continuación encontrarás una transcripción del video y luego, al final, agregaré algunas observaciones al respecto.
<br/><br/>
-Transcripción-
<br/><br/>
“Quiero hablar de algunos conceptos sobre los que vengo pensando en los últimos años, acerca de lo que significa ser músico, hacer música, no solo para nosotros mismos sino para, idealmente, impactar en quién nos escucha.
<br/><br/>
Esta es una reflexión muy personal, basada en mi experiencia, pero creo que puede ser útil a otros, o en todo caso un incentivo para pensar qué estamos haciendo y cómo lo estamos haciendo.
<br/><br/>
La razón primera por la que nos interesamos por la <b>música</b> es porque hay un artista, una canción, que nos toca, nos moviliza, y nos libera esa dopamina y endorfina, nos brinda sensaciones de <b>placer y energía</b> al mismo tiempo. 
<br/><br/>
Te voy a contar mi experiencia: Vengo de una familia clase media normal, donde salvo por mis primos, nadie era músico. De pequeño tenía unos pocos cassettes en mi casa que escuchaba bastante. Recuerdo que la primera música que me atrapó era un cassette del Show de las Tortugas Ninja, uno de los Twists (que temazo Mannix), y un mixtape de los Beatles de mi viejo. No sabía porqué me gustaban, pero los escuchaba una y otra vez y me encantaba cantar las canciones.
<br/><br/>
También, sin saberlo, mucha de la música de los videojuegos a los que jugaba fueron calando en mí, la música de Mario, Zelda, Castlevania, y muchos otros. Eran juegos que tenían detrás a grandes compositores como Koji Kondo que hoy es reconocido mundialmente. En esa época la música de los videojuegos tenía carácter, identidad, temas identificables que podías tararear mientras jugabas. Creo que eso ha cambiado bastante ahora, que por lo general es música ambiental totalmente genérica, de stock.
<br/><br/>
Cuando me regalaron mi primer reproductor de CDs, me compré el Greatest Hits 2 de Queen, que me voló completamente la cabeza 🤯. Luego vinieron discos increibles como el Album Negro de Metallica, Get A Grip de Aerosmith, Check Your Head de los Beastie Boys, Jagged little pill de Alanis Morissette, Dookie de Green Day, el primero de Rage Against the Machine y luego el Blood Sugar Sex Magic de los Red Hot, Ok Computer de Radiohead, y la lista sigue y sigue.
<br/><br/>
Toda esa música que absorbí durante mi infancia y adolescencia que me enamoró, me motivó, desde la curiosidad, a tocarla y entenderla mejor. Me <b>inspiró</b> a tocar.
<br/><br/>
Si bien todos estos artistas que mencioné son bastantes distintos entre sí, tienen algo en común. Todos tienen su personalidad, su <b>voz propia</b>. Escuchas 10 segundos de un tema y ya sabes quién es. Y también sabes que no están copiando a nadie, es auténtica, de verdad.
<br/><br/>
Y me voy a detener en este punto. 
<br/><br/>
Creo que hay una diferencia entre la música que se hace "de verdad" y la genérica, la de stock, la “meh” que da lo mismo. Y como músicos/artistas tenemos que ser conscientes de eso. 
<br/><br/>
A la hora de crear, e independientemente de que le guste a muchos o a casi nadie, la "música de verdad", a mi entender, es la que nos representa, la que somos, la que nos gusta, la que queremos crear, la que nos entusiasma y enamora.
<br/><br/>
No es la que otros quieren que toquemos, no es la del repertorio del conservatorio, no es la que un "músico profesional" debería saber, no es la del ego, ni tampoco la que creemos que vende.
<br/><br/>
Digo todo esto porque yo mismo estuve sin dirección clara, tocando durante mucho tiempo música que no era mía, que no me representaba, sino la que creía que tenía que tocar para ser un músico “profesional”.
<br/><br/>
Entiendo que es importante aprender de grandes músicos, estudiarlos, transcribirlos, sin embargo acá estoy hablando de otro tema, una cosa es <b>estudiar/practicar</b> y otra es <b>crear/tocar</b>.
<br/><br/>
A veces nos ponemos a hacer cosas simplemente porque <b>todos a nuestro alrededor</b> lo están haciendo. Sobre todo cuando queremos hacer de la música nuestro oficio. Dejamos de guiarnos por curiosidad e interés real y nos ponemos a tocar cosas porque somos (o queremos ser) profesionales y tenemos que hacerlo, vendemos nuestro tiempo ad honorem por 2 mangos, e incluso a veces ponemos plata, con la perspectiva futura e incierta de obtener un beneficio.<br/>
Solo pensamos en responder las demandas de la industria… o mejor dicho de lo que creemos puede ser la industria. Porque la verdad la cosa está difícil y puede que eso que buscamos nunca llegue, que la demanda realmente no exista, por saturación de oferta o lo que sea.
<br/><br/>
Entonces creo <b>queda hacer lo que queremos hacer</b>, lo que nos motiva, sin más.
De forma inteligente, claro. 
<br/><br/>
Tenemos que convertirnos en la Vaca Púrpura. En lugar de mimetizarnos con el resto, si abrazamos nuestras particularidades y encontramos lo que nos hace únicos, podremos aportar algo original y fresco, que nos distinga del resto.
<br/><br/>
Todo es un balance entre <b>lo que queremos crear</b> y <b>lo que necesitamos</b> para crearlo. Porque el oficio, la habilidad la tenemos que tener, eso no se negocia. La música es <b>tocar</b> pero también aprender a <b>escuchar</b> para poder tomar decisiones estéticas: qué notas, qué ritmos, cómo los articulamos, el timbre, afinación, precisión rítmica. Eso hay que entrenarlo. Y la mejor manera de entrenarlo es haciendo.
<br/><br/>
Si miramos con detenimiento a la mayor parte de los artistas que nos inspiran, no son aquellos más virtuosos y técnicos, o que saben tocar muchos estilos, o tienen oído absoluto. Sino son aquellos que tienen una <b>estética propia</b>, y simplemente hacen lo que hacen sin vueltas y muy bien.
<br/><br/>
Uno puede ser un músico increíble técnicamente, saber mucho de armonía, de estilos y técnicas, y aún así ser un músico más, de stock, sin mucho para decir. 
Y terminar dependiendo de qué otro músico, quizás más limitado, pero que tiene personalidad, creatividad y muchas cosas para decir, te contrate. 
<br/><br/>
Y ahí, creo, radica la principal <b>diferencia</b> entre ser <b>artista</b> y ser un <b>músico “profesional”</b>.
<br/><br/>
En mi opinión un <b>artista</b> encuentra <b>"su" manera</b> de hacer las cosas, no mira qué están haciendo los demás. Cuando empezás a prestar demasiada atención a lo que hacen los demás caés en la trampa de la estandarización, de las tendencias, de la no-personalidad que termina haciéndote invisible.
<br/><br/>
Un artista también busca <b>contar una historia</b>, integrando al oyente, pensando en el oyente, llevándolo a un viaje memorable.
<br/><br/>
Antes me consideraba músico “profesional”, hoy me considero o busco ser un artista: alguien que crea. No me limito solo a la música, puede ser también un video, una clase, un post, una imagen, una reflexión como esta. 
<br/><br/>
Y aquí estoy, no digo que tenga todo resuelto, sigo buscando y tratando de encontrar la forma en que quiero hacer las cosas, pero en todo lo que hago trato de imprimir una cuota de mi personalidad.”
<br/><br/>

<b>Conclusión y observaciones</b>
<br/><br/>
Luego de publicado el video he recibido algunos comentarios que de alguna manera u otra insinuaban que ser virtuoso y artista eran incompatibles, o que tal artista era virtuoso y además original como si eso fuera una contradicción.
<br/><br/>
La idea que quiero transmitir es justamente que <b>no hay una sola manera</b> de hacer las cosas. Se puede ser un artista virtuoso o no, da lo mismo. Lo importante es ser capaz de <b>movilizar y conectar</b> con el oyente, tener un impacto social y cultural.
<br/><br/>
La <b>técnica</b> y porqué no, el <b>virtuosismo</b>, pueden ser importantes para sentar un estándar de calidad mínimo, aunque <b>no deben ser un fin en sí mismo sino un medio</b> para expresarnos de la forma que queremos.
<br/><br/>
El <b>verdadero trabajo</b> de un artista, creo, es ser <b>auténtico</b> y buscar una estética y forma de expresión personal, que puede estar basada en uno o varios estilos, pero tiene que tener un condimento personal. Y además esa música personal tiene que ser lo suficientemente universal como para poder <b>conectar</b> con otros. Al menos eso es lo que escucho en los artistas que admiro.
<br/><br/>
Finalmente quiero compartir con vos una encuesta realizada en Instagram y Youtube. La pregunta era “¿Cuál es el atributo más importante de un músico?”, aquí las respuestas:
<br/>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b9/votacion_voz_propia_yt_500px.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >En YouTube con un total de 109 votos, gana Personalidad con el 43%, le sigue Movilizador con 26%, un 25% votó Versatilidad y solo el 5% Técnica.</div>
              </div>
            </div>

            <div className="card my-4" style={{overflow: 'hidden'}}>
              <img src="/assets/images/b9/votacion_voz_propia_insta_500px.jpg" className="card-img-top" alt=""/>
              <div className="card-body">
                <div className="card-text" style={{margin: '0'}} >En Instagram con un total de 62 votos, curiosamente ganó Versatilidad con el 42%, le sigue Movilizador con 24%, Personalidad con un 23% y Técnica con el 11%.</div>
              </div>
            </div>

Me sorprende el resultado de Instagram. La versatilidad, es decir, la capacidad de adaptarse a distintas situaciones, es un atributo más bien requerido en los sesionistas, es una gran habilidad, pero tiene más que ver con copiar los estilos de otros que con desarrollar el propio.
<br/><br/>
Si simplificamos en 2 grupos distinguiendo atributos Creativos (Personalidad/Movilizador) vs Técnicos (Versatilidad/Técnica) tendríamos:
<br/><br/>
- Youtube:<br/>
Creativo: 69%<br/>
Técnico: 30%
<br/><br/>
- Instagram:<br/>
Creativo: 47%<br/>
Técnico: 53%
<br/><br/>
De aquí podemos concluir que los músicos que están en Instagram ponderan más los atributos técnicos, y los de Youtube los atributos creativos. Lo cual es curioso y creo debe estar relacionado a la dinámica de cada red social.

                <br/><br/>
                A seguir grooveando!
                <br/><br/>
                <span className='texto-cursiva' style={{ fontSize: '1.4rem'}}>Tincho </span>
            <span role="img" aria-label="emoji">🤗🎸🎶</span>
                <br/><br/>


                <BlogVid embed="https://www.youtube.com/embed/KW6ii7_cni4"
                         texto="Este texto está basado en el siguiente video:"
                />

                <br/>
                  <ScrollTop />

                  <div className='btn-anterior-proximo centro'>
                    <a href="/blog/breve-historia-del-bajo-electrico" className='btn mt-4 centro'>
                      <span className="material-icons">keyboard_arrow_left</span>
                      <span>Anterior: "Breve Historia del Bajo Eléctrico"</span>
                    </a>

                    <a href="/blog/que-necesito-para-empezar-a-tocar-el-bajo" className='btn mt-4 centro' style={{}}>
                      <span>Próximo: "¿Qué Necesito para Empezar a Tocar el Bajo?"</span>
                      <span className="material-icons">keyboard_arrow_right</span>
                    </a>
                  </div>

                  
                <br/>              
            </div>

          </div>
        </div>
    )
  }
}
