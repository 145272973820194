import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
// Components
import { Intro } from './Components/Intro'
import { Clases } from './Components/Clases'
import { Acerca } from './Components/Acerca'
import { Contacto } from './Components/Contacto'

import './musica.css'


export function Musica() {
  // scroll arriba
  useEffect(() => {
    window.scroll({
      top: 0
    })

  }, [])


  return (
    <div className='musica-main'>
      <Helmet>
        <title>Tincho en el Bajo</title>
      </Helmet>
      <main>

        <div className="back1" id="bg1">
          <div className="filter"></div>
          {/* <img src="/assets/img/header_musica_web.jpg" /> */}
        </div>
        
          <Clases />

      </main>
    </div>
  )
}
