import React, { Component } from 'react'

import './Form.css'
import { _SERVER } from '../../utils/req'

export class Form extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      cursorWait: '',
      enviado: false,
      error: false,
      nombre: '',
      email: '',
      mensaje: '',
    }
  }

  handleInputChange = (e) => {
    //console.log(e.target.value)
    const { name, value } = e.target

    this.setState({
      [name]: value
    })
  }

  handleSubmit = async e => {
    e.preventDefault()
    //console.log('handleSubmit')
    
    this.setState({ 
      cargando: true,
      cursorWait: 'wait'
    })

    try {
      let formData = new FormData()
      formData.append('content', JSON.stringify({
        nombre: this.state.nombre,
        email: this.state.email,
        asunto: 'Mensaje desde Tinchoenelbajo.com.ar',
        mensaje: this.state.mensaje,
      }))

      const enviar = await fetch(_SERVER + '/mail.php', {
        method: 'POST',
        body: formData,
        /*headers: {
          "Content-Type": "application/json"
        }*/
      })
      const res = await enviar.json()
      //console.log(res)

      if (res === 'ok') {
        this.setState({ enviado: true })
        const formContacto = document.querySelectorAll('.contacto-form')
        formContacto.forEach(e => {
          //console.log(e)
          e.reset()
        })
        
      }
      else {
        this.setState({ error: true })
      }

      this.setState({ 
        cargando: false,
        cursorWait: ''
      })
    }
    catch (err) {
      //console.log(err)
      this.setState({ 
        error: true,
        cargando: false,
        cursorWait: ''
      })
    }

  }

  render() {
    const { cargando, cursorWait, enviado, error } = this.state

    return (
      <div className="container form">

          <form id="contacto-form" className="contacto-form" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input type="text" className="form-control" name="nombre" id="nombre" placeholder="Nombre" required onChange={this.handleInputChange} />
            </div>
            <div className="form-group">
              <input type="email" className="form-control" name="email" id="email" placeholder="Email" required onChange={this.handleInputChange} />
            </div>
            <div className="form-group">
              <textarea rows="5" className="form-control" name="mensaje" id="textarea" placeholder="Mensaje" onChange={this.handleInputChange}></textarea>
            </div>

            <div className="center-y">
              <button type="submit" 
                  className="btn btn-primary"
                  style={{cursor: cursorWait}}
                  disabled={cargando}>
                    {cargando ? 'Enviando...' : 'Enviar'}
              </button>
            </div>

            <div className="center-y mt-4">
              { enviado && <p className="">¡Mensaje enviado con éxito! <span role="img" aria-label="emoji">👍</span></p> }

              { error && <p className="">Ha ocurrido un error. <span role="img" aria-label="emoji">😩</span><br/> Verificá tu conexión y volvé a intentarlo, si el problema persiste escribinos a <a className="text-primary" href="mailto:hola@tinchoenelbajo.com.ar">hola@tinchoenelbajo.com.ar</a>. Muchas gracias y disculpá las molestias!</p> }
            </div>


          </form>

      </div>
    )
  }
}
