import React from 'react'
import { Helmet } from 'react-helmet'
import { Alert } from 'react-bootstrap'
import './login.css'

// Utils
import { _SERVER, _AUTH } from '../utils/req'

export default class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      cursorWait: '',
      enviado: false,
      error: false,
      datosIncompletos: false,
      email: '',
      nombre: '**',
      apellido: '**',
      ciudad: '**',
      pais: '**',
      acepta: '',
    }
  }

  componentDidMount() {
    window.scroll({
      top: 0,
    })
  }

  // Handle Form fields
  handleInputChange = (e) => {
    //console.log(e.target.value)
    const { name, value } = e.target

    this.setState({
      [name]: value
    })
  }

  handleSubmit = async e => {
    e.preventDefault()
    //console.log('Submit Partituras...')

    this.setState({
      cargando: true,
      cursorWait: 'wait'
    })

    try {
      let formData = new FormData()
      formData.append('content', JSON.stringify({
        nombre: this.state.nombre,
        apellido: this.state.apellido,
        email: this.state.email,
        pais: this.state.pais,
        ciudad: this.state.ciudad
      }))

      //console.log(this.state)

      fetch(_SERVER + _AUTH, {
        method: 'POST',
        /*headers: {
          'Content-Type': 'text/json'
        },*/
        body: formData
      })
        .then(res => res.json())
        .then(res => {
          //console.log(res)

          // pasa state a parent (app)
          this.props.isLoggedFunc(res.logged, res.id, res.name)

          //reset form
          /*this.setState({
            nombre: '',
            apellido: '',
            email: '',
            pais: '',
            ciudad: ''
          })*/


          if (res.logged) {
            this.setState({ enviado: true })
            //const formContacto = document.getElementById('partituras-form')
            //formContacto.reset()

            //console.log('Respuesta del envio de form', res)

            // Crear cookie para uid
            this.props.isLoggedFunc(res.logged, res.id, res.name)

            this.props.updateSesion(res)
          }
          else if (res.error === 'datos incompletos') {
            this.setState({ datosIncompletos: true })
            //console.log(this.state)
          }
          else {
            this.setState({ error: true })
          }

          this.setState({
            cargando: false,
            cursorWait: ''
          })


        })
      //console.log(res)



    }
    catch (err) {
      console.log('error en fetch')
    }

  }


  render() {
    const { cargando, cursorWait, error, datosIncompletos } = this.state

    //console.log(this.state)

    return (
      <div className="login">
        <Helmet>
          <title>Login | Tincho en el Bajo</title>
        </Helmet>

        <div className="content">
          <img src='/assets/img/bassman_500px.png' className='bassman' alt='' />

          <div className="cl-descrip form">

            <h3 className="texto-titulo" style={{ color: 'var(--color-cinco)' }}>Partituras</h3>
            <p className="text-center subtitle">
              Para ver y descargar gratis las partituras, ingresá los datos solicitados.
              <br/>
              <span className="small text-muted"> Si ya te registraste ingresa solo tu <i>email</i> para acceder.</span>
            </p>

            <form onSubmit={this.handleSubmit} id="partituras-form">
              <div className="form-group">
                <label>
                  Email <span className='text-danger'>*</span>
                  <input
                    type="email" className="form-control" name="email" placeholder="" required
                    maxLength="50"
                    onChange={this.handleInputChange}
                  />
                </label>
                <small id="emailHelp" className="form-text text-muted"></small>
              </div>

              <div className="form-group">
                <label>
                  Nombre
                  <input
                    type="text" className="form-control" name="nombre" placeholder=""
                    maxLength="25"
                    onChange={this.handleInputChange}
                  />
                </label>
              </div>

              <div className="form-group">
                <label>
                  Apellido
                  <input
                    type="text" className="form-control" name="apellido" placeholder=""
                    maxLength="25"
                    onChange={this.handleInputChange}
                  />
                </label>
              </div>

              <div className="form-group">
                <label>
                  País
                  <input
                    type="text" className="form-control" name="pais" placeholder=""
                    maxLength="25"
                    onChange={this.handleInputChange}
                  />
                </label>
              </div>

              <div className="form-group">
                <label>
                  Ciudad
                  <input
                    type="text" className="form-control" name="ciudad" placeholder=""
                    maxLength="25"
                    onChange={this.handleInputChange}
                  />
                </label>
              </div>

              <div className="form-group">
                <label>
                  <input
                    type="checkbox" className="" name="acepta" placeholder=""
                    required
                    onChange={this.handleInputChange}
                  />
                  <span> Acepto suscribirme a la lista de emails de Tincho en el Bajo</span>
                  <br/><br/>
                  <span className='' style={{display: 'inline-block', textAlign: 'left', fontSize: '0.9rem'}}>Ocasionalmente te enviaremos un email con novedades e info de interés relacionados a nuestra actividad y el bajo eléctrico.
                  Valoramos tu privacidad, no se compartirán tus datos con terceros. Puedes optar por desuscribirte cuando quieras.</span>
                </label>
              </div>

              <div className="center-y mt-4">
                <button type="submit"
                    className="btn btn-primary"
                    style={{cursor: cursorWait}}
                    disabled={cargando}>
                      {cargando ? 'Accediendo...' : 'Acceder'}
                </button>
              </div>

              <div className="center-y text-center mt-4">

                { datosIncompletos && <Alert variant="danger">Datos incompletos: para ingresar por primera vez debés completar todos los campos.</Alert> }

                { error && <Alert variant="warning">Disculpas, ha ocurrido un error. <span role="img" aria-label="emoji">😩</span><br/> Verificá tu conexión y volvé a intentarlo, si el problema persiste escribinos a <a className="text-primary" href="mailto:hola@tinchoenelbajo.com.ar">hola@tinchoenelbajo.com.ar</a>. Muchas gracias.</Alert> }

              </div>

            </form>

          </div>
        </div>
      </div>
    );
  }
}
